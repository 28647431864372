<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar" id="chart">
        <apexchart
          type="pie"
          height="200"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
        title: {
          text: "Ingresos servicios del mes",
          offsetX: 0,
          style: {
            fontSize: "15px",
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      mostrar: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/estadisticas/datos`,
          data: {},
        })
        .then((result) => {
          let datos = result.data.servicios;
          let servicios = datos.servicios;
          let serie = [];
          let etiquetas = [];
          for (let index = 0; index < 10; index++) {
            serie.push(servicios[index].porcentaje);
            etiquetas.push(servicios[index].nombre);
          }
          this.totalServicios = datos.total;
          this.series = serie;
          this.chartOptions.labels = etiquetas;
          this.mostrar = true;
        });
    },
  },
};
</script>