<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="cool pa-4" elevation="4">
          <v-card-title primary-title>
            Secrets
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              clearable
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              solo
              hide-details
              class="rounded-pill"
              @keyup.enter="getDatos"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :search="search"
            :headers="headers"
            :items="elementos"
            :options.sync="options"
            :server-items-length="totalElementos"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-format-horizontal-align-left',
              lastIcon: 'mdi-format-horizontal-align-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por página:',
              itemsPerPageOptions: [5, 10, 100, -1],
            }"
          >
            <template v-slot:item.ip="{ item }">
              <v-btn
                v-if="item.ip"
                text
                small
                dark
                color="blue"
                @click="abrirIp(item.ip)"
                >{{ item.ip }}</v-btn
              >
              <v-btn v-else text small dark color="blue">Pendiente</v-btn>
            </template>
            <template v-slot:item.perfil="{ item }">
              <v-chip v-if="item.perfil" outlined small dark text color="teal">
                <v-icon left>mdi-account-card-details</v-icon>
                {{ item.perfil.name }}</v-chip
              >
              <v-chip v-else outlined text small dark color="black">
                <v-icon left>mdi-account-card-details</v-icon>

                No encontrado</v-chip
              >
            </template>
            <template v-slot:item.cliente="{ item }">
              <v-chip
                small
                outlined
                dark
                text
                color="primary"
                @click="abrirCliente(item.clienteId)"
              >
                <v-icon left>mdi-account</v-icon>
                {{ item.nombreCliente }}</v-chip
              >
            </template>
            <template v-slot:item.opciones="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn small @click="irAntena(item.id)" icon v-on="on">
                    <v-icon small>mdi-access-point-network</v-icon>
                  </v-btn>
                </template>
                <span>Ver antena</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import LocalStorage from "@/js/LocalStorage.js";
export default {
  data() {
    return {
      idRouter: 0,
      search: "",
      elementos: [],
      options: {},
      totalElementos: 0,
      loading: false,
      headers: [
        { text: "Secret", value: "secret" },
        { text: "Contraseña", value: "password_secret" },
        { text: "Cliente", value: "cliente" },
        { text: "Perfil", value: "perfil" },
        { text: "Ip", value: "ip", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.idRouter = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `secrets/tabla`,
          data: {
            search: this.search,
            filtroEstado: parseInt(this.filtroEstado),
            tipo_antena: this.tipoAntena,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            router_id: this.idRouter,
          },
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.loading = false;
            this.elementos = result.data.secrets.data;

            this.totalElementos = result.data.secrets.total;
          }
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    abrirIp(ip) {
      if (ip) {
        window.open("https://" + ip, "_blank");
      }
    },
    irAntena(id) {
      window.location.href = "detalles/antena/vista/" + id;
    },
    abrirCliente(id) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + id;
    },
  },
};
</script>
<style>
</style>