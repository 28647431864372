<template>
  <div>
    <v-dialog v-model="dialog" max-width="850" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title class="display-1" primary-title
            ><strong>Editar</strong></v-card-title
          >

          <v-responsive class="overflow-y-auto" max-height="510">
            <v-card-text>
              <v-form
                dense
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                lazy-validation
              >
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="cliente.nombre"
                      :rules="nombreReglas"
                      label="Nombre del cliente"
                      prepend-icon="mdi-account"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="cliente.apellido_paterno"
                      :rules="generalRegla"
                      label="Apellido paterno"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="cliente.apellido_materno"
                      :rules="generalRegla"
                      label="Apellido materno"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-select
                      v-model="cliente.tipo_cliente_id"
                      :items="tiposCliente"
                      label="Tipo de cliente"
                      item-value="id"
                      :rules="generalRegla"
                      item-text="tipo"
                      no-data-text="No hay elementos registrados"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevoTipoCliente()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-account</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Celular"
                      v-model="cliente.celular"
                      type="number"
                      :rules="telefonoReglas"
                      prepend-icon="mdi-cellphone-android"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Telefono"
                      v-model="cliente.telefono"
                      type="number"
                      prepend-icon="mdi-phone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Correo cliente"
                      v-model="cliente.correo"
                      prepend-icon="mdi-gmail"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-select
                      v-model="cliente.zona_id"
                      :items="zonas"
                      :rules="generalRegla"
                      attach
                      label="Zona"
                      item-value="id"
                      item-text="nombre"
                      no-data-text="No hay elementos registrados"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevaZona()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              >Agregar otra zona</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs6 md3>
                    <v-select
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                      ]"
                      label="Dia de creación de factura"
                      v-model="cliente.dia_factura"
                      :rules="diaFacturaRules"
                      prepend-icon="mdi-calendar-multiple-check"
                      hint="cuando se creara la factura del pago mensual?"
                    ></v-select>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-select
                      label="Dia de pago"
                      :items="diasDePago"
                      v-model="cliente.dia_pago"
                      type="number"
                      :rules="diaPagoRules"
                      required
                      prepend-icon="mdi-calendar-multiple-check"
                    ></v-select>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-select
                      label="Dias de gracia de pago"
                      :items="diasDeGracia"
                      v-model="cliente.dias_gracia"
                      type="number"
                      :rules="diasGraciaRules"
                      required
                      prepend-icon="mdi-calendar-multiple-check"
                      hint="cuantos dias tiene el cliente para liquidar su pago?"
                    ></v-select>
                  </v-flex>
                  <v-flex xs6 md3>
                    <v-text-field
                      disabled
                      readonly
                      label="El dia de suspención"
                      v-model="dia_cancelacion"
                      prepend-icon="mdi-calendar-multiple-check"
                      hint="Corte de servicio"
                      persistent-hint
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <strong>Red</strong>
                <v-layout row wrap>
                  <v-layout row wrap>
                    <v-flex xs10>
                      <strong> Monitorear consumo </strong>
                      <br />
                      Monitoreo de consumo de ancho de banda subida y bajada del
                      cliente.
                    </v-flex>
                    <v-flex xs2>
                      <v-switch
                        inset
                        v-model="cliente.monitoreo_consumo"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs10>
                      <strong> Monitorear ping </strong>
                      <br />
                      Monitoreo de ping a la ip de la antena el cliente.
                    </v-flex>
                    <v-flex xs2>
                      <v-switch
                        inset
                        v-model="cliente.monitoreo_ping"
                      ></v-switch>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs10>
                      <strong> Inmunidad </strong>
                      <br />
                      El servicio del cliente no podra ser cancelado en el
                      Mikrotik aunque tenga pagos pendientes
                    </v-flex>
                    <v-flex xs2>
                      <v-switch inset v-model="cliente.inmunidad"></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs10>
                      <strong> Historial </strong>
                      <br />
                      Recoleccion Ips visitadas por el cliente cada cierto
                      tiempo.
                    </v-flex>
                    <v-flex xs2>
                      <v-switch inset v-model="cliente.historial"></v-switch>
                    </v-flex>
                  </v-layout>
                </v-layout>
                <v-divider></v-divider>
                <v-layout row wrap>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model="cp"
                      label="Código postal"
                      :rules="generalRegla"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field
                      v-model="cliente.estado"
                      label="Estado"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field
                      v-model="cliente.municipio"
                      label="Municipio"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex v-if="colonias.length != 0" xs12 md6>
                    <v-select
                      v-model="cliente.colonia"
                      :items="colonias"
                      label="Colonias"
                    ></v-select>
                  </v-flex>
                  <v-flex v-if="colonias.length == 0" xs12 md6>
                    <v-text-field
                      v-model="cliente.colonia"
                      label="Colonia"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="cliente.direccion"
                      label="Dirección"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12>
                    <mapa ref="mapa"></mapa>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <strong>Datos de instalación</strong>
                <v-layout row wrap>
                  <v-flex xs11 md6>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedDateFormatted"
                          label="Dia de instalación"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="generalRegla"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-mx"
                        v-model="cliente.fecha_instalacion"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-menu
                      ref="menu"
                      v-model="menu_reloj"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="cliente.hora_instalacion"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="cliente.hora_instalacion"
                          label="Hora de instalación"
                          prepend-icon="mdi-av-timer"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu_reloj"
                        v-model="cliente.hora_instalacion"
                        full-width
                        @click:minute="
                          $refs.menu.save(cliente.hora_instalacion)
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 md4>
                    <v-text-field
                      label="Costo Instalacion"
                      v-model="cliente.costo_instalacion"
                      prepend-icon="mdi-cash"
                      prefix="$"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-select
                      v-model="cliente.captacion_id"
                      :items="captaciones"
                      :rules="generalRegla"
                      label="Captación"
                      item-value="id"
                      item-text="captacion"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"> </v-divider>
                        <v-list-item @click="nuevaCaptacion()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-autocomplete
                      v-model="cliente.instalador_id"
                      :items="personas"
                      :search-input.sync="search_persona"
                      :rules="generalRegla"
                      attach
                      item-text="nombre"
                      item-value="id"
                      label="Instalador"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            no se encuentra el
                            <strong>Personal</strong>
                          </v-list-item-title>
                        </v-list-item>
                      </template>

                      <template v-slot:item="{ item }">
                        <v-list-item-avatar
                          color="indigo"
                          class="headline font-weight-light white--text"
                        >
                          {{ item.nombre.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title> item.nombre</v-list-item-title>
                          <v-list-item-subtitle
                            >item.apellido_paterno</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >item.apellido_materno</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-autocomplete
                      v-model="cliente.ayudantes"
                      :items="personas"
                      item-text="FullName"
                      item-value="id"
                      multiple
                      chips
                      prepend-inner-icon="mdi-account-multiple"
                      label="Ayudantes del instalador"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      filled
                      label="Detalles"
                      v-model="cliente.detalles"
                      rows="3"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <strong>Datos fiscales</strong>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="cliente.razon_social"
                      label="Razón social"
                      prepend-icon="mdi-account-edit"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Tipo de identificacion"
                      v-model="cliente.tipo_identificacion"
                      prepend-icon="mdi-sim"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="RFC-RUC-NIT"
                      v-model="cliente.RFC_RUC_NIT"
                      prepend-icon="mdi-account-card-details"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Retenciones"
                      v-model="cliente.retenciones"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelar()" text>Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              class="rounded-pill pa-3"
              @click="actualizar()"
              color="primary"
              >Actualizar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <formNuevaZona ref="zona"></formNuevaZona>
    <formTipoCliente ref="formNuevoTipoCliente"></formTipoCliente>
    <formCaptacion ref="refCaptacionForm"></formCaptacion>
  </div>
</template>

<script>
import mapa from "@/js/components/mapa/mapaComponent.vue";
import Cliente from "@/js/components/clientes/Cliente.js";
import formNuevaZona from "@/js/components/red/zonas/nuevaZona.vue";
import formTipoCliente from "@/js/components/clientes/recursos/tablas/tipos_cliente/nuevo.vue";
import formCaptacion from "@/js/components/clientes/recursos/tablas/captaciones/nuevo.vue";

export default {
  components: {
    mapa,
    formNuevaZona,
    formTipoCliente,
    formNuevaZona,
    formCaptacion,
  },
  data() {
    return {
      cliente: new Cliente(),
      valid: true,
      nombreReglas: [(v) => !!v || "Se necesita el nombre"],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      telefonoReglas: [(v) => v > 0 || "Se necesita un número de contacto"],
      emailRules: [
        (v) =>
          /.+@.+/.test(v) ||
          v == null ||
          v.length == 0 ||
          "Introduce un correo valido",
      ],
      diaFacturaRules: [
        (v) =>
          !!v ||
          "Se necesita el dia de cracion de factura del pago rango 1-28.",
      ],
      diaPagoRules: [(v) => !!v || "Se necesita el dia de pago rango 1-28."],
      diasGraciaRules: [
        (v) => !!(v + "") || "Cuantos dias tiene el cliente para liquidar",
      ],

      dialog: false,
      colonias: [],
      modal2: false,
      modal: false,
      cp: "",
      zonas: [],
      resolve: null,
      reject: null,
      captaciones: [],
      tiposCliente: [],
      search_persona: "",
      cargando: true,
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
      personas: [],
    };
  },
  mounted() {},
  watch: {
    cp(val) {
      //this.getDatosApi(val);
      this.cliente.codigo_postal = val;
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.cliente.fecha_instalacion) {
        return this.formatDate(this.cliente.fecha_instalacion);
      } else {
        return "";
      }
    },
    diasDePago() {
      var dias = [];
      var tamanio = this.cliente.dia_factura;
      for (var i = tamanio; i < 29; i++) {
        dias.push(i);
      }
      if (!dias.includes(this.cliente.dia_pago)) {
        this.cliente.dia_pago = "";
      }
      return dias;
    },
    diasDeGracia() {
      var dias = [];
      var tope = 29 - this.cliente.dia_pago;
      for (var i = 0; i < tope; i++) {
        dias.push(i);
      }

      if (!dias.includes(this.cliente.dias_gracia)) {
        this.cliente.dias_gracia = "";
      }
      return dias;
    },
    dia_cancelacion() {
      return this.cliente.dia_pago + this.cliente.dias_gracia;
    },
  },
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.cliente.latitud,
        this.cliente.longitud,
        "markerCasa4.png",
        "Zona",
        true
      );
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/empresa/registro/cliente",
          data: {},
        })
        .then((result) => {
          this.zonas = result.data.zonas;
          this.personas = result.data.personas;
          this.tiposCliente = result.data.tiposCliente;
          this.captaciones = result.data.captaciones;
        });
    },

    cargarDatos(cliente) {
      this.getDatos();

      this.cliente = cliente;
      this.dia_factura = cliente.dia_factura;
      this.dia_pago = cliente.dia_pago;
      this.dias_gracia = cliente.dias_gracia;
      this.dialog = true;
      this.cp = cliente.codigo_postal;
      this.esperarComponente();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        this.cliente.latitud = this.$refs.mapa.getLatitud();
        this.cliente.longitud = this.$refs.mapa.getLongitud();
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `cliente/` + this.cliente.id;

        this.cliente.antena = null;
        this.cliente.router = null;
        this.cliente.zona = null;
        this.cliente.pagos = null;
        this.cliente.instalador = null;
        this.cliente.recepcionista = null;
        this.cliente.tipo = null;
        this.cliente.status = null;
        this.cliente.ayudantes = this.cliente.ayudantes.map((item) => item.id);

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.cliente,
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data.cliente);
          });
      }
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;
              this.cliente.estado = datos.estado;
              this.cliente.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
    guardarCoordenadas() {
      this.dialog_mapa = false;
      this.cliente.latitud = this.$store.getters.getCoordenadas.lat;
      this.cliente.longitud = this.$store.getters.getCoordenadas.lng;
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
    nuevaZona() {
      this.$refs.zona.mostrar().then((resultado) => {
        if (resultado) {
          this.zonas.push(resultado);
          this.cliente.zona_id = resultado.id;
        }
      });
    },

    nuevaCaptacion() {
      this.$refs.refCaptacionForm.mostrar().then((resultado) => {
        if (resultado) {
          this.captaciones.push(resultado);
          this.cliente.captacion_id = resultado.id;
        }
      });
    },
    nuevoTipoCliente() {
      this.$refs.formNuevoTipoCliente.mostrar().then((resultado) => {
        if (resultado) {
          this.tiposCliente.push(resultado);
          this.cliente.tipo_cliente_id = resultado.id;
        }
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
