<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-3">
              <v-card-title class="display-1">
                <strong>Mis donaciones</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      v-model="search"
                      clearable
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      solo
                      class="rounded-pill"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [10, 500, 1000, 5000, -1],
                }"
              >
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.usuario="{ item }">
                  <v-chip outlined v-if="item.name" dark color="pink">{{
                    item.name
                  }}</v-chip>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row>
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
  
  <script>
import "jspdf-autotable";

export default {
  components: {},
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id", sortable: true },
        { text: "Monto en dolares", value: "amount", sortable: true },
        { text: "Usuario", value: "usuario.name", sortable: false },
        { text: "Correo", value: "usuario.email", sortable: false },
        { text: "Empresa", value: "empresa.nombre", sortable: false },
        { text: "Fecha de liquidación", value: "created_at", sortable: true },
      ],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `paypal/pagos/empresa`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
          },
        })
        .then((result) => {
          this.loading = false;
          if ([200, 201].includes(result.status)) {
            this.elementos = result.data.pagos;
            this.totalElementos = result.data.pagos.total;
          }
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
  },
};
</script>
  