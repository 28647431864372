<template>
  <div class="component mt-2">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12>
            <v-container grid-list-md>
              <v-flex xs12 md12>
                <v-card class="cool pa-2">
                  <v-container grid-list-md>
                    <v-card-title primary-title> Dias inhábiles </v-card-title>
                    <v-card-subtitle>
                      Las fechas agregadas las reconocera el sistema como dias
                      inabiles y no se tomaran en cuenta en el los dias de
                      gracia
                    </v-card-subtitle>
                    <v-container style="max-width: 1000px">
                      <v-timeline dense clipped>
                        <v-timeline-item
                          fill-dot
                          class="white--text mb-12"
                          color="orange"
                          large
                        >
                          <template v-slot:icon>
                            <span><v-icon dark>mdi-calendar-plus</v-icon></span>
                          </template>
                          <v-container grid-list-md class="mt-6">
                            <v-form
                              dense
                              ref="form"
                              v-model="valid"
                              enctype="multipart/form-data"
                              lazy-validation
                            >
                              <v-layout row wrap>
                                <v-flex xs12 md5>
                                  <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="fechaInhabil.dia"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        flat
                                        label="Fecha"
                                        outlined
                                        dense
                                        :rules="generalRegla"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fechaInhabil.dia"
                                      @input="menu = false"
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12 md5>
                                  <v-text-field
                                    v-model="fechaInhabil.motivo"
                                    flat
                                    outlined
                                    label="Motivo"
                                    dense
                                    :rules="generalRegla"
                                  >
                                  </v-text-field>
                                </v-flex>
                                <v-flex xs12 md2>
                                  <v-btn
                                    class="mx-0"
                                    depressed
                                    @click="agregar()"
                                  >
                                    Agregar
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-form>
                          </v-container>
                        </v-timeline-item>

                        <v-slide-x-transition group>
                          <v-timeline-item
                            v-for="event in events"
                            :key="event.id"
                            class="mb-4"
                            color="pink"
                          >
                            <v-row justify="space-between">
                              <v-col cols="5">{{ event.motivo }}</v-col>
                              <v-col class="text-right" cols="5"
                                >{{ event.dia }}
                              </v-col>
                              <v-col cols="2">
                                <v-icon @click="eliminar(event)" class="ml-2"
                                  >mdi-delete</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-timeline-item>
                        </v-slide-x-transition>
                      </v-timeline>
                    </v-container>
                  </v-container>
                </v-card>
              </v-flex>
            </v-container>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      events: [],
      menu: false,
      fechaInhabil: {
        id: 1,
        dia: null,
        motivo: "",
      },
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},

  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "feriados",
        })
        .then((result) => {
          var fechas = result.data.feriados;
          for (var i = 0; i < fechas.length; i++) {
            fechas[i].dia = this.formatearFecha(fechas[i].dia);
          }
          this.events = fechas;
        });
    },
    agregar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "feriados";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.fechaInhabil,
          })
          .then((result) => {
            result.data.feriado.dia = this.formatearFecha(
              result.data.feriado.dia
            );
            this.events.unshift(result.data.feriado);
          });
      }
    },

    eliminar(item) {
      console.log("item: ", item);
      this.$refs.confirmar
        .open("Confirmación", "¿Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `feriados/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                this.events.splice(this.events.indexOf(item), 1);
              });
          }
        });
    },
    formatearFecha(fecha) {
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      // Crear un objeto Date a partir de la cadena de fecha
      const [year, month, day] = fecha.split("-");
      const fechaObj = new Date(year, month - 1, day);

      // Obtener el día y el mes con el nombre correspondiente
      const dia = fechaObj.getDate();
      const mesNombre = meses[fechaObj.getMonth()];

      return `${dia} de ${mesNombre}`;
    },
  },
};
</script>