<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title class="display-1" primary-title>
            <strong>Nuevo</strong>
          </v-card-title>
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="460"
          >
            <v-card-text>
              <v-form
                dense
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                lazy-validation
              >
                <v-layout row wrap>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="Nombre del servicio"
                        v-model="servicio.nombre"
                        :rules="generalRegla"
                        prepend-icon="mdi-mixcloud"
                        hint="telefono, cobro a domicilio..."
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-select
                        v-model="servicio.tipo_servicio"
                        :items="tipos"
                        item-text="tipo"
                        item-value="id"
                        :rules="generalRegla"
                        label="Tipo de servicio"
                        prepend-icon="mdi-earth"
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item @click="dialogTipo = true">
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon>mdi-mixcloud</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>Agregar</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field
                        label="Costo"
                        v-model="servicio.costo"
                        :rules="generalRegla"
                        prepend-icon="mdi-cash-usd"
                        hint="¿Cuanto cobraras por este servicio?"
                        type="number"
                        prefix="$"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Velocidad de bajada"
                        v-model="servicio.bajada"
                        :rules="velocidadRegla"
                        prepend-icon="mdi-cloud-download"
                        type="number"
                        suffix="MB"
                        hint="Max limit"
                        persistent-hint
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Velocidad de subida"
                        v-model="servicio.subida"
                        :rules="velocidadRegla"
                        prepend-icon="mdi-cloud-upload"
                        type="number"
                        suffix="MB"
                        hint="Max limit"
                        persistent-hint
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-layout>
                <v-divider></v-divider>
                <v-layout row wrap>
                  <v-autocomplete
                    v-model="servicio.zonas"
                    :disabled="isUpdating"
                    :items="zonas"
                    chips
                    outlined
                    label="Servicio disponible en las zonas"
                    item-text="nombre"
                    item-value="id"
                    multiple
                  >
                    <v-spacer></v-spacer>
                    <template v-slot:selection="data">
                      <v-chip
                        dark
                        color="purple"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        {{ data.item.nombre }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content>{{
                          data.item
                        }}</v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar
                          color="teal"
                          class="headline font-weight-light white--text"
                        >
                          {{ data.item.nombre.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.nombre }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-model="servicio.detalles"
                      label="Detalles"
                      outlined
                      rows="3"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <strong>Configuraciones avanzadas</strong>
                    <br />
                    Modificar mas opciones de la cola simple que se registrara
                    en el Mikrotik
                  </v-flex>
                  <v-spacer></v-spacer>
                </v-layout>
                <v-layout row wrap>
                  <v-layout row wrap>
                    <v-flex xs12 md6> Burst limit </v-flex>
                    <v-flex xs12 md6> Burst threshold </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Bajada"
                        v-model="servicio.burst_limit_bajada"
                        :rules="velocidadRegla"
                        prepend-icon="mdi-speedometer"
                        type="number"
                        suffix="MB."
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Subida"
                        v-model="servicio.burst_limit_subida"
                        :rules="velocidadRegla"
                        type="number"
                        suffix="MB."
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Bajada"
                        v-model="servicio.burst_threshold_bajada"
                        :rules="velocidadRegla"
                        prepend-icon="mdi-ray-vertex"
                        type="number"
                        suffix="MB."
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Subida"
                        v-model="servicio.burst_threshold_subida"
                        :rules="velocidadRegla"
                        type="number"
                        suffix="MB."
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-layout>
                <v-layout row wrap>
                  <v-layout row wrap>
                    <v-flex xs12 md6> Burst Time </v-flex>
                    <v-flex xs12 md6> Limit At </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Bajada"
                        v-model="servicio.burst_time_bajada"
                        :rules="velocidadRegla"
                        prepend-icon="mdi-timer"
                        type="number"
                        suffix="s"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Subida"
                        v-model="servicio.burst_time_subida"
                        :rules="velocidadRegla"
                        type="number"
                        suffix="s"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Bajada"
                        v-model="servicio.limit_at_bajada"
                        :rules="velocidadRegla"
                        prepend-icon="mdi-sign-caution"
                        type="number"
                        suffix="MB."
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        label="Subida"
                        v-model="servicio.limit_at_subida"
                        :rules="velocidadRegla"
                        type="number"
                        suffix="MB."
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-flex class="mt-2" xs12>
                    <v-slider
                      v-model="servicio.prioridad"
                      class="align-center"
                      label="Prioridad"
                      max="8"
                      min="1"
                      color="orange darken-3"
                      step="1"
                      ticks="always"
                      tick-size="5"
                      thumb-label="always"
                      :thumb-size="24"
                    >
                    </v-slider>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn color="primary" class="rounded-pill pa-3" @click="nuevo()">
              Registrar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTipo" max-width="400" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title class="display-1" primary-title>
            <strong>Nuevo</strong>
          </v-card-title>
          <v-card-text>
            <p class="text-subtitle-2">Agrega un nuevo tipo de servicio</p>
            <v-form dense ref="formTipo" v-model="validTipo" lazy-validation>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Tipo de servicio"
                    v-model="nuevoTipo.tipo"
                    :rules="generalRegla"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogTipo = false">Cancelar</v-btn>
            <v-btn
              class="rounded-pill pa-3"
              color="primary"
              @click="registrarTipo()"
              :disabled="!validTipo"
            >
              Registrar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Static from "../../Common";
export default {
  data() {
    return {
      servicio: {
        nombre: "",
        costo: "",
        subida: 0,
        bajada: 0,
        detalles: "",
        tipo_servicio: "",
        burst_limit_bajada: null,
        burst_limit_subida: null,
        burst_threshold_bajada: null,
        burst_threshold_subida: null,
        burst_time_bajada: null,
        burst_time_subida: null,
        limit_at_bajada: null,
        limit_at_subida: null,
        prioridad: 7,
        zonas: [],
      },
      valid: true,
      validTipo: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      velocidadRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => v > 0 || "necesita ser mayor a 0",
      ],
      tipos: [],
      dialogTipo: false,
      nuevoTipo: {
        tipo: "",
      },
      dialog: false,
      isUpdating: false,
      zonas: [],
    };
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    nuevo() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "servicio";
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.servicio,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.servicio);
          });
      }
    },

    limpiar() {
      this.$refs.form.reset();
    },
    mostrar() {
      this.getDatosInicio();
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatosInicio() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/formulario/servicio",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
          this.zonas = result.data.zonas;
          this.dialog = true;
        });
    },
    registrarTipo() {
      if (this.$refs.formTipo.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_servicio";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.nuevoTipo,
          })
          .then((result) => {
            this.tipos.push(result.data.tipo);
            this.servicio.tipo_servicio = result.data.tipo.id;
            this.dialogTipo = false;
          });
      }
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
    remove(item) {
      const index = this.servicio.zonas.indexOf(item.id);
      if (index >= 0) this.servicios.zonas.splice(index, 1);
    },
  },
};
</script>