<template>
  <div>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <div style="height: 300px; width: 100%">
            <l-map
              class="cool-xs shadow"
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              style="height: 100%"
              @update:center="centerUpdate"
              @update:zoom="zoomUpdate"
            >
              <l-tile-layer :url="url" :attribution="attribution" />
              <l-marker
                v-for="marker in markers"
                :key="marker.id"
                :visible="marker.visible"
                :draggable="marker.draggable"
                :lat-lng.sync="marker.position"
                :icon="marker.icon"
              >
                <l-popup :content="marker.tooltip" />
                <l-tooltip :content="marker.tooltip" />
                <l-icon
                  icon-url="img/markerCasa4.png"
                  :icon-anchor="staticAnchor"
                  :icon-size="[70, 70]"
                />
              </l-marker>
            </l-map>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LIcon,
} from "vue2-leaflet";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
  },
  data() {
    return {
      zoom: 16.5,
      center: latLng(23.0, -102.0),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(23.0, -102.0),
      showParagraph: true,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      loadingButton: false,
      staticAnchor: [26, 47],
      markers: [
        {
          id: "m1",
          position: { lat: 23.0, lng: -102.0 },
          tooltip: "Mi empresa",
          draggable: false,
          visible: true,
        },
      ],
    };
  },
  mounted() {},
  computed: {
    cliente() {
      return this.$store.getters.getShowCliente;
    },
  },
  watch: {
    cliente(val) {
      if (val != null) {
        this.markers[0].tooltip = this.cliente.FullName;
        this.markers[0].position.lng = this.cliente.longitud;
        this.markers[0].position.lat = this.cliente.latitud;
        this.center = latLng(this.cliente.latitud, this.cliente.longitud);
      }
    },
  },
  methods: {
    mostrarMarker(latitud, longitud, label) {
      this.markers[0].tooltip = label;
      this.markers[0].position.lng = longitud;
      this.markers[0].position.lat = latitud;
      this.center = latLng(this.cliente.latitud, this.cliente.longitud);

      if (
        latitud != "null" &&
        longitud != "null" &&
        latitud != null &&
        longitud != null &&
        latitud != "" &&
        longitud != ""
      ) {
        this.marker = latLng(latitud, longitud);
        this.center = latLng(latitud, longitud);
        this.zoom = 16;
        this.currentZoom = 16;
        this.showMap = true;
      }
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
  },
};
</script>


