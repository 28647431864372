<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";

export default {
  components: {
    apexchart,
  },
  data() {
    return {
      mostrar: false,
      series: [
        {
          name: "Promesas",
          data: [],
        },
        {
          name: "Liquidados",
          data: [],
        },
        {
          name: "Pendientes",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        title: {
          text: "Pagos mes a mes",
          offsetX: 0,
          style: {
            fontSize: "15px",
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
        },
        yaxis: {
          title: {
            text: "$ Cantidad",
          },
          labels: {
            formatter: function (y) {
              return Common.formatMoney(y);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Common.formatMoney(val);
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/estadisticas/datos`,
          data: {},
        })
        .then((result) => {
          var datos = result.data.meses;
          let liquidados = [];
          let pendientes = [];
          let promesas = [];

          for (let index = 0; index < datos.length; index++) {
            liquidados.push(datos[index].liquidado);
            pendientes.push(datos[index].pendiente);
            promesas.push(datos[index].promesa);
          }

          this.series = [
            {
              name: "Promesas",
              data: promesas,
            },
            {
              name: "Liquidados",
              data: liquidados,
            },
            {
              name: "Pendientes",
              data: pendientes,
            },
          ];
          this.mostrar = true;
        });
    },
  },
};
</script>
