<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat class="cool pa-4">
          <v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  v-model="search"
                  @keyup.enter="getDatos"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  class="rounded-pill"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            dense
            :search="search"
            :headers="headers"
            :items="elementos"
            :options.sync="options"
            :server-items-length="totalElementos"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-format-horizontal-align-left',
              lastIcon: 'mdi-format-horizontal-align-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por página:',
              itemsPerPageOptions: [10, 100, 500, 1000, -1],
            }"
          >
            <template v-slot:no-results>
              <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
              >
            </template>
            <template v-slot:item.imagen="{ item }">
              <v-flex xs12>
                <v-img
                  class="cool-xs ma-1"
                  v-if="item.cpe.imagen"
                  :src="item.cpe.imagen"
                  height="60"
                  width="60"
                  ma-2
                ></v-img>
                <v-img
                  v-else
                  class="cool-xs ma-1"
                  src="/img/noimagen.jpg"
                  height="60"
                  width="60"
                ></v-img>
              </v-flex>
            </template>
            <template v-slot:item.modem="{ item }">
              <v-flex xs12>
                <v-img
                  class="cool-xs ma-1"
                  v-if="item.modem.imagen"
                  :src="item.modem.imagen"
                  height="60"
                  width="60"
                  ma-2
                ></v-img>
                <v-img
                  v-else
                  class="cool-xs ma-1"
                  src="/img/noimagen.jpg"
                  height="60"
                  width="60"
                ></v-img>
              </v-flex>
            </template>
            <template v-slot:item.nombre="{ item }">
              <v-chip
                v-if="item.nombreCliente"
                class="mb-1"
                outlined
                dark
                small
                color="cyan"
                @click="abrirCliente(item.clienteId)"
                ><v-icon left>mdi-account</v-icon>
                {{ item.nombreCliente }}</v-chip
              >
              <strong v-else>
                {{ item.nombre }}
              </strong>
            </template>
            <template v-slot:item.ip="{ item }">
              <v-btn
                v-if="item.ip"
                text
                color="primary"
                @click="abrirIp(item.ip)"
              >
                {{ item.ip }}
              </v-btn>
              <v-btn v-else text color="indigo" @click="abrirIp(item.ip)">
                Pendiente
              </v-btn>
            </template>
            <template v-slot:item.ping="{ item }">
              <v-tooltip v-if="item.ping" bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    class="ma-1"
                    outlined
                    :color="colorPing(item.ping)"
                    v-on="on"
                    dark
                    small
                  >
                    <v-icon left>mdi-heart-pulse</v-icon>
                    {{ item.ping.ping ? item.ping.ping + "ms" : "--" }}
                  </v-chip>
                </template>
                <span v-if="item.ping"
                  >Actualización: <br />
                  Hora: {{ item.ping.created_at.split(" ")[1] }}
                  <br />
                  Fecha: {{ item.ping.created_at.split(" ")[0] }}</span
                >
              </v-tooltip>
            </template>
            <template v-slot:item.torre="{ item }">
              <v-chip
                small
                @click="abrirZona(item.zona_id)"
                color="pink"
                dark
                outlined
              >
                <v-icon left>mdi-radio-tower</v-icon>
                {{ item.nombreZona }}</v-chip
              >
            </template>
            <template v-slot:item.administracion="{ item }">
              <v-tooltip v-if="item.eliminado == false" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    fab
                    class="elevation-0"
                    @click="status(item)"
                    v-bind="attrs"
                    v-on="on"
                    :color="calcularColor(item.administracion)"
                    dark
                  >
                    <v-icon x-small v-if="item.administracion == 1"
                      >mdi-window-minimize</v-icon
                    >
                    <v-icon x-small v-if="item.administracion == 2"
                      >mdi-account-network</v-icon
                    >
                    <v-icon x-small v-if="item.administracion == 3"
                      >mdi-server-remove</v-icon
                    >
                  </v-btn>
                </template>
                <span
                  ><div v-if="item.administracion == 1">Simple Queue</div>
                  <div v-if="item.administracion == 2">PPPoe</div>
                  <div v-if="item.administracion == 3">Ningúna</div></span
                >
              </v-tooltip>
            </template>
            <template v-slot:item.opciones="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="detalles(item)" icon v-on="on">
                    <v-icon small> mdi-dots-horizontal </v-icon>
                  </v-btn>
                </template>
                <span>Detalles</span>
              </v-tooltip>
              <v-tooltip v-if="item.eliminado == false" top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editarAntena(item)" icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip
                v-if="item.eliminado == false && item.clienteId == null"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-btn @click="eliminar(item)" icon v-on="on">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Desactivar</span>
              </v-tooltip>

              <v-tooltip v-if="item.eliminado" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dense
                    @click="restaurar(item)"
                    :color="item.eliminado == true ? 'red' : 'green'"
                    v-on="on"
                    fab
                    x-small
                    dark
                  >
                    <v-icon>mdi-backup-restore</v-icon>
                  </v-btn>
                </template>
                <span>Restaurar</span>
              </v-tooltip>
            </template>

            <template v-slot:no-data>
              <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                <v-row align="center">
                  <v-col> Ningún dato que mostrar :( </v-col>
                  <v-col class="shrink">
                    <v-btn @click="getDatos()">
                      <v-icon pl-4>mdi-cached</v-icon> Refrescar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <editarAntena ref="refEditarAntena"></editarAntena>
  </div>
</template>
<script>
import Common from "@/js/Common";
import editarAntena from "@/js/components/red/antenas/editarAntena.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    editarAntena,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "Antena", value: "imagen", sortable: true },
        { text: "Modem", value: "modem", sortable: true },
        { text: "Cliente", value: "nombre", sortable: true },
        { text: "Torre", value: "torre", sortable: false },
        { text: "Ip", value: "ip", sortable: true },
        {
          text: "Admin",
          value: "administracion",
          sortable: false,
        },
        { text: "Ping", value: "ping", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      zona_id: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.zona_id = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `tabla/antenas/clientes/zona/` + this.zona_id,
          data: {
            search: this.search,
            filtroEstado: parseInt(this.filtroEstado),
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.loading = false;
            this.elementos = result.data.antenas.data;

            this.totalElementos = result.data.antenas.total;
          }
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `antena/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    editarAntena(item) {
      this.antenaActualizar = item;
      let antena = Object.assign({}, item);
      this.$refs.refEditarAntena.cargarDatos(antena).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.antenaActualizar),
            1,
            confirm
          );
        }
      });
    },
    colorPing(ping) {
      var latenciaAlta = 200;
      var latenciaMedia = 100;
      if (ping.ping != null) {
        var latenciaMS = ping.ping.split("ms")[0];
        var latencia = latenciaMS.split("us");

        if (latencia.length == 2) {
          ping = 0;
        }
        if (latencia >= latenciaAlta) {
          return "red";
        } else if (latencia >= latenciaMedia) {
          return "orange";
        } else {
          return "green";
        }
      } else {
        return "black";
      }
    },
    calcularColor(administracion) {
      switch (administracion) {
        case 1:
          return "green";
        case 2:
          return "indigo";
        case 3:
          return "grey";
        default:
          return "black";
      }
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    abrirCliente(id) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + id;
    },
    detalles(antena) {
      LocalStorage.set("TAB_ACTUAL_ANTENA", 0);
      document.location.href = "detalles/antena/vista/" + antena.id;
    },
  },
};
</script>