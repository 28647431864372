<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-3">
              <v-card-title class="display-1">
                <strong>Instalaciones</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="6">
                    <v-text-field
                      solo
                      class="rounded-pill"
                      v-model="search"
                      clearable
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [15, 50, 100, 500, -1],
                }"
              >
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.zona="{ item }">
                  <v-chip small color="purple" dark outlined>
                    <v-icon small left>mdi-map-marker</v-icon>
                    {{ item.zona.nombre }}</v-chip
                  >
                </template>
                <template v-slot:item.contacto="{ item }">
                  <v-chip
                    v-if="item.celular"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="green darken-4"
                    @click="llamar(item.celular)"
                  >
                    <v-icon left> mdi-whatsapp </v-icon>{{ item.celular }}
                  </v-chip>
                  <v-chip
                    v-if="item.telefono"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="blue darken-4"
                    @click="llamar(item.telefono)"
                  >
                    <v-icon left> mdi-phone </v-icon>{{ item.telefono }}
                  </v-chip>
                  <v-chip
                    v-if="item.correo"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="deep-orange darken-4"
                  >
                    <v-icon left> mdi-gmail </v-icon>{{ item.correo }}
                  </v-chip>
                </template>
                <template v-slot:item.ip="{ item }">
                  <v-btn text @click="abrirIp(item.ip)" color="blue">
                    {{ item.ip ? item.ip : "Pendiente" }}
                  </v-btn>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-tooltip v-if="item.eliminado == false" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        class="elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        :color="getColorStatus(item.status.id)"
                        dark
                      >
                        <v-icon x-small v-if="item.status_cliente_id == 1"
                          >mdi-worker</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 2"
                          >mdi-wifi</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 3"
                          >mdi-wifi-off</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 4"
                          >mdi-timer</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 5"
                          >mdi-account-off</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 6"
                          >mdi-emoticon-dead</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>{{ item.status.status }}</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        class="elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        color="black"
                        dark
                      >
                        <v-icon x-small>mdi-emoticon-dead</v-icon>
                      </v-btn>
                    </template>
                    <span> Eliminado </span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.detalles`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="detalles(item)" icon v-on="on">
                        <v-icon small>mdi-card-bulleted</v-icon>
                      </v-btn>
                    </template>
                    <span>Detalles reporte</span>
                  </v-tooltip>
                </template>

                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: false,
      options: {},
      headers: [
        { text: "Nombre", value: "FullName", sortable: false },
        { text: "Zona", value: "zona", sortable: false },
        { text: "Contacto", value: "contacto", sortable: false },
        { text: "IP", value: "ip", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Detalles", value: "detalles", sortable: false },
      ],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      if (this.loading) return;

      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `clientes/instalador/instalador/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.clientes.data;
          this.totalElementos = result.data.clientes.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    detalles(item) {
      document.location.href = "vista/detalles/instalacion/" + item.id;
    },
    getColorStatus(item) {
      if (item == 1) return "orange";
      else if (item == 2) return "green";
      else if (item == 3) return "red";
      else if (item == 4) return "indigo";
      else if (item == 5) return "grey";
      else if (item == 6) return "black";
      else return "black";
    },
    llamar(numero) {
      document.location.href = "tel:" + numero;
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
  },
};
</script>
