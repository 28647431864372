<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-card flat>
          <v-container grid-list-md>
            <v-card-title> Instalaciones </v-card-title>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-btn
                  dense
                  @click="nuevo()"
                  class="rounded-pill pa-3"
                  color="red"
                  dark
                >
                  <v-icon left>mdi-plus</v-icon>
                  Agregar
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                  solo
                  class="rounded-pill"
                  dense
                  v-model="search"
                  clearable
                  @keyup.enter="getDatos"
                  append-icon="mdi-magnify"
                  label="Buscador"
                  single-line
                  hide-details
                ></v-text-field>
              </v-layout>
            </v-container>
            <v-divider></v-divider>
            <v-data-table
              dense
              :search="search"
              :headers="headers"
              :items="elementos"
              :options.sync="options"
              :server-items-length="totalElementos"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [3, 5, 10, 15, -1],
              }"
            >
              <template v-slot:no-results>
                <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                  >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                >
              </template>
              <template v-slot:item.id="{ item }">
                {{
                  elementos
                    .map(function (x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:item.contacto="{ item }">
                <v-chip
                  v-if="item.celular"
                  class="ma-1"
                  outlined
                  small
                  dark
                  color="green darken-4"
                  ><v-icon left> mdi-whatsapp </v-icon
                  >{{ item.celular }}</v-chip
                >
                <v-chip
                  v-if="item.telefono"
                  class="ma-1"
                  outlined
                  small
                  dark
                  color="blue darken-4"
                  ><v-icon left> mdi-phone </v-icon>{{ item.telefono }}</v-chip
                >
                <v-chip
                  v-if="item.correo"
                  class="ma-1"
                  outlined
                  small
                  dark
                  color="deep-orange darken-4"
                  ><v-icon left> mdi-gmail </v-icon>{{ item.correo }}</v-chip
                >
              </template>
              <template v-slot:item.estado="{ item }">
                <v-chip dark :color="item.eliminado ? 'black' : 'green'">{{
                  item.eliminado ? "Eliminado" : "Activo"
                }}</v-chip>
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                  <v-row align="center">
                    <v-col> Ningún dato que mostrar :( </v-col>
                    <v-col class="shrink">
                      <v-btn @click="getDatos()"
                        ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </div>
    </base-component>
  </div>
</template>

<script>
import Comun from "@/js/Common.js";

export default {
  components: {},
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      hoy: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Cliente", value: "FullName", sortable: false },
        {
          text: "Fecha instalacion",
          value: "fecha_instalacion",
          sortable: true,
        },
        { text: "Hora instalacion", value: "hora_instalacion", sortable: true },
        { text: "Contacto", value: "contacto", sortable: true },
        { text: "Direccion", value: "direccion", sortable: true },
        { text: "Colonia", value: "colonia", sortable: true },
        { text: "Municipio", value: "municipio", sortable: true },
        { text: "Estado", value: "estado", sortable: true },
      ],
      dialog: false,
      PagoActualizar: {},
      resolve: null,
      reject: null,
      id: null,
      dialogEstados: false,
      validEstados: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estado_id: "",
      estados: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },

    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `personal/instalaciones/tabla/` + this.id,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.instalaciones.data;
          this.totalElementos = result.data.instalaciones.total;
        });
    },

    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    nuevo() {
      document.location.href = "/cliente/registro/instalacion";
    },
  },
};
</script>