<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-container grid-list-xs>
          <v-row>
            <v-col cols="12" md="4">
              <v-card @click="verTickets()" class="cool pa-1">
                <v-card-text>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Tickets o reportes</v-list-item-subtitle
                      >
                      <v-list-item-title class="display-1">
                        {{ tickets_pendientes }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Pendientes</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar size="50" color="red" class="shadow-lg">
                      <v-icon dark>mdi-ticket-account</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card @click="verInstalaciones()" class="cool pa-1">
                <v-card-text>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>Instalaciones</v-list-item-subtitle>
                      <v-list-item-title class="display-1">
                        {{ instalaciones_pendientes }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Pendientes</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar
                      size="50"
                      color="green"
                      class="shadow-lg"
                    >
                      <v-icon dark>mdi-account-multiple-plus</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card @click="verTrabajos()" class="cool pa-1">
                <v-card-text>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>Trabajos</v-list-item-subtitle>
                      <v-list-item-title class="display-1">
                        {{ trabajos_pendientes }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Pendientes</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar
                      size="50"
                      color="indigo"
                      class="shadow-lg"
                    >
                      <v-icon dark>mdi-wrench</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!--
          <v-row>
            <v-col cols="12">
              <v-card class="cool pa-3">
                <v-card-title primary-title>
                  Mis Datos
                </v-card-title>
                <v-card-text>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-avatar size="56">
                        <img
                          height="175"
                          v-if="persona.imagen"
                          :src="persona.imagen"
                        />
                        <img v-else height="175" src="/img/user_default.png" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ persona.FullName }}</v-list-item-title>
                        <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-map-marker </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ persona.direccion }},{{ persona.colonia }},{{ persona.municipio }},{{ persona.cp }},{{ persona.estado }}</v-list-item-title
                        >
                        <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-row>
                      <v-col cols="12" md="3" sm="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-phone </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title v-if="persona.telefono">{{persona.telefono}}</v-list-item-title>
                            <v-list-item-title v-else>No registrado</v-list-item-title>
                            <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3" sm="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-cellphone </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title v-if="persona.celular">{{persona.celular}}</v-list-item-title>
                            <v-list-item-title v-else>No registrado</v-list-item-title>
                            <v-list-item-subtitle>Celular</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3" sm="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-email </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title v-if="persona.correo">{{persona.correo}}</v-list-item-title>
                            <v-list-item-title v-else>No registrado</v-list-item-title>
                            <v-list-item-subtitle>Correo</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3" sm="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-calendar </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>{{ persona.periodo.periodo }}</v-list-item-title>
                                <v-list-item-subtitle>Periodo de pago</v-list-item-subtitle>
                              </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3" sm="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-account-star </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ persona.tipo.name }}</v-list-item-title>
                            <v-list-item-subtitle>Cargo</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3" sm="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-cake-variant </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ persona.fecha_nacimiento }}</v-list-item-title>
                            <v-list-item-subtitle>Cumpleños</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3" sm="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-cash-multiple </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ persona.sueldo }}</v-list-item-title>
                            <v-list-item-subtitle>Sueldo</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        -->
          <v-row>
            <v-col cols="12">
              <v-card class="cool pa-2">
                <v-card-title primary-title class="headline">
                  <strong>Mi semana</strong>
                </v-card-title>
                <v-row>
                  <v-col>
                    <v-sheet height="600">
                      <v-calendar
                        ref="calendar"
                        :now="today"
                        :value="today"
                        :events="events"
                        :event-color="getEventColor"
                        type="week"
                        locale="es-mx"
                        @click:event="showEvent"
                      ></v-calendar>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import Persona from "@/js/components/personal/Persona";
export default {
  data() {
    return {
      persona: new Persona(),
      today: new Date().toISOString().substr(0, 10),
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialogNuevaActividad: false,
      actividades: [],
      actividadActualizar: null,
      model: null,
      dialog_detalles: false,
      trabajos_pendientes: "0",
      tickets_pendientes: "0",
      instalaciones_pendientes: "0",
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {
    events() {
      return this.actividades.map((entry) => {
        let fecha = "";
        let hora_inicio = "";
        let nombre = "";
        //  let personal = "";
        let actividad = "";
        if (entry.trabajo) {
          nombre = "Trabajo: " + entry.trabajo;
          actividad = "Trabajo";
        }
        if (entry.asunto) {
          nombre = "Ticket: " + entry.asunto;
          actividad = "Ticket";
        }
        if (entry.FullName) {
          nombre = "Instalacion: " + entry.FullName;
          actividad = "Instalacion";
        }
        if (entry.fecha) {
          fecha = entry.fecha;
        }
        if (entry.fecha_instalacion) {
          fecha = entry.fecha_instalacion;
        }
        if (entry.hora_inicio) {
          hora_inicio = entry.hora_inicio;
        }
        if (entry.hora) {
          hora_inicio = entry.hora;
        }

        if (entry.hora_instalacion) {
          hora_inicio = entry.hora_instalacion;
        }
        if (entry.personal) {
          entry.personal = entry.personal.FullName;
        }

        if (entry.instalador) {
          entry.instalador = entry.instalador.FullName;
        }
        //  const personal_asignado =personal;
        const id = entry.id;

        const name = nombre;

        const start = fecha + " " + hora_inicio;

        const color = this.getEventColor(entry);

        if (entry.hora_fin) {
          const end = entry.fecha + " " + entry.hora_fin;

          return Object.assign({}, entry, {
            id,
            actividad,
            name,
            start,
            end,
            color,
          });
        }

        return Object.assign({}, entry, { id, actividad, name, start, color });
      });
    },
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
  },
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/instalador/home",
          data: {},
        })
        .then((result) => {
          //this.persona = result.data.persona;
          this.actividades = result.data.actividades;
          this.trabajos_pendientes = result.data.trabajosHoy + "";
          this.instalaciones_pendientes = result.data.instalacionesHoy + "";
          this.tickets_pendientes = result.data.ticketsHoy + "";
        });
    },
    showEvent({ nativeEvent, event }) {
      //this.model = event;
      switch (event.actividad) {
        case "Instalacion":
          window.location.href = "vista/detalles/instalacion/" + event.id;
          break;
        case "Trabajo":
          window.location.href = "vista/detalles/actividad/" + event.id;
          break;
        case "Ticket":
          window.location.href = "vista/detalles/ticket/" + event.id;

          break;
        default:
      }
    },
    verTrabajos() {
      window.location.href = "/home/instalador/trabajo";
    },
    verTickets() {
      window.location.href = "/home/instalador/tickets";
    },
    verInstalaciones() {
      window.location.href = "/home/instalador/instalaciones";
    },
    iniciarInstalacion() {
      this.$store
        .dispatch("UsersModule/OnSave", {
          url: "iniciar/instalacion/cliente/" + this.model.id,
          data: {},
        })
        .then((result) => {
          dialog_detalles = false;
        });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    calcularColor(event) {
      return "primary";
    },
    getEventColor(event) {
      if (event.trabajo) {
        return "cyan darken-1";
      }
      if (event.asunto) {
        return "orange";
      }
      if (event.FullName) {
        return "light-green darken-1";
      }
      return "light-green darken-1";
    },
  },
};
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>
