<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="display-1">
            <strong>Nuevo</strong>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation dense>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-autocomplete
                    v-model="pago.cliente_id"
                    :items="clientes"
                    item-text="FullName"
                    max-width="30"
                    item-value="id"
                    :loading="isLoading"
                    :rules="generalRegla"
                    :search-input.sync="search"
                    hide-no-data
                    hide-selected
                    label="Cliente"
                    placeholder="Empieza a escribir para búscar"
                    prepend-icon="mdi-database-search"
                  >
                    <template max-width="60" v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="indigo"
                        class="headline font-weight-light white--text"
                      >
                        {{ item.nombre.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.FullName
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.direccion
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle
                          >Télefono: {{ item.telefono }} Celular:
                          {{ item.celular }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 md4>
                  <v-autocomplete
                    :items="servicios"
                    v-model="pago.servicio_id"
                    item-text="nombre"
                    item-value="id"
                    label="Servicio contratados del cliente"
                    :rules="generalRegla"
                    prepend-icon="mdi-server"
                    no-data-text="Sin elementos"
                  >
                    <template max-width="60" v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="indigo"
                        class="headline font-weight-light white--text"
                      >
                        {{ item.nombre.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                        <v-list-item-title>
                          <strong> ${{ item.costo }} </strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.tipo.tipo
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle
                          >{{ item.bajada }}MB/{{
                            item.subida
                          }}MB</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 md4>
                  <v-select
                    :items="status"
                    v-model="pago.status_pago_id"
                    item-text="status"
                    item-value="id"
                    :rules="generalRegla"
                    label="status de pago"
                    prepend-icon="mdi-square-inc-cash"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 md4>
                  <v-select
                    :items="meses"
                    v-model="pago.mes_id"
                    item-text="mes"
                    item-value="id"
                    :rules="generalRegla"
                    label="Mes"
                    filled
                    hint="mes al que corresponde el pago"
                    prepend-icon="mdi-calendar-blank"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md4>
                  <v-select
                    :items="anios"
                    v-model="pago.anio"
                    :rules="generalRegla"
                    label="Año"
                    hint="año al que corresponde el pago"
                    filled
                  ></v-select>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Extra"
                    v-model="pago.extra"
                    type="number"
                    :rules="extraRegla"
                    prefix="$"
                    prepend-icon="mdi-cash"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Descuento"
                    v-model="pago.descuento"
                    type="number"
                    :rules="descuentoRegla"
                    prefix="$"
                    prepend-icon="mdi-cash"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="pago.status_pago_id == 1" row wrap>
                <v-flex xs12 md4>
                  <v-select
                    :items="tipos"
                    v-model="pago.tipo_pago_id"
                    item-text="tipo"
                    item-value="id"
                    :rules="generalRegla"
                    label="Tipo de pago"
                    prepend-icon="mdi-cash-multiple"
                  >
                    <template v-slot:append-item>
                      <v-divider></v-divider>
                      <v-list-item @click="nuevoTipoPago()">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Nuevo</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 md4>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormatted"
                        label="Pagado el dia"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es-mx"
                      v-model="pago.fecha"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md4>
                  <v-menu
                    ref="menu"
                    v-model="menu_reloj"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="pago.hora"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="pago.hora"
                        label="Hora del pago"
                        prepend-icon="mdi-av-timer"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_reloj"
                      v-model="pago.hora"
                      full-width
                      @click:minute="$refs.menu.save(pago.hora)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-textarea
                    v-model="pago.detalles"
                    rows="5"
                    label="Detalles"
                    filled
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelar()" text>Cancelar</v-btn>
            <v-btn class="rounded-pill pa-3" @click="enviar()" color="primary"
              >Registrar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <nuevoTipoPago ref="tipo_pago"></nuevoTipoPago>
  </div>
</template>
<script>
import Common from "@/js/Common.js";
import nuevoTipoPago from "@/js/components/pagos/recursos/tablas/tipos_pagos/nuevoTipoPago.vue";

export default {
  components: {
    nuevoTipoPago,
  },
  data() {
    return {
      pago: {
        monto: null,
        descuento: "0",
        extra: "0",
        detalles: "",
        fecha: "",
        hora: "",
        cliente_id: "",
        status_pago_id: "",
        mes_id: "",
        servicio_id: "",
        tipo_pago_id: "",
        anio: "",
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      extraRegla: [(v) => !!v || "Si no hay un extra poner 0"],
      descuentoRegla: [(v) => !!v || "Si no hay un descuento poner 0"],

      clientes: [],
      search: null,
      entradas: [],
      meses: [],
      servicios: [],
      status: [],
      tipos: [],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
      dialog: false,
      anios: Common.anios(),
      isLoading: false,
    };
  },
  mounted() {},
  watch: {
    "pago.status_pago_id"(val) {
      if (val != 1) {
        this.pago.tipo_pago_id = "";
        this.pago.hora = "";
        this.pago.fecha = "";
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
    search(val) {
      if (val == null) return;
      if (this.isLoading) return;
      if (val.length > 20) return;
      if (this.search.length < 3) return;

      this.isLoading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `clientes/pagos`,
          data: {
            search: this.search,
          },
        })
        .then((result) => {
          this.clientes = result.data.clientes;
          this.isLoading = false;
        });
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.pago.fecha) {
        return this.formatDate(this.pago.fecha);
      } else {
        return "";
      }
    },
  },
  methods: {
    mostrar() {
      this.getDatos();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    nuevoTipoPago() {
      this.$refs.tipo_pago.mostrar().then((resultado) => {
        if (resultado) {
          this.tipos.push(resultado);
          this.pago.tipo_pago_id = resultado.id;
        }
      });
    },
    enviar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "pago";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.pago,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.pago);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
      this.search = null;
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `datos/form/pago`,
          data: {},
        })
        .then((result) => {
          this.meses = result.data.meses;
          this.tipos = result.data.tipos;
          this.status = result.data.status;
          this.servicios = result.data.servicios;
          this.dialog = true;
        });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>