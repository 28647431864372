<template>
  <div>
    <v-row v-if="torre">
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title primary-title>
            <strong>{{ torre.nombre }}</strong>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="subtitle-1">
            <v-chip v-if="torre.zona" dark color="purple" outlined
              >{{ torre.zona.nombre }}
              <v-icon right>mdi-map-marker</v-icon>
            </v-chip>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-account </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ torre.encargado }}</v-list-item-title>
                  <v-list-item-subtitle>Encargado</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-map-marker </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ torre.direccion }}</v-list-item-title>
                  <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="torre.telefono">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-phone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ torre.telefono }}</v-list-item-title>
                  <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="torre.celular">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-cellphone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ torre.celular }}</v-list-item-title>
                  <v-list-item-subtitle>Celular</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" flat>
        <v-row>
          <v-col cols="12">
            <mapa v-if="mostrarMapa" ref="mapa"></mapa>
          </v-col>
          <v-col cols="12" md="12">
            <v-card class="cool">
              <v-card-text>
                <v-card-title primary-title> Detalles </v-card-title>
                <v-card-text>
                  {{ torre.detalles }}
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Common from "@/js/Common";
import mapa from "@/js/components/red/torres/detalles/mapaZona.vue";
import Torre from "@/js/components/red/torres/Torre.js";

export default {
  components: {
    mapa,
  },
  data() {
    return {
      torre: new Torre(),
      mostrarMapa: true,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.getDatos(arreUrl.pop());
  },
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        [],
        this.torre.latitud,
        this.torre.longitud,
        "markerTorre.png",
        this.torre.nombre,
        false
      );
    },
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `torre/` + id,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.torre = result.data.torre;
            this.esperarComponente();
          }
        });
    },

    calcularPorcentaje(voltaje) {
      var sistema12 = true;
      if (voltaje >= 15) {
        sistema12 = false;
      }
      var voltaje100 = sistema12 ? 12.7 : 24.7;
      if (voltaje >= voltaje100) {
        return "100%";
      }
      var voltaje90 = sistema12 ? 12.5 : 24.5;
      if (voltaje >= voltaje90 && voltaje < voltaje100) {
        this.iconoBateria = "mdi-battery-90";
        return "90%";
      }

      var voltaje80 = sistema12 ? 12.42 : 24.42;
      if (voltaje >= voltaje80 && voltaje < voltaje90) {
        this.iconoBateria = "mdi-battery-80";
        return "80%";
      }

      var voltaje70 = sistema12 ? 12.32 : 24.32;
      if (voltaje >= voltaje70 && voltaje < voltaje80) {
        this.iconoBateria = "mdi-battery-70";
        return "70%";
      }

      var voltaje60 = sistema12 ? 12.2 : 24.2;
      if (voltaje >= voltaje60 && voltaje < voltaje70) {
        this.iconoBateria = "mdi-battery-60";
        return "60%";
      }

      var voltaje50 = sistema12 ? 12.06 : 24.06;
      if (voltaje >= voltaje50 && voltaje < voltaje70) {
        this.iconoBateria = "mdi-battery-50";
        return "50%";
      }
      this.colorBateria = "orange";

      var voltaje40 = sistema12 ? 11.9 : 23.9;
      if (voltaje >= voltaje40 && voltaje < voltaje50) {
        this.iconoBateria = "mdi-battery-40";
        return "40%";
      }

      var voltaje30 = sistema12 ? 11.75 : 23.75;
      if (voltaje >= voltaje30 && voltaje < voltaje40) {
        this.iconoBateria = "mdi-battery-30";
        return "30%";
      }
      this.colorBateria = "red";

      var voltaje20 = sistema12 ? 11.58 : 23.58;
      if (voltaje >= voltaje20 && voltaje < voltaje30) {
        this.iconoBateria = "mdi-battery-20";
        return "20%";
      }

      var voltaje10 = sistema12 ? 11.31 : 23.31;
      if (voltaje >= voltaje10 && voltaje < voltaje20) {
        this.iconoBateria = "mdi-battery-10";
        return "10%";
      }
      this.iconoBateria = "mdi-battery-outline";

      return "0%";
    },

    obtenerColor(porcentaje) {
      const valor = parseFloat(porcentaje.replace("%", ""));
      const verde = [0, 255, 0]; // Color verde en RGB
      const rojo = [255, 0, 0]; // Color rojo en RGB

      // Interpolación lineal entre verde y rojo
      const color = rojo.map((componenteRojo, index) => {
        const componenteVerde = verde[index];
        const valorColor = Math.round(
          (componenteVerde - componenteRojo) * (valor / 100) + componenteRojo
        );
        return valorColor;
      });

      // Construir el color en formato RGB
      const colorRGB = `rgb(${color.join(", ")})`;

      return colorRGB;
    },
    colorCPU(cpu) {
      if (cpu < 10 && cpu > 50) {
        return "#1565C0";
      }
      if (cpu > 50 && cpu < 80) {
        return "#EF6C00";
      }
      if (cpu >= 80) {
        return "#BF360C";
      }
      return "#1565C0";
    },
  },
};
</script>