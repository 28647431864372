<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar" id="chart">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [
        {
          name: "Clientes",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Clientes por zona",
          position: "center",
          offsetX: 0,
          style: {
            fontSize: "12px",
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
        },
      },
      mostrar: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `graficas/datos/clientes`,
          data: {},
        })
        .then((result) => {
          this.series = [
            {
              name: "Clientes",
              data: result.data.serie_zona,
            },
          ];
          this.chartOptions.xaxis.categories = result.data.zonas;

          this.mostrar = true;
        });
    },
  },
};
</script>