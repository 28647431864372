class Reporte {
    constructor() {
        this.costo = 0;
        this.asunto = "";
        this.fecha = null;
        this.hora = null;
        this.personal = null;
        this.cliente_id = "";
        this.estado_reporte_id = 1;
        this.ayudantes = [];
        this.cliente = null;
    }
}

export default Reporte