<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-card class="cool pa-4">
            <v-container grid-list-md>
              <v-card-title class="display-1">
                <strong>Pagos</strong>
              </v-card-title>
              <v-container grid-list-md>
                <v-card flat>
                  <v-card-title primary-title>
                    <v-radio-group dense v-model="estadoPago" row>
                      <v-radio
                        dense
                        color="blue"
                        label="Todos"
                        value="0"
                      ></v-radio>
                      <v-radio
                        dense
                        color="green"
                        label="Liquidado"
                        value="1"
                      ></v-radio>
                      <v-radio
                        dense
                        color="orange"
                        label="Pendiente"
                        value="2"
                      ></v-radio>
                      <v-radio
                        dense
                        color="purple"
                        label="Promesa"
                        value="3"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-title>
                  <v-card-text>
                    <v-card outlined class="cool pa-2">
                      <v-card-title primary-title>
                        <v-text-field
                          solo
                          rounded
                          v-model="search"
                          clearable
                          @keyup.enter="getDatos"
                          append-icon="mdi-magnify"
                          label="Buscador"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          dense
                          :search="search"
                          :headers="headers"
                          :items="elementos"
                          :options.sync="options"
                          :server-items-length="totalElementos"
                          :loading="loading"
                          :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-format-horizontal-align-left',
                            lastIcon: 'mdi-format-horizontal-align-right',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageAllText: 'Todos',
                            itemsPerPageText: 'Registros por página:',
                            itemsPerPageOptions: [3, 5, 10, 15, -1],
                          }"
                        >
                          <template v-slot:no-results>
                            <v-alert
                              :value="true"
                              color="lime lighten-1"
                              icon="mdi-alert"
                              >Tu búsqueda "{{ search }}" no se
                              encuentra.</v-alert
                            >
                          </template>
                          <template v-slot:item.id="{ item }">
                            {{
                              elementos
                                .map(function (x) {
                                  return x.id;
                                })
                                .indexOf(item.id) + 1
                            }}
                          </template>
                          <template v-slot:item.estado="{ item }">
                            <v-chip
                              dense
                              text-color="white"
                              :color="calcularColor(item.status_pago_id)"
                              >{{ item.estado.status }}</v-chip
                            >
                          </template>
                          <template v-slot:item.opciones="{ item }">
                            <v-tooltip v-if="item.eliminado == false" top>
                              <template v-slot:activator="{ on }">
                                <v-btn @click="recibo(item)" icon v-on="on">
                                  <v-icon small>mdi-file-document</v-icon>
                                </v-btn>
                              </template>
                              <span>Recibo</span>
                            </v-tooltip>
                          </template>

                          <template v-slot:no-data>
                            <v-alert
                              :value="true"
                              color="red"
                              dark
                              icon="mdi-alert-circle"
                            >
                              <v-row align="center">
                                <v-col> Ningún dato que mostrar :( </v-col>
                                <v-col class="shrink">
                                  <v-btn @click="getDatos()"
                                    ><v-icon pl-4>mdi-cached</v-icon>
                                    Refrescar</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-alert>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-container>
          </v-card>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>

<script>
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import jsPDF from "jspdf";
import Comun from "@/js/Common.js";
import "jspdf-autotable";

export default {
  components: {
    exportar,
  },
  data() {
    return {
      estadoPago: "0",
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      hoy: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Monto", value: "monto", sortable: true },
        { text: "Descuento", value: "descuento", sortable: true },
        { text: "Extra", value: "extra", sortable: true },
        //{ text: "Cliente", value: "cliente.FullName", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
        { text: "Mes", value: "mes.mes", sortable: false },
        { text: "Servicio", value: "servicio.nombre", sortable: false },
        { text: "Tipo de pago", value: "tipo.tipo", sortable: false },
        { text: "Fecha", value: "fecha", sortable: true },
        { text: "Hora", value: "hora", sortable: true },
        { text: "Detalles", value: "detalles", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialog: false,
      PagoActualizar: {},
      resolve: null,
      reject: null,
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    estadoPago(val) {
      this.getDatos();
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    cliente(val) {
      if (val != null) {
        this.getDatos();
      }
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `todos/pagos/cliente/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.pagos.data;
          this.totalElementos = result.data.pagos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `pago/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      let respuestaDialog = this.$refs.refPago.nuevo();

      if (respuestaDialog == null) {
        return;
      }
      let dispatch = "UsersModule/OnSave";
      let endpoint = "pago";
      let event = "insert";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: respuestaDialog,
        })
        .then((result) => {
          this.elementos.push(result.data.pago);
          this.totalElementos = this.elementos.length;
          this.dialog = false;
          this.$refs.refPago.limpiar();
        });
    },
    editar(elemento) {
      this.PagoActualizar = elemento;
      const pago = Object.assign({}, elemento);
      this.$refs.refEditarPago.esperarDatos(pago).then((confirm) => {
        if (confirm) {
          this.actualizar(confirm);
        }
      });
    },
    actualizar(respuesta) {
      let dispatch = "UsersModule/OnUpdate";
      let endpoint = `pago/` + respuesta.id;

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: respuesta,
        })
        .then((result) => {
          this.elementos.splice(
            this.elementos.indexOf(this.PagoActualizar),
            1,
            result.data.pago
          );
        });
    },
    restaurar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de restaurar este elemento?", {
          color: "orange",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnUpdate";
            let endpoint = `pago/restaurar/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                item.eliminado = false;
              });
          }
        });
    },

    calcularColor(id) {
      if (id == 1) {
        return "green";
      }
      if (id == 2) {
        return "orange";
      }
      if (id == 3) {
        return "purple";
      }
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("pagosCliente/" + this.cliente.id);
    },
    detalles(item) {
      document.location.href = "detalles/pago/" + item.id;
    },

    recibo(item) {
      let pdfName = "Recibo-" + Comun.formatoFecha(this.hoy);
      var doc = new jsPDF();

      var nombre =
        item.cliente.nombre +
        " " +
        item.cliente.apellido_paterno +
        " " +
        item.cliente.apellido_materno;
      var direccion = item.cliente.direccion;
      var colonia = item.cliente.colonia;
      var municipio = item.cliente.municipio;
      var telefono = item.cliente.telefono;
      var celular = item.cliente.celular ? item.cliente.celular : "";
      var correo = item.cliente.correo;
      var estado = item.estado.status;
      var status = item.status_pago_id;
      var servicio = item.servicio.nombre;
      var tipoServicio = item.servicio.tipo.tipo;
      var detalles = item.servicio.detalles;
      var bajada = item.servicio.bajada;
      var subida = item.servicio.subida;
      var descuento = item.descuento;
      var extra = item.extra;
      var tipo = item.tipo ? item.tipo.tipo : "";
      var fecha = item.fecha ? item.fecha : "";
      var hora = item.hora ? item.fecha : "";
      var detallePago = item.detalles;
      var monto = item.monto;
      var creado = item.created_at;
      var logo = item.empresa.logo ? item.empresa.logo : "";

      doc.setFont("arial");
      doc.setFontSize(12);
      var lMargin = 15; //left margin in mm
      var rMargin = 15; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm
      var pageCenter = pdfInMM / 2;

      var img1 = new Image();
      img1.src = "img/recursos/tune.png";
      doc.addImage(img1, "png", 0, -2, 290, 20);
      if (logo) {
        var img2 = new Image();
        img2.src = logo;
        doc.addImage(img2, "png", 15, 25, 20, 20);
      }

      doc.setFont("helvetica");
      doc.setFontSize(15);

      var cabecera = "RECIBO DE PAGO";
      var linea = doc.splitTextToSize(cabecera, pdfInMM - lMargin - rMargin);
      doc.text(linea, pageCenter, 40, "center");

      doc.setFontSize(14);
      doc.setFont("arial");
      doc.text(15, 58, "Cliente: ");

      doc.setFontSize(12);

      doc.text(15, 65, nombre);
      doc.text(15, 70, direccion + ", " + colonia);
      doc.text(15, 75, municipio);
      doc.text(15, 80, "Telefono: " + telefono);
      doc.text(15, 85, "Celular: " + celular);
      doc.text(15, 90, "Correo: " + correo);

      doc.text(135, 65, "Estado: " + estado);

      doc.setLineWidth(1);
      doc.setDrawColor(0);
      if (status == 1) {
        doc.setFillColor(0, 255, 0);
      }
      if (status == 2) {
        doc.setFillColor(255, 0, 0);
      }
      if (status == 3) {
        doc.setFillColor(139, 0, 255);
      }
      doc.circle(150, 80, 10, "FD");

      doc.setLineWidth(1);
      doc.line(195, 100, 15, 100);
      doc.setLineWidth(0.5);
      doc.line(195, 102, 15, 102);

      doc.setFontSize(14);
      doc.text(15, 110, "Servicios: ");

      doc.text(15, 115, "Nombre: " + correo);
      doc.text(15, 120, "Tipo: " + tipoServicio);
      doc.text(15, 125, "Detalles: " + detalles);
      if (tipo == "Internet") {
        doc.text(
          15,
          130,
          "Velocidad: " + "Bajada: " + bajada + "MB / Subida: " + subida + "MB"
        );
      }

      var head = [["Descuento", "Extra", "Pago", "Fecha", "detalles", "Monto"]];
      var body = [
        [descuento, extra, tipo, fecha + "-" + hora, detallePago, monto],
      ];
      doc.autoTable({
        head: head,
        body: body,
        startY: 145,
        bodyStyles: {
          valign: "top",
        },
        styles: {
          rowPageBreak: "auto",
          overflow: "linebreak",
        },
        columnStyles: {
          text: {
            cellWidth: "wrap",
          },
        },
        margin: { left: 15, right: 15 },
      });
      doc.setFontSize(14);
      let finalY = doc.autoTable.previous.finalY;
      doc.text(160, finalY + 6, "Total: $" + monto);
      doc.setFontSize(8);
      doc.text(15, 240, "Generado el: " + creado);

      //var img = new Image();
      //img.src = "img/recursos/ola1.jpg";
      //doc.addImage(img, "jpg", 0, 247, 290, 55);

      doc.save(pdfName + ".pdf");
    },
  },
};
</script>
