<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title class="display-1" primary-title>
            <strong>Nuevo</strong>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md12>
                <v-container grid-list-md>
                  <v-form
                    dense
                    ref="form"
                    v-model="valid"
                    enctype="multipart/form-data"
                    lazy-validation
                  >
                    <v-layout row wrap>
                      <v-flex xs12 md12>
                        <v-autocomplete
                          v-model="reporte.cliente_id"
                          :rules="generalRegla"
                          :items="clientes"
                          item-text="FullName"
                          item-value="id"
                          max-width="30"
                          :search-input.sync="searchCliente"
                          hide-no-data
                          label="Cliente"
                          placeholder="Empieza a escribir para búscar"
                          prepend-icon="mdi-account"
                        >
                          <template max-width="60" v-slot:item="{ item }">
                            <v-list-item-avatar
                              color="indigo"
                              class="headline font-weight-light white--text"
                            >
                              {{ item.nombre.charAt(0) }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.FullName
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                item.direccion
                              }}</v-list-item-subtitle>
                              <v-list-item-subtitle
                                >Celular:
                                {{ item.celular }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md8>
                        <v-text-field
                          label="Asunto"
                          v-model="reporte.asunto"
                          :rules="generalRegla"
                          prepend-icon="mdi-pen"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          label="Costo"
                          v-model="reporte.costo"
                          :rules="costoRegla"
                          type="number"
                          prepend-icon="mdi-cash-usd"
                          prefix="$"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md12>
                        <v-radio-group
                          dense
                          v-model="reporte.estado_reporte_id"
                          row
                        >
                          <v-radio
                            dense
                            color="orange"
                            label="Pendiente"
                            :value="1"
                          ></v-radio>
                          <v-radio
                            dense
                            color="yellow"
                            label="En proceso"
                            :value="2"
                          ></v-radio>
                          <v-radio
                            dense
                            color="green"
                            label="Solucionado"
                            :value="3"
                          ></v-radio>
                        </v-radio-group>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted"
                              label="Dia de revisión"
                              prepend-icon="mdi-calendar"
                              readonly
                              :rules="generalRegla"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            locale="es-mx"
                            v-model="reporte.fecha"
                            no-title
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex xs12 md6>
                        <v-menu
                          ref="menu"
                          v-model="menu_reloj"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="reporte.hora"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="reporte.hora"
                              label="Hora"
                              prepend-icon="mdi-av-timer"
                              readonly
                              :rules="generalRegla"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu_reloj"
                            v-model="reporte.hora"
                            full-width
                            @click:minute="$refs.menu.save(reporte.hora)"
                          ></v-time-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md12>
                        <v-autocomplete
                          v-model="reporte.personal"
                          :items="personal"
                          item-text="FullName"
                          item-value="id"
                          max-width="30"
                          :rules="generalRegla"
                          hide-no-data
                          :search-input.sync="searchPersonal"
                          label="Personal"
                          placeholder="Empieza a escribir para búscar"
                          prepend-icon="mdi-worker"
                        >
                          <template max-width="60" v-slot:item="{ item }">
                            <v-list-item-avatar
                              color="indigo"
                              class="headline font-weight-light white--text"
                            >
                              {{ item.nombre.charAt(0) }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.FullName
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Celular:
                                {{ item.celular }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                      <v-flex xs12 md12>
                        <v-autocomplete
                          v-model="reporte.ayudantes"
                          :items="personal"
                          item-text="FullName"
                          item-value="id"
                          multiple
                          clearable
                          chips
                          prepend-inner-icon="mdi-account-multiple"
                          label="Ayudantes"
                        >
                        </v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn color="primary" class="rounded-pill pa-3" @click="nuevo()">
              Registrar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reporte: {
        costo: "0",
        asunto: "",
        fecha: null,
        hora: null,
        personal: "",
        cliente_id: "",
        estado_reporte_id: 1,
        ayudantes: [],
      },
      clientes: [],
      personal: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      costoRegla: [(v) => !!v || "poner en 0 si no hay un costo"],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
      searchPersonal: "",
      searchCliente: "",
      dialog: false,
    };
  },
  mounted() {},
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.reporte.fecha) {
        return this.formatDate(this.date);
      } else {
        return "";
      }
    },
  },
  methods: {
    mostrar() {
      this.getDatos();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/form/reporte",
          data: {},
        })
        .then((result) => {
          this.clientes = result.data.clientes;
          this.personal = result.data.personal;
          this.dialog = true;
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    nuevo() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "reporte";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.reporte,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.reporte);
          });
      }
    },
    limpiar() {
      this.reporte.costo = "0";
      this.reporte.asunto = "";
      this.reporte.fecha = null;
      this.reporte.hora = null;
      this.reporte.personal = "";
      this.reporte.cliente_id = "";
      this.searchCliente = "";
      this.searchPersonal = "";
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>