<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1">
                <strong>Tickets</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="6">
                    <v-text-field
                      solo
                      class="rounded-pill"
                      v-model="search"
                      clearable
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [3, 5, 10, 15, -1],
                }"
              >
                <template v-slot:item.costo="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="ma-2"
                        v-bind="attrs"
                        v-on="on"
                        :color="calcularColor(item.estado_reporte_id)"
                        dark
                      >
                        <v-icon small left v-if="item.estado_reporte_id == 1"
                          >mdi-timer</v-icon
                        >
                        <v-icon small left v-if="item.estado_reporte_id == 2"
                          >mdi-worker</v-icon
                        >
                        <v-icon small left v-if="item.estado_reporte_id == 3"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        {{ formatoDinero(item.costo) }}
                      </v-chip>
                    </template>
                    <span>
                      {{ item.estado.estado }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.cliente`]="{ item }">
                  <v-chip
                    v-if="item.clienteEliminado"
                    class="ma-1"
                    outlined
                    dark
                    small
                    color="black"
                  >
                    <v-icon left>mdi-emoticon-dead</v-icon>
                    {{ item.nombreCliente }}</v-chip
                  >
                  <v-chip v-else class="ma-1" outlined dark small color="cyan"
                    ><v-icon left>mdi-account</v-icon>
                    {{ item.nombreCliente }}</v-chip
                  >
                  <br />
                  <v-chip class="ma-1" outlined dark small color="orange"
                    ><v-icon left>mdi-worker</v-icon>
                    {{ item.personal.FullName }}</v-chip
                  >
                </template>

                <template v-slot:[`item.fecha`]="{ item }">
                  <v-icon left>mdi-calendar</v-icon>
                  {{ formatoFecha(item.fecha) }}
                  <br />
                  <v-icon left>mdi-clock</v-icon>
                  {{ formatoHora(item.hora) }}
                </template>
                <template v-slot:item.zona="{ item }">
                  <v-chip small color="purple" dark outlined>
                    <v-icon small left>mdi-map-marker</v-icon>
                    {{ item.cliente.zona.nombre }}</v-chip
                  >
                </template>
                <template v-slot:[`item.opciones`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="detallesReporte(item)" icon v-on="on">
                        <v-icon small>mdi-card-bulleted</v-icon>
                      </v-btn>
                    </template>
                    <span>Detalles reporte</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";

export default {
  components: {},
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "Estado y costo", value: "costo" },
        { text: "Asunto", value: "asunto" },
        { text: "Cliente/Tecnico", value: "cliente" },
        { text: "Fecha", value: "fecha" },
        { text: "Zona", value: "zona" },
        { text: "Opciones", value: "opciones" },
      ],
      dialogEstados: false,
      validEstados: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estado_id: "",
      estados: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  mounted() {
    this.getEstados();
  },
  computed: {},
  methods: {
    getEstados() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "estados/reporte",
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },
    cambiarEstado(item) {
      this.item = item;
      this.estado_id = item.id;
      this.dialogEstados = true;
    },
    actualizarEstado(item) {
      if (this.$refs.formEstados.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `reporte/cambiar/estado/` + this.item.id;
        let copiaItem = Object.assign({}, this.item);
        copiaItem.estado_reporte_id = this.estado_id;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: copiaItem,
          })
          .then((result) => {
            this.elementos.splice(
              this.elementos.indexOf(this.item),
              1,
              result.data.reporte
            );
            this.dialogEstados = false;
          });
      }
    },
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `reportes/instalador/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.tickets.data;
          this.totalElementos = result.data.tickets.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    exportar() {
      if (this.elementos.length > 0) {
        this.$refs.refExportar.cargarDatos(
          "tickets/personal/" + this.elementos[0].personal.id
        );
      }
    },
    calcularColor(id) {
      if (id == 1) {
        return "red";
      }
      if (id == 2) {
        return "amber";
      }
      if (id == 3) {
        return "green";
      }
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad);
    },
    formatoFecha(cantidad) {
      return Common.formatoFecha(cantidad);
    },
    formatoHora(cantidad) {
      return Common.formatoHora(cantidad);
    },

    detallesReporte(item) {
      document.location.href = "vista/detalles/ticket/" + item.id;
    },
  },
};
</script>