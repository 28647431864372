var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{attrs:{"xs12":""}},[_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('v-card',{staticClass:"cool pa-4",attrs:{"elevation":"4"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Perfiles ")]),_vm._v(" "),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-btn',{staticClass:"rounded-pill",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.nuevo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("\n          Agregar\n        ")],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"rounded-pill",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","solo":"","small":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-format-horizontal-align-left',
          lastIcon: 'mdi-format-horizontal-align-right',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Registros por página:',
          itemsPerPageOptions: [5, 50, 100, -1],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.velocidad",fn:function({ item }){return [_c('div',[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"blue"}},[_vm._v("mdi-arrow-up-bold-circle-outline")]),_vm._v(" "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.servicio.subida)+" ")]),_vm._v(" Mb.\n\n            "),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"pink"}},[_vm._v("mdi-arrow-down-bold-circle-outline")]),_vm._v(" "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(item.servicio.bajada)+" ")]),_vm._v(" Mb.\n          ")],1)]}},{key:"item.local_address",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":function($event){return _vm.abrirIp(item.local_address)}}},[_vm._v(_vm._s(item.local_address))])]}},{key:"item.opciones",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])])]}}])})],1)],1),_vm._v(" "),_c('nuevoPerfil',{ref:"refNuevoPerfil"}),_vm._v(" "),_c('editarPerfil',{ref:"refEditarPerfil"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }