<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card flat>
        <v-card-title class="display-1">
          <strong>Editar</strong>
        </v-card-title>
        <v-container grid-list-md>
          <v-responsive class="overflow-y-auto" max-height="450">
            <v-card-text>
              <v-form dense ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap>
                  <strong>Antena</strong>
                </v-layout>
                <v-divider></v-divider>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-autocomplete
                      v-model="antena.tipo_antena_id"
                      :items="antenas"
                      label="Tipo de antena"
                      item-value="id"
                      :rules="generalRegla"
                      item-text="producto"
                      filled
                      no-data-text="No hay elementos registrados"
                      prepend-inner-icon="mdi-access-point-network"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar color="indigo">
                          <img v-if="item.imagen" :src="item.imagen" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.producto
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Marca: {{ item.marca }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >Cantidad en stock:
                            {{ item.cantidad }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevaAntena()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-access-point-network</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12 md4>
                    <v-autocomplete
                      v-if="antena.cliente"
                      v-model="antena.cliente.router_id"
                      :items="routers_admin"
                      label="Mikrotik administración"
                      item-value="id"
                      :rules="generalRegla"
                      item-text="nombre"
                      filled
                      no-data-text="No hay elementos registrados"
                      prepend-inner-icon="mdi-server-minus"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar
                          color="indigo"
                          class="headline font-weight-light white--text"
                        >
                          {{ item.nombre.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.nombre
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Ip: {{ item.ip }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-select
                      v-model="antena.propietario_id"
                      :items="propietarios"
                      label="Propietario de la antena"
                      item-value="id"
                      filled
                      :rules="generalRegla"
                      item-text="propietario"
                      no-data-text="No hay elementos registrados"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-autocomplete
                      v-model="antena.ap_id"
                      :items="puntos_de_acceso"
                      label="Sector"
                      item-value="id"
                      :rules="generalRegla"
                      item-text="ip"
                      filled
                      no-data-text="No hay elementos registrados"
                      prepend-inner-icon="mdi-access-point-network"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar
                          color="indigo"
                          class="headline font-weight-light white--text"
                        >
                          {{ item.producto.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <strong>
                              {{ item.nombre }}</strong
                            ></v-list-item-title
                          >
                          <v-list-item-title>{{
                            item.producto
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            ><strong>Ip: </strong>
                            {{ item.ip }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevoAp()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-access-point-network</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar AP</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="antena.usuario"
                      label="Usuario antena"
                      required
                      filled
                      autocomplete="nope"
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="antena.contrasena"
                      label="Contraseña antena"
                      required
                      filled
                      autocomplete="new-password"
                      hint="opcional"
                      prepend-inner-icon="mdi-textbox-password"
                      :type="show ? 'text' : 'password'"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <strong> Tipo de administración </strong>
                </v-layout>

                <v-divider></v-divider>
                <v-container row wrap>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-radio-group v-model="antena.administracion">
                        <v-radio
                          label="Simple Queue"
                          :value="1"
                          color="green"
                        ></v-radio>
                        <v-radio
                          label="PPPoe"
                          :value="2"
                          color="indigo"
                        ></v-radio>
                        <v-radio
                          label="Ninguno"
                          :value="3"
                          color="grey"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-layout v-if="antena.administracion == 2" row wrap>
                        <v-flex xs12 md6>
                          <v-text-field
                            label="Secret"
                            filled
                            :rules="generalRegla"
                            v-model="antena.secret"
                            prepend-inner-icon="mdi-router-wireless"
                            hint="Suele ser la ip gateway de la interfaz donde esta el servidor pppoe"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field
                            filled
                            label="Contraseña"
                            :rules="generalRegla"
                            v-model="antena.password_secret"
                            prepend-inner-icon="mdi-router-wireless"
                            hint="Suele ser la ip gateway de la interfaz donde esta el servidor pppoe"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="antena.perfil_id"
                            :items="perfiles"
                            label="Perfil pppoe"
                            item-value="id"
                            :rules="generalRegla"
                            item-text="name"
                            filled
                            no-data-text="No hay elementos registrados"
                            prepend-inner-icon="mdi-server-minus"
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-avatar
                                color="indigo"
                                class="headline font-weight-light white--text"
                              >
                                {{ item.name.charAt(0) }}
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  item.name
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Local address:
                                  {{ item.local_address }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  >Remote address:
                                  {{
                                    item.remote_address
                                  }}</v-list-item-subtitle
                                >
                                <v-list-item-title>
                                  Servcio: {{ item.servicio.nombre }}
                                </v-list-item-title>
                                <v-list-item-title>
                                  ${{ item.servicio.costo }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{
                                  "Subida: " +
                                  item.servicio.bajada +
                                  "MB / Bajada:" +
                                  item.servicio.subida +
                                  "MB"
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                            <template v-slot:append-item>
                              <v-divider class="mb-2"></v-divider>
                              <v-list-item @click="nuevoPerfil()">
                                <v-list-item-avatar color="grey lighten-3">
                                  <v-icon>mdi-plus</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>Agregar</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else row wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="antena.ip"
                            label="IP"
                            filled
                            :rules="ipPrivadaRegla"
                            prepend-inner-icon="mdi-ip"
                            required
                            hint="Ip privada del rango 192.168.x.x o 172.16.x.x o 10.x.x.x"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-layout row wrap>
                  <strong>Modem Wifi</strong>
                </v-layout>
                <v-divider></v-divider>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-autocomplete
                      v-model="antena.router_casa_id"
                      :items="routers"
                      label="Router de casa"
                      filled
                      item-value="id"
                      :rules="generalRegla"
                      item-text="producto"
                      no-data-text="No hay elementos registrados"
                      prepend-inner-icon="mdi-wifi"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar color="indigo">
                          <img v-if="item.imagen" :src="item.imagen" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.producto
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Marca: {{ item.marca }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >Cantidad en stock:
                            {{ item.cantidad }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevoRouter()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-wifi</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="antena.ssid_wifi"
                      label="SSID del Wi-Fi"
                      hint="opcional"
                      filled
                      prepend-inner-icon="mdi-wifi"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="antena.contrasena_wifi"
                      label="Clave del Wi-Fi"
                      hint="opcional"
                      filled
                      prepend-inner-icon="mdi-textbox"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              class="rounded-pill pa-3"
              color="primary"
              @click="validate()"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <nuevaZona ref="nuevaZona"></nuevaZona>
    <formProducto ref="refProducto"></formProducto>
    <formAP ref="refAP"></formAP>
    <nuevoPerfil ref="refNuevoPerfil"></nuevoPerfil>
  </div>
</template>
<script>
import Antena from "@/js/components/clientes/Antena.js";
import nuevaZona from "@/js/components/red/zonas/nuevaZona.vue";
import formProducto from "@/js/components/almacen/productos/nuevoProducto.vue";
import nuevoPerfil from "@/js/components/red/routers/detalles/apartado_pppoe/perfiles/nuevoPerfil.vue";
import formAP from "@/js/components/red/enlaces/nuevoAntena.vue";

export default {
  components: {
    formProducto,
    nuevaZona,
    nuevoPerfil,
    formAP,
  },
  data() {
    return {
      antena: new Antena(),
      dialog: false,
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      ipPrivadaRegla: [
        (v) =>
          /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            v
          ) || "Ip no valida. Ejemplo: 192.168.1.10",
      ],
      routers: [],
      antenas: [],
      propietarios: [],
      show: false,
      routers_admin: [],
      zonas: [],
      puntos_de_acceso: [],
      resolve: null,
      reject: null,
      modo: "1",
      perfiles: [],
    };
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    cargarDatos(antena) {
      this.getDatosForm();
      this.antena = antena;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.antena.cpe = null;
        this.antena.mikrotik = null;
        this.antena.modem = null;
        this.antena.zona = null;
        this.antena.propietario = null;

        let dispatch = "UsersModule/OnUpdate";
        let endpoint = "antena/" + this.antena.id;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.antena,
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data.antena);
          });
      }
    },
    getDatosForm() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/antena",
        })
        .then((result) => {
          this.routers = result.data.routers;
          this.propietarios = result.data.propietarios;
          this.antenas = result.data.antenas;
          this.routers_admin = result.data.routers_admin;
          this.zonas = result.data.zonas;
          this.puntos_de_acceso = result.data.puntos_de_acceso;
          this.perfiles = result.data.perfiles;
        });
    },
    nuevoPerfil() {
      var mikrotik = this.antena.cliente.router_id;
      this.$refs.refNuevoPerfil.mostrar(mikrotik).then((resultado) => {
        if (resultado) {
          this.perfiles.push(resultado);
          this.antena.perfil_id = resultado.id;
        }
      });
    },
    nuevoAp() {
      this.$refs.refAP.mostrar().then((confirm) => {
        if (confirm) {
          confirm.producto = confirm.cpe.producto;
          this.puntos_de_acceso.push(confirm);
          this.antena.ap_id = confirm.id;
        }
      });
    },
    nuevaZona() {
      this.$refs.nuevaZona.mostrar().then((resultado) => {
        if (resultado) {
          this.zonas.push(resultado);
          this.antena.zona_id = resultado.id;
        }
      });
    },
    nuevaAntena() {
      this.$refs.refProducto.cargarDatos().then((confirm) => {
        if (confirm) {
          this.antenas.push(confirm);
          this.antena.tipo_antena_id = confirm.id;
        }
      });
    },
    nuevoRouter() {
      this.$refs.refProducto.cargarDatos().then((resultado) => {
        if (resultado) {
          this.routers.push(resultado);
          this.antena.router_casa_id = resultado.id;
        }
      });
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>
