<template>
  <div>
    <v-card flat>
      <v-container grid-list-md>
        <v-card-text>
          <v-form dense ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-autocomplete
                  v-model="cliente.router_id"
                  :items="routers_admin"
                  label="Administrar cliente por Mikrotik"
                  item-value="id"
                  :rules="generalRegla"
                  item-text="nombre"
                  filled
                  no-data-text="No hay elementos registrados"
                  prepend-inner-icon="mdi-server-minus"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      color="indigo"
                      class="headline font-weight-light white--text"
                    >
                      {{ item.nombre.charAt(0) }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Ip: {{ item.ip }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-flex>

              <v-flex xs12 md4>
                <v-autocomplete
                  v-model="antena.router_casa_id"
                  :items="routers"
                  label="Tipo de router de casa"
                  filled
                  item-value="id"
                  :rules="generalRegla"
                  item-text="producto"
                  no-data-text="No hay elementos registrados"
                  prepend-inner-icon="mdi-wifi"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar color="indigo">
                      <img v-if="item.imagen" :src="item.imagen" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.producto }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Marca: {{ item.marca }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Cantidad en stock:
                        {{ item.cantidad }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="nuevoRouter()">
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>mdi-wifi</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 md4>
                <v-autocomplete
                  v-model="antena.tipo_antena_id"
                  :items="antenas"
                  label="Tipo de antena"
                  item-value="id"
                  :rules="generalRegla"
                  item-text="producto"
                  filled
                  no-data-text="No hay elementos registrados"
                  prepend-inner-icon="mdi-access-point-network"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar color="indigo">
                      <img v-if="item.imagen" :src="item.imagen" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.producto }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Marca: {{ item.marca }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Cantidad en stock:
                        {{ item.cantidad }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="nuevaAntena()">
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>mdi-access-point-network</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-autocomplete
                  v-model="antena.ap_id"
                  :items="puntos_de_acceso"
                  label="Se enlazara al sector o antena"
                  item-value="id"
                  :rules="generalRegla"
                  item-text="ip"
                  filled
                  :search-input.sync="search_aps"
                  no-data-text="No hay elementos registrados"
                  prepend-inner-icon="mdi-access-point-network"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      color="indigo"
                      class="headline font-weight-light white--text"
                    >
                      {{ item.producto.charAt(0) }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                      <v-list-item-title>{{ item.producto }}</v-list-item-title>
                      <v-list-item-subtitle
                        ><strong>Ip: </strong>
                        {{ item.ip }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="nuevoAp()">
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>mdi-access-point-network</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Agregar AP</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  v-model="antena.servicio_id"
                  :items="servicios"
                  :rules="generalRegla"
                  :search-input.sync="search_servicios"
                  color="blue-grey lighten-2"
                  label="Servicio"
                  item-text="nombre"
                  item-value="id"
                  filled
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      color="indigo"
                      class="headline font-weight-light white--text"
                    >
                      {{ item.nombre.charAt(0) }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.nombre }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >${{ item.costo }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Tipo:{{ item.tipo.tipo }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        v-if="item.tipo.tipo == 'Internet'"
                        >{{
                          item.bajada + "MB/" + item.subida + "MB"
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>

                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="nuevoServicio()">
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-container row wrap>
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex xs12>
                    <strong> Tipo de administración </strong>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-radio-group v-model="antena.administracion" row>
                      <v-radio
                        label="Simple Queue"
                        :value="1"
                        color="green"
                      ></v-radio>
                      <v-radio
                        label="PPPoe"
                        :value="2"
                        color="purple"
                      ></v-radio>
                      <v-radio
                        label="Ninguno"
                        :value="3"
                        color="blue"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="antena.ip"
                      label="IP"
                      filled
                      :rules="ipPrivadaRegla"
                      prepend-inner-icon="mdi-ip"
                      required
                      hint="Ip privada del rango 192.168.x.x o 172.16.x.x o 10.x.x.x"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-layout v-if="antena.administracion == 2" row wrap>
                <v-flex xs12 md12>
                  <v-card outlined class="cool pa-3">
                    <v-card-title primary-title> Secret </v-card-title>
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <v-text-field
                          label="Usuario"
                          :rules="generalRegla"
                          v-model="antena.secret"
                          prepend-icon="mdi-router-wireless"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          label="Contraseña"
                          :rules="generalRegla"
                          v-model="antena.password_secret"
                          prepend-icon="mdi-router-wireless"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-autocomplete
                          v-model="antena.perfil_id"
                          :items="perfiles"
                          label="Perfil pppoe"
                          item-value="id"
                          :rules="generalRegla"
                          item-text="name"
                          :search-input.sync="search_perfiles"
                          no-data-text="No hay elementos registrados"
                          prepend-inner-icon="mdi-server-minus"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar
                              color="indigo"
                              class="headline font-weight-light white--text"
                            >
                              {{ item.name.charAt(0) }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Local address:
                                {{ item.local_address }}</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                >Remote address:
                                {{ item.remote_address }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </template>
                          <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item @click="nuevoPerfil()">
                              <v-list-item-avatar color="grey lighten-3">
                                <v-icon>mdi-plus</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>Agregar</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>

            <div>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-divider></v-divider>
                  Opciones
                </v-flex>
                <v-flex xs10>
                  <strong> Monitorear consumo </strong>
                  <br />
                  Consumo de ancho de banda del cliente.
                </v-flex>
                <v-flex xs2>
                  <v-switch
                    inset
                    v-model="cliente.monitoreo_consumo"
                  ></v-switch>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs10>
                  <strong> Monitorear ping </strong>
                  <br />
                  Monitoreo de ping a la IP de la antena del cliente.
                </v-flex>
                <v-flex xs2>
                  <v-switch inset v-model="cliente.monitoreo_ping"></v-switch>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs10>
                  <strong> Historial </strong>
                  <br />
                  Recoleccion Ips visitadas por el cliente cada cierto tiempo.
                </v-flex>
                <v-flex xs2>
                  <v-switch inset v-model="cliente.historial"></v-switch>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs10>
                  <strong> Inmunidad </strong>
                  <br />
                  El servicio del cliente no podra ser cancelado en el Mikrotik
                  aunque tenga pagos pendientes
                </v-flex>
                <v-flex xs2>
                  <v-switch inset v-model="cliente.inmunidad"></v-switch>
                </v-flex>
              </v-layout>
            </div>
            <v-layout row wrap>
              <strong>Datos opcionales</strong>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="antena.usuario"
                  label="Usuario antena"
                  required
                  filled
                  autocomplete="nope"
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="antena.contrasena"
                  label="Contraseña antena"
                  required
                  filled
                  autocomplete="new-password"
                  hint="opcional"
                  prepend-inner-icon="mdi-textbox-password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-select
                  v-model="antena.propietario_id"
                  :items="propietarios"
                  label="Propietario de la antena"
                  item-value="id"
                  filled
                  :rules="generalRegla"
                  item-text="propietario"
                  no-data-text="No hay elementos registrados"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="antena.ssid_wifi"
                  label="SSID del Wi-Fi"
                  hint="opcional"
                  filled
                  prepend-inner-icon="mdi-wifi"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="antena.contrasena_wifi"
                  label="Clave del Wi-Fi"
                  hint="opcional"
                  filled
                  prepend-inner-icon="mdi-textbox"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="cliente.costo_por_cliente"
                  label="Costo cliente"
                  type="number"
                  filled
                  hint="¿Cuanto le cuesta a la empresa este cliente?"
                  prepend-inner-icon="mdi-cash-usd"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="regresar()">Regresar</v-btn>
          <v-btn
            :disabled="!valid"
            class="rounded-pill pa-3"
            color="primary"
            @click="enviar()"
            >Finalizar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>

    <formProducto ref="refProducto"></formProducto>
    <formAP ref="refAP"></formAP>
    <formServicio ref="refServicioForm"></formServicio>
    <nuevoPerfil ref="refNuevoPerfil"></nuevoPerfil>
  </div>
</template>
<script>
import Antena from "@/js/components/clientes/Antena.js";
import formProducto from "@/js/components/almacen/productos/nuevoProducto.vue";
import formAP from "@/js/components/red/enlaces/nuevoAntena.vue";
import formServicio from "@/js/components/servicios/dialogServicioComponent.vue";
import nuevoPerfil from "@/js/components/red/routers/detalles/apartado_pppoe/perfiles/nuevoPerfil.vue";

export default {
  components: {
    formProducto,
    formAP,
    formServicio,
    nuevoPerfil,
  },
  data() {
    return {
      antena: new Antena(),
      cliente: {
        historial: false,
        monitoreo_ping: false,
        monitoreo_consumo: true,
        inmunidad: false,
        router_id: "",
        costo_por_cliente: 0,
      },

      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],

      ipPrivadaRegla: [
        (v) =>
          /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            v
          ) || "Ip no valida. Ejemplo: 192.168.1.10",
      ],
      routers: [],
      antenas: [],
      propietarios: [],
      show: false,
      routers_admin: [],
      puntos_de_acceso: [],
      pools: [],
      existePerfil: false,
      search_aps: null,
      search_perfiles: null,
      search_servicios: null,
      perfiles: [],
      servicios: [],
      empresa: null,
    };
  },
  mounted() {
    this.getDatosForm();
  },
  watch: {
    "antena.administracion": function (newVal, oldVal) {
      if (newVal == 2) {
        this.getPerfiles();
        this.antena.secret = this.getRandomUsername();
        this.antena.password_secret = this.getRandomPassword();
      }
    },
  },
  computed: {},
  methods: {
    getPerfiles() {
      if (this.cliente.router_id) {
        this.$store
          .dispatch("UsersModule/OnGet", {
            url: "perfiles/todos/router/" + this.cliente.router_id,
          })
          .then((result) => {
            if (
              !result.data.perfiles.some(
                (perfil) => perfil.id === this.antena.perfil_id
              )
            ) {
              this.antena.perfil_id = null;
            }
            this.perfiles = result.data.perfiles;
          });
      }
    },
    getDatosForm() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/antena",
          data: {},
        })
        .then((result) => {
          this.routers = result.data.routers;
          this.propietarios = result.data.propietarios;
          this.antenas = result.data.antenas;
          this.puntos_de_acceso = result.data.puntos_de_acceso;
          this.routers_admin = result.data.routers_admin;
          this.servicios = result.data.servicios;
          this.empresa = result.data.empresa;
          this.antena.contrasena_wifi = this.getRandomPasswordWifi();
          this.antena.ssid_wifi = this.getRandomSsidWifi();
        });
    },
    regresar() {
      this.antena.administracion = 1;

      this.$store.dispatch("regresarRegistroCliente", 2);
    },

    nuevoServicio() {
      this.$refs.refServicioForm.mostrar().then((confirm) => {
        if (confirm) {
          this.servicios.push(confirm);
        }
      });
    },
    nuevoPerfil() {
      this.$refs.refNuevoPerfil
        .mostrar(this.cliente.router_id)
        .then((resultado) => {
          if (resultado) {
            this.perfiles.push(resultado);
            this.antena.perfil_id = resultado.id;
          }
        });
    },
    enviar() {
      if (this.$refs.form.validate()) {
        var clienteOriginal = this.$store.getters.getCliente;
        const cliente = Object.assign({}, clienteOriginal);

        var instalacionOriginal = this.$store.getters.getInstalacion;
        const instalacion = Object.assign({}, instalacionOriginal);

        cliente.fecha_instalacion = instalacion.fecha_instalacion;
        cliente.hora_instalacion = instalacion.hora_instalacion;
        cliente.costo_instalacion = instalacion.costo_instalacion;
        cliente.dia_pago = instalacion.dia_pago;
        cliente.dia_factura = instalacion.dia_factura;
        cliente.dias_gracia = instalacion.dias_gracia;
        cliente.tipo_cliente_id = instalacion.tipo_cliente_id;
        cliente.zona_id = instalacion.zona_id;
        cliente.instalador_id = instalacion.instalador_id;
        cliente.captacion_id = instalacion.captacion_id;
        cliente.status_cliente_id = instalacion.status_cliente_id;
        cliente.detalles = instalacion.detalles;
        cliente.ayudantes = instalacion.ayudantes;
        cliente.inmunidad = this.cliente.inmunidad;
        cliente.historial = this.cliente.historial;
        cliente.tipo_persona = this.cliente.tipo_persona;
        cliente.monitoreo_consumo = this.cliente.monitoreo_consumo;
        cliente.monitoreo_ping = this.cliente.monitoreo_ping;
        cliente.costo_por_cliente = this.cliente.costo_por_cliente;
        cliente.router_id = this.cliente.router_id;

        this.antena.mikrotik_id = this.cliente.router_id;

        let datos = {
          antena: this.antena,
          cliente: cliente,
        };

        let dispatch = "UsersModule/OnSave";
        let endpoint = "cliente";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: datos,
          })
          .then((result) => {
            document.location.href = "clientes/vista";
          });
      }
    },
    nuevaAntena() {
      this.$refs.refProducto.cargarDatos().then((confirm) => {
        if (confirm) {
          this.antenas.push(confirm);
          this.antena.tipo_antena_id = confirm.id;
        }
      });
    },
    nuevoAp() {
      this.$refs.refAP.mostrar().then((confirm) => {
        if (confirm) {
          confirm.producto = confirm.cpe.producto;
          this.puntos_de_acceso.push(confirm);
          this.antena.ap_id = confirm.id;
        }
      });
    },
    nuevoRouter() {
      this.$refs.refProducto.cargarDatos().then((resultado) => {
        if (resultado) {
          this.routers.push(resultado);
          this.antena.router_casa_id = resultado.id;
        }
      });
    },
    getRandomUsername() {
      var clienteOriginal = this.$store.getters.getCliente;
      const cliente = Object.assign({}, clienteOriginal);
      var input =
        cliente.nombre +
        "_" +
        cliente.apellido_paterno +
        "_" +
        cliente.apellido_materno;
      let normalized = input.replace(/ /g, "_");
      normalized = normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      normalized = normalized.replace(/ñ/g, "n").replace(/Ñ/g, "N");
      normalized = normalized.replace(/[^\w\s]/gi, "");
      return normalized;
    },
    getRandomSsidWifi() {
      var input = this.empresa.nombre;
      let normalized = input.replace(/ /g, "_");
      normalized = normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      normalized = normalized.replace(/ñ/g, "n").replace(/Ñ/g, "N");
      normalized = normalized.replace(/[^\w\s]/gi, "");

      var ssid = normalized + "_";
      const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "0123456789";
      for (let i = 0; i < 2; i++) {
        ssid += letters.charAt(Math.floor(Math.random() * letters.length));
        ssid += letters.charAt(Math.floor(Math.random() * letters.length));
        ssid += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }

      ssid += "_Tel." + this.empresa.telefono;

      return ssid;
    },
    getRandomPassword() {
      const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numbers = "0123456789";
      const specialCharacters = "!@#$%*=?";
      let password = "";
      for (let i = 0; i < 3; i++) {
        password += letters.charAt(Math.floor(Math.random() * letters.length));
        password += letters.charAt(Math.floor(Math.random() * letters.length));
        password += numbers.charAt(Math.floor(Math.random() * numbers.length));
        password += specialCharacters.charAt(
          Math.floor(Math.random() * specialCharacters.length)
        );
      }
      return password;
    },
    getRandomPasswordWifi() {
      const letters = "abcdefghijklmnopqrstuvwxyz";
      const numbers = "0123456789";
      const specialCharacters = "@#$*=?";
      let password = "";
      for (let i = 0; i < 2; i++) {
        password += numbers.charAt(Math.floor(Math.random() * numbers.length));
        password += letters.charAt(Math.floor(Math.random() * letters.length));
        password += letters.charAt(Math.floor(Math.random() * letters.length));
        password += numbers.charAt(Math.floor(Math.random() * numbers.length));
        password += specialCharacters.charAt(
          Math.floor(Math.random() * specialCharacters.length)
        );
      }
      return password;
    },
  },
};
</script>
