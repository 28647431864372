<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card color="red lighten-2" dark>
      <v-card-title class="headline red lighten-3">
        Buscar cliente
      </v-card-title>
      <v-card-text>
        <a>Escribe un algun atributo del cliente</a>. puede ser nombre,
        domicilio, apellido, código postal, telefono, correo, etc.
      </v-card-text>
      <v-card-text>
        <v-autocomplete
          v-model="cliente"
          :items="items"
          :loading="isLoading"
          item-text="todo"
          max-width="30"
          :search-input.sync="search"
          color="white"
          hide-no-data
          hide-selected
          label="Buscar"
          placeholder="Empieza a escribir para búscar"
          prepend-icon="mdi-database-search"
          return-object
        >
          <template max-width="60" v-slot:item="{ item }">
            <v-list-item-avatar
              color="indigo"
              class="headline font-weight-light white--text"
            >
              {{ item.nombre.charAt(0) }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.FullName }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.direccion }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Zona</strong>: {{ item.zona.nombre }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>IP</strong>: {{ item.ip }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong> Celular: </strong> {{ item.celular }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelar()">Cancelar</v-btn>
        <v-btn
          class="rounded-pill pa-3"
          :disabled="!cliente"
          color="grey darken-3"
          @click="cliente = null"
        >
          Limpiar
          <v-icon right>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      descriptionLimit: 60,
      isLoading: false,
      cliente: null,
      search: null,
      dialog: false,
      entradas: [],
      resolve: null,
      reject: null,
    };
  },
  mounted() {},
  watch: {
    search(val) {
      if (val == null) return;
      if (this.isLoading) return;
      if (val.length > 20) return;
      if (this.search.length < 3) return;

      this.isLoading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `clientes/pagos`,
          data: {
            search: this.search,
          },
        })
        .then((result) => {
          this.entradas = result.data.clientes;
          this.isLoading = false;
        });
    },
    cliente(val) {
      if (val) {
        this.resolve(val.id);
        this.dialog = false;
      }
    },
  },
  computed: {
    items() {
      return this.entradas.map((entry) => {
        const todo =
          entry.nombre +
          " " +
          entry.apellido_paterno +
          " " +
          entry.apellido_materno +
          " " +
          entry.direccion +
          entry.municipio +
          entry.colonia +
          entry.telefono +
          entry.celular +
          entry.correo +
          entry.codigo_postal;
        return Object.assign({}, entry, { todo });
      });
    },
  },
  methods: {
    mostrar() {
      this.cliente = null;
      this.search = null;
      this.entradas = [];
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>