var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-format-horizontal-align-left',
    lastIcon: 'mdi-format-horizontal-align-right',
    prevIcon: 'mdi-chevron-left',
    nextIcon: 'mdi-chevron-right',
    itemsPerPageAllText: 'Todos',
    itemsPerPageText: 'Registros por página:',
    itemsPerPageOptions: [3, 5, 10, 15, -1],
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.id",fn:function({ item }){return [_vm._v("\n    "+_vm._s(_vm.elementos
        .map(function (x) {
          return x.id;
        })
        .indexOf(item.id) + 1)+"\n  ")]}},{key:"item.monto",fn:function({ item }){return [_vm._v(" $"+_vm._s(item.monto)+" ")]}},{key:"item.opciones",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.verArchivo(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Ver comprobante")])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }