<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-container grid-list-md>
          <v-layout v-if="cliente" row wrap>
            <v-flex xs12 md6>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-card
                    class="cool pa-2"
                    dark
                    :color="colorStatus(cliente.status_cliente_id)"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><h4 class="headline-2">
                            Estado: {{ cliente.status.status }}
                          </h4>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-flex>
                <v-flex xs12 md6>
                  <v-card class="cool pa-2">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><h4 class="headline-2">Contraseña Wi-Fi:</h4>
                        </v-list-item-subtitle>
                        <v-list-item-title>
                          <h5 class="headline-2">
                            <v-icon>mdi-wifi</v-icon>
                            {{ cliente.antena.contrasena_wifi }}
                          </h5></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-card class="cool pa-2">
                    <v-list-item three-line>
                      <v-list-item-avatar color="indigo" tile size="100">
                        <img
                          v-if="cliente.antena.modem.imagen"
                          :src="cliente.antena.modem.imagen"
                        />
                        <img v-else src="/img/user_default.png" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="headline-2"
                          >Modem:</v-list-item-title
                        >
                        <v-list-item-title class="headline-2">{{
                          cliente.antena.modem.producto
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          ><p>
                            Marca: {{ cliente.antena.modem.marca }}
                          </p></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-flex>
                <v-flex xs12 md6>
                  <v-card class="cool pa-2">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><h4 class="headline-2">Zona:</h4>
                        </v-list-item-subtitle>
                        <v-list-item-title
                          ><h3 class="headline-2">
                            {{ cliente.zona.nombre }}
                          </h3></v-list-item-title
                        >
                        <v-list-item-subtitle
                          ><h4 class="headline-2">
                            Propietario:{{
                              cliente.antena.propietario.propietario
                            }}
                          </h4>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md6>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-card class="cool pa-2">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-avatar color="indigo" tile size="90">
                          <img
                            v-if="cliente.antena.cpe.imagen"
                            :src="cliente.antena.cpe.imagen"
                          />
                          <img v-else src="/img/user_default.png" />
                        </v-list-item-avatar>
                        <v-list-item-title class="headline-2"
                          >CPE:{{
                            cliente.antena.cpe.producto
                          }}</v-list-item-title
                        >
                        <v-list-item-subtitle
                          ><p>
                            Marca: {{ cliente.antena.cpe.marca }}
                          </p></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-flex>
                <v-flex xs12 md6>
                  <v-card class="cool pa-2">
                    <v-container grid-list-md>
                      <v-list-item-subtitle
                        ><h4 class="headline-3">Calidad de cliente</h4>
                      </v-list-item-subtitle>

                      <v-layout justify-center row wrap>
                        <v-progress-circular
                          :rotate="-90"
                          :size="150"
                          :width="20"
                          :value="calidad"
                          color="primary"
                        >
                          %{{ value }}
                        </v-progress-circular>
                      </v-layout>
                    </v-container>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <consumoGrafica ref="consumos"></consumoGrafica>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <pingGrafica ref="ping"></pingGrafica>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import consumoGrafica from "@/js/components/home_cliente/red/consumoGrafica.vue";
import pingGrafica from "@/js/components/home_cliente/red/pingGrafica.vue";
export default {
  components: {
    consumoGrafica,
    pingGrafica,
  },
  data() {
    return {
      calidad: 0,
      subida: 0,
      bajada: 0,
      value: 0,
      cliente: null,
    };
  },
  mounted() {
    this.getCliente();
  },
  computed: {},
  methods: {
    getCliente() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "cliente/logueado/datos",
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.cliente = result.data.cliente;

            this.getDatos(result.data.cliente.id);
            //this.getConsumo(result.data.cliente.id);
          }
        });
    },
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `antena/graficas/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            let datos = result.data.grafica;
            this.calidad = result.data.calidad;
            this.value = result.data.calidad;

            if (datos.length > 0) {
              this.$refs.consumos.graficar(datos);
              this.$refs.ping.graficar(datos);
            }
          }
        });
    },
    colorStatus(id) {
      if (id == 1) {
        return "orange";
      }
      if (id == 2) {
        return "green";
      }
      if (id == 3) {
        return "red";
      }
      if (id == 4) {
        return "indigo";
      }
      if (id == 5) {
        return "grey";
      }
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>