<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar" id="chart">
        <apexchart
          type="line"
          height="300"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [],

      name: "Oscar",
      data: [],
      chartOptions: {
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          line: {
            isSlopeChart: false,
          },
        },
        tooltip: {
          followCursor: true,
          intersect: false,
          shared: true,
        },
        dataLabels: {
          background: {
            enabled: true,
          },
          formatter(val, opts) {
            const seriesName = opts.w.config.series[opts.seriesIndex].name;
            // return val !== null ? seriesName : ''
            return "";
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: true,
          },
        },
        xaxis: {
          position: "bottom",
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
        stroke: {
          width: [2, 2, 2, 2],
          dashArray: [0, 0, 0, 0],
          curve: "smooth",
        },
        title: {
          text: "Cantidad de cobros por cajero",
          offsetX: 0,
          style: {
            fontSize: "15px",
          },
        },
      },

      mostrar: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `grafica/cajeros/pagos`,
          data: {},
        })
        .then((result) => {
          this.series = result.data.series;

          this.mostrar = true;
        });
    },
  },
};
</script>