<template>
  <div v-if="antena">
    <v-container grid-list-md>
      <v-row>
        <v-col cols="12" md="6">
          <v-card outlined class="cool">
            <v-img
              v-if="antena.cpe.imagen"
              :src="antena.cpe.imagen"
              max-height="150"
            >
              <v-chip
                dark
                :color="antena.status_antena_id ? 'lime' : 'orange'"
                class="ma-4"
                ><p v-if="antena.status_antena_id" class="headline-2 ma-1">
                  Conectado
                </p>
                <p v-else class="headline-2 ma-1">Desconectado</p></v-chip
              >
            </v-img>
            <v-img v-else src="/img/noimagen.jpg" max-height="150">
              <v-chip
                dark
                :color="antena.status_antena_id ? 'lime' : 'orange'"
                class="ma-4"
                ><p v-if="antena.status_antena_id" class="headline-2 ma-1">
                  Conectado
                </p>
                <p v-else class="headline-2 ma-1">Desconectado</p></v-chip
              >
            </v-img>
            <v-card-title primary-title>
              <strong v-if="antena.nombre">{{ antena.nombre }}</strong>
              {{ antena.cpe.producto }}
            </v-card-title>
            <v-card-subtitle>
              {{ antena.cpe.marca }}
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="grey">mdi-ticket</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ antena.ip }}</v-list-item-title>
                        <v-list-item-subtitle>IP</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-archive</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          antena.usuario
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Usuario</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="antena.propietario">
                      <v-list-item-icon>
                        <v-icon color="grey">mdi-cash-minus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          antena.propietario.propietario
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Propietario</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="grey">mdi-cash-plus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content v-if="antena.zona">
                        <v-list-item-title>{{
                          antena.zona.nombre
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Zona</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="grey">mdi-cash-minus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          antena.contrasena
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Contraseña</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="antena.ping">
                      <v-list-item-icon>
                        <v-icon color="grey">mdi-cash-minus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          antena.ping.ping
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Ping</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-list>
                  <v-list-item v-if="antena.cliente">
                    <v-list-item-icon>
                      <v-icon>mdi-cellphone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p v-if="antena.administracion == 1">Simple Queue</p>
                        <p v-if="antena.administracion == 2">PPPoe</p>
                        <p v-if="antena.administracion == 3">Ninguna</p>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Modo de administracion</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="antena.cliente" cols="12" md="6">
          <v-card class="cool pa-2" outlined>
            <v-card-title primary-title>
              {{ antena.cliente.FullName }}
            </v-card-title>
            <v-card-subtitle> Cliente </v-card-subtitle>
            <v-card-text>
              <v-list two-line>
                <v-list-item
                  @click="
                    abrirEnMaps(antena.clente.latitud, antena.cliente.longitud)
                  "
                >
                  <v-list-item-icon>
                    <v-icon color="blue">mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ antena.cliente.direccion }},{{
                        antena.cliente.colonia
                      }},{{ antena.cliente.municipio
                      }}{{ antena.cliente.codigo_postal }}</v-list-item-title
                    >
                    <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="antena.cliente.telefono">
                  <v-list-item-icon>
                    <v-icon color="grey">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      antena.cliente.telefono
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="antena.cliente.celular">
                  <v-list-item-icon>
                    <v-icon>mdi-cellphone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      antena.cliente.celular
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Celular</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          <v-card class="cool pa-2 mt-2" outlined>
            <v-card-title primary-title> Modem de casa </v-card-title>
            <v-list-item three-line>
              <v-list-item-avatar color="indigo" tile size="100" class="cool">
                <img v-if="antena.modem.imagen" :src="antena.modem.imagen" />
                <img v-else src="/img/noimagen.jpg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline-2">{{
                  antena.modem.producto
                }}</v-list-item-title>
                <v-list-item-subtitle
                  ><p>Marca: {{ antena.modem.marca }}</p></v-list-item-subtitle
                >
                <v-list-item-subtitle
                  ><p>
                    Contraseña WI-FI: {{ antena.modem.contrasena_wifi }}
                  </p></v-list-item-subtitle
                >
                <v-list-item-subtitle class="headline-2"
                  >Propietario:
                  {{ antena.propietario.propietario }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card
            v-if="antena.mikrotik"
            @click="verMikrotik(antena.cliente.router_id)"
            class="cool-xs mt-2"
          >
            <v-card-title primary-title> Mikrotik </v-card-title>
            <v-list-item three-line>
              <v-list-item-avatar color="indigo" tile size="100" class="cool">
                <img
                  v-if="antena.mikrotik.producto.imagen"
                  :src="antena.mikrotik.producto.imagen"
                />
                <img v-else src="/img/noimagen.jpg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline-2">{{
                  antena.mikrotik.nombre
                }}</v-list-item-title>
                <v-list-item-subtitle
                  ><p>Ip: {{ antena.mikrotik.ip }}</p></v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-if="antena.mikrotik.tipo_conexion == 1"
                  class="headline-2"
                  >Conexion: <strong>Ip publica</strong>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="antena.mikrotik.tipo_conexion == 2"
                  class="headline-2"
                  >Conexion: <strong>Script</strong>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="antena.mikrotik.tipo_conexion == 3"
                  class="headline-2"
                  >Conexion: <strong>Ninguna</strong>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="antena.administracion == 2">
        <v-col cols="12" md="6">
          <v-card outlined class="cool">
            <v-card-title primary-title> Perfil </v-card-title>
            <v-card-subtitle>
              {{ antena.perfil.name }}
            </v-card-subtitle>
            <v-card-text>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-network</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      antena.perfil.local_address
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Local address</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="grey">mdi-pool</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      antena.perfil.remote_address
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Remote address</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="grey">mdi-server</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      antena.perfil.servicio.nombre
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Servicio</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="grey">mdi-speedometer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      "Subida:" +
                      antena.perfil.servicio.subida +
                      "Mb/Bajada: " +
                      antena.perfil.servicio.bajada +
                      "Mb"
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Velocidad</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card outlined class="cool">
            <v-card-title primary-title> Secret </v-card-title>
            <v-card-text>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account-network</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ antena.secret }}</v-list-item-title>
                    <v-list-item-subtitle>Usuario</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="grey">mdi-textbox-password</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      antena.password_secret
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Password</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      antena: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    var id = arreUrl.pop();
    this.getDatos(id);
  },

  computed: {},
  methods: {
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `antena/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            var antena = result.data.antena;
            this.antena = antena;
          }
        });
    },

    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    abrirZona(id) {
      document.location.href = "/detalles/zona/" + id;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>