<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <div style="height: 710px; width: 100%">
              <l-map
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 100%"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
              >
                <l-tile-layer :url="url" :attribution="attribution" />

                <l-marker
                  v-for="marker in markersNegocios"
                  :key="marker.id"
                  :visible="marker.visible"
                  :lat-lng.sync="marker.position"
                  :icon="marker.icon"
                >
                  <l-popup>
                    <v-col cols="12">
                      <v-card flat>
                        <v-card-title primary-title>
                          {{ marker.nombre }}
                          <v-spacer></v-spacer>
                          <v-btn
                            @click="
                              abrirEnMaps(
                                marker.position.lat,
                                marker.position.lng
                              )
                            "
                            class="mx-2"
                            fab
                            dark
                            color="indigo"
                          >
                            <v-icon dark> mdi-car-side </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-list two-line>
                            <v-list-item v-if="marker.FullName">
                              <v-list-item-avatar size="56">
                                <img height="175" src="/img/user_default.png" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  marker.FullName
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Encargado</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-map-marker </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title
                                  >{{ marker.direccion }}
                                  {{ marker.colonia }}
                                  {{ marker.municipio }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Dirección</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-store </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ marker.tipo }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  >Tipo</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-phone </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title v-if="marker.telefono">{{
                                  marker.telefono
                                }}</v-list-item-title>
                                <v-list-item-title v-else
                                  >No registrado</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Telefono</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-cellphone </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title v-if="marker.celular">{{
                                  marker.celular
                                }}</v-list-item-title>
                                <v-list-item-title v-else
                                  >No registrado</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Celular</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>

                            <v-divider></v-divider>
                            {{ marker.detalles }}
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </l-popup>

                  <l-icon
                    icon-url="img/markerNegocio.png"
                    :icon-anchor="staticAnchor"
                    :icon-size="[90, 90]"
                  />
                </l-marker>
              </l-map>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LIcon,
} from "vue2-leaflet";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
  },
  data() {
    return {
      zoom: 5,
      center: latLng(16.9, -96.5),

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 25.5,
      currentCenter: latLng(16.9, -96.5),
      //currentCenter: latLng(23.0, -102.0),
      showParagraph: true,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: false,
      loadingButton: false,
      staticAnchor: [26, 47],
      markersNegocios: [],
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  watch: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `negocios/ubicaciones/mapa`,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.crearMarcadoresNegocios(result.data.negocios);
            this.showMap = true;
          }
        });
    },
    crearMarcadoresNegocios(negocios) {
      for (let index = 0; index < negocios.length; index++) {
        const negocio = negocios[index];
        if (negocio.latitud && negocio.longitud) {
          let marcador = {
            id: +negocio.id + "n",
            position: { lat: negocio.latitud, lng: negocio.longitud },
            FullName: negocio.encargado.FullName,
            nombre: negocio.nombre,
            municipio: negocio.municipio,
            colonia: negocio.colonia,
            telefono: negocio.telefono,
            celular: negocio.celular,
            correo: negocio.correo,
            tipo: negocio.tipo.tipo,
            detalles: negocio.detalles,
            draggable: false,
            visible: true,
          };

          this.markersNegocios.push(marcador);
        }
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    abrirEnMaps(latitud, longitud) {
      if (latitud && longitud) {
        const url = `https://www.google.com/maps?q=${latitud},${longitud}`;
        window.open(url);
      }
    },
  },
};
</script>


