<template>
  <div v-if="router">
    <v-container grid-list-xs>
      <v-row>
        <v-col cols="12" md="5">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card outlined class="cool pa-2">
                <v-card-title primary-title>
                  <p class="title">{{ router.nombre }}</p>
                </v-card-title>
                <v-card-title v-if="informacion">
                  <p class="title">{{ informacion["board-name"] }}</p>
                  <p class="mb-3 ml-3 caption font-weight-thin">
                    {{ informacion.version }}
                  </p>
                </v-card-title>
                <v-card-text>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-ip </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ router.ip }}
                        </v-list-item-title>
                        <v-list-item-subtitle>IP </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-account </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ router.usuario }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Usuario</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-api </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ router.api }}
                        </v-list-item-title>
                        <v-list-item-subtitle>API </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-lan-connect </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="router.tipo_conexion == 1">
                          Ip publica
                        </v-list-item-title>
                        <v-list-item-title v-if="router.tipo_conexion == 2">
                          Script
                        </v-list-item-title>
                        <v-list-item-title v-if="router.tipo_conexion == 3">
                          Ninguna
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Tipo de Conexión
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-account-group </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ router.clientesCantidad }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Clientes administrados
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-account-cancel </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="router.cancelar_todos_clientes"
                        >
                          si
                        </v-list-item-title>
                        <v-list-item-title v-else> no </v-list-item-title>
                        <v-list-item-subtitle
                          >Suspender a todos los clientes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-calendar </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ convertirFormato(router.estado) }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Ultima conexion</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="cool pa-2" outlined>
                <v-card-title primary-title class="pb-0">
                  <v-icon>mdi-package-variant</v-icon> Producto
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-avatar tile size="100" class="cool">
                        <img
                          v-if="router.producto.imagen"
                          :src="router.producto.imagen"
                        />
                        <img v-else src="/img/noimagen.jpg" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ router.producto.producto }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                          router.producto.marca
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7">
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-card outlined class="cool pa-2">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar class="elevation-3">
                      <v-icon class="amber lighten-1"> mdi-flash </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> {{ voltaje }} V </v-list-item-title>
                      <v-list-item-subtitle>Voltaje</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-card outlined class="cool pa-2">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar class="elevation-3">
                      <v-icon class="purple lighten-1">
                        mdi-thermometer
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ temperatura }} °C
                      </v-list-item-title>
                      <v-list-item-subtitle>Temperatura</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col v-if="informacion" cols="12">
              <v-card outlined class="cool pa-2">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar class="elevation-3">
                      <v-icon class="teal lighten-1">
                        mdi-calendar-clock
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ informacion.uptime }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Tiempo de actividad</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card outlined class="cool pa-2">
                <v-card-title primary-title>
                  <v-icon>mdi-battery-medium</v-icon> Bateria
                  <v-spacer></v-spacer> {{ calcularPorcentaje(voltaje) }}
                </v-card-title>
                <v-card-text>
                  <v-progress-linear
                    height="15"
                    :color="obtenerColor(calcularPorcentaje(voltaje))"
                    class="rounded-pill"
                    :value="calcularPorcentaje(voltaje)"
                  >
                  </v-progress-linear>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="informacion" cols="5">
              <v-card class="cool pa-2" outlined>
                <v-card-title primary-title class="pb-0">
                  <v-icon>mdi-chip</v-icon> CPU
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-icon class="elevation-0">
                        <v-progress-circular
                          :value="informacion['cpu-load']"
                          :size="100"
                          :color="colorCPU(informacion['cpu-load'])"
                          :rotate="-90"
                          :width="10"
                        >
                          {{ informacion["cpu-load"] }}%</v-progress-circular
                        >
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="informacion" cols="7">
              <v-card class="cool pa-2" outlined>
                <v-card-title primary-title class="pb-0">
                  <v-icon>mdi-memory</v-icon> RAM
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-icon class="elevation-0">
                        <v-progress-circular
                          :value="
                            calcularPorcentajeUtilizado(
                              informacion['total-memory'],
                              informacion['free-memory']
                            )
                          "
                          :size="100"
                          :color="
                            colorCPU(
                              calcularPorcentajeUtilizado(
                                informacion['total-memory'],
                                informacion['free-memory']
                              )
                            )
                          "
                          :rotate="-90"
                          :width="10"
                        >
                          {{
                            calcularPorcentajeUtilizado(
                              informacion["total-memory"],
                              informacion["free-memory"]
                            )
                          }}%</v-progress-circular
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          Libre: {{ formatoBytes(informacion["free-memory"]) }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Capacidad:
                          {{
                            router.ram ? formatoBytes(router.ram) : "x"
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="informacion" cols="12">
              <v-card class="cool pa-2" outlined>
                <v-card-title primary-title class="pb-0">
                  <v-icon>mdi-disc</v-icon> Disco
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-list class="py-0">
                    <v-list-item>
                      <v-list-item-icon class="elevation-0">
                        <v-progress-circular
                          :value="
                            calcularPorcentajeUtilizado(
                              informacion['total-hdd-space'],
                              informacion['free-hdd-space']
                            )
                          "
                          :size="100"
                          :color="
                            colorCPU(
                              calcularPorcentajeUtilizado(
                                informacion['total-hdd-space'],
                                informacion['free-hdd-space']
                              )
                            )
                          "
                          :rotate="-90"
                          :width="10"
                        >
                          {{
                            calcularPorcentajeUtilizado(
                              informacion["total-hdd-space"],
                              informacion["free-hdd-space"]
                            )
                          }}%</v-progress-circular
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          Libre:
                          {{ formatoBytes(informacion["free-hdd-space"]) }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Capacidad:
                          {{
                            formatoBytes(informacion["total-hdd-space"])
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-12">
          <v-card
            v-if="router.tipo_conexion != 3"
            class="cool pa-3"
            dark
            outlined
          >
            <v-card-title primary-title>
              <v-icon>mdi-pencil-ruler</v-icon> Reglas de corte para clientes
            </v-card-title>
            <v-card-subtitle>
              Las reglas de cancelacion sirven para que el sistema pueda
              cancelar el servicio de internet a los clientes morosos
            </v-card-subtitle>
            <v-card-text>
              <v-layout v-for="regla in reglas" :key="regla.nombre" row wrap>
                <v-list-item-content>
                  <v-list-item-title>{{ regla.nombre }}</v-list-item-title>
                  <v-list-item-subtitle>{{ regla.ruta }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    regla.comentario
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="regla.detalles">{{
                    regla.detalles
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="regla.registrado"
                      x-small
                      fab
                      class="elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      dark
                      color="green"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      x-small
                      fab
                      class="elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      dark
                      color="red"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    <div v-if="regla.registrado">Regla activa en el RB</div>
                    <div v-else>Regla no encontrada en el RB</div>
                  </span>
                </v-tooltip>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Common from "@/js/Common";
import widget from "@/js/components/utileria/widget.vue";

export default {
  components: {
    widget,
  },

  data() {
    return {
      informacion: null,
      router: null,
      temperatura: null,
      voltaje: null,
      healt: null,
      id: null,
      iconoBateria: "mdi-battery",
      colorBateria: "#558B2F",
      reglas: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    obtenerColor(porcentaje) {
      const valor = parseFloat(porcentaje.replace("%", ""));
      const verde = [0, 255, 0]; // Color verde en RGB
      const rojo = [255, 0, 0]; // Color rojo en RGB

      // Interpolación lineal entre verde y rojo
      const color = rojo.map((componenteRojo, index) => {
        const componenteVerde = verde[index];
        const valorColor = Math.round(
          (componenteVerde - componenteRojo) * (valor / 100) + componenteRojo
        );
        return valorColor;
      });

      // Construir el color en formato RGB
      const colorRGB = `rgb(${color.join(", ")})`;

      return colorRGB;
    },
    convertirFormato(fechaHora) {
      var fecha = fechaHora.split(" ")[0];
      var hora = fechaHora.split(" ")[1];

      var partesFecha = fecha.split("-");
      var anio = partesFecha[0];
      var mes = partesFecha[1];
      var dia = partesFecha[2];

      var meses = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      var fechaAmigable =
        dia + " de " + meses[parseInt(mes) - 1] + " de " + anio;
      var horaAmigable = hora;

      return fechaAmigable + ", " + horaAmigable;
    },
    cargarDatos(result) {
      this.informacion = result.data.informacion;
      this.router = result.data.router;
      this.reglas = result.data.reglas;
      var healt = result.data.healt;

      if (healt) {
        this.tempAndbatery(this.informacion.version, healt);
        this.healt = healt;
      }
    },

    tempAndbatery(version, healt) {
      if (version.substring(0, 1) >= "7") {
        for (var i = 0; i < healt.length; i++) {
          var elemento = healt[i];
          if (elemento.name == "voltage") {
            this.voltaje = elemento.value;
          }
          if (elemento.name == "temperature") {
            this.temperatura = elemento.value;
          }
        }
      } else {
        this.voltaje = healt[0].voltage;
        this.temperatura = healt[0].temperature;
      }
    },
    calcularPorcentajeUtilizado(totalBytes, bytesLibres) {
      // Calcular los bytes utilizados
      const bytesUtilizados = totalBytes - bytesLibres;

      // Calcular el porcentaje utilizado
      const porcentajeUtilizado = (bytesUtilizados / totalBytes) * 100;

      // Redondear el resultado a dos decimales
      const porcentajeRedondeado = porcentajeUtilizado.toFixed(0);

      // Retornar el porcentaje utilizado
      return porcentajeRedondeado;
    },
    formatoBytes(bytes) {
      return Common.formatBytes(bytes);
    },
    colorCPU(cpu) {
      if (cpu < 10 && cpu > 50) {
        return "#1565C0";
      }
      if (cpu > 50 && cpu < 80) {
        return "#EF6C00";
      }
      if (cpu >= 80) {
        return "#BF360C";
      }
      return "#1565C0";
    },
    calcularPorcentaje(voltaje) {
      var sistema12 = true;
      if (voltaje >= 15) {
        sistema12 = false;
      }
      var voltaje100 = sistema12 ? 12.7 : 24.7;
      if (voltaje >= voltaje100) {
        return "100%";
      }
      var voltaje90 = sistema12 ? 12.5 : 24.5;
      if (voltaje >= voltaje90 && voltaje < voltaje100) {
        this.iconoBateria = "mdi-battery-90";
        return "90%";
      }

      var voltaje80 = sistema12 ? 12.42 : 24.42;
      if (voltaje >= voltaje80 && voltaje < voltaje90) {
        this.iconoBateria = "mdi-battery-80";
        return "80%";
      }

      var voltaje70 = sistema12 ? 12.32 : 24.32;
      if (voltaje >= voltaje70 && voltaje < voltaje80) {
        this.iconoBateria = "mdi-battery-70";
        return "70%";
      }

      var voltaje60 = sistema12 ? 12.2 : 24.2;
      if (voltaje >= voltaje60 && voltaje < voltaje70) {
        this.iconoBateria = "mdi-battery-60";
        return "60%";
      }

      var voltaje50 = sistema12 ? 12.06 : 24.06;
      if (voltaje >= voltaje50 && voltaje < voltaje70) {
        this.iconoBateria = "mdi-battery-50";
        return "50%";
      }
      this.colorBateria = "orange";

      var voltaje40 = sistema12 ? 11.9 : 23.9;
      if (voltaje >= voltaje40 && voltaje < voltaje50) {
        this.iconoBateria = "mdi-battery-40";
        return "40%";
      }

      var voltaje30 = sistema12 ? 11.75 : 23.75;
      if (voltaje >= voltaje30 && voltaje < voltaje40) {
        this.iconoBateria = "mdi-battery-30";
        return "30%";
      }
      this.colorBateria = "red";

      var voltaje20 = sistema12 ? 11.58 : 23.58;
      if (voltaje >= voltaje20 && voltaje < voltaje30) {
        this.iconoBateria = "mdi-battery-20";
        return "20%";
      }

      var voltaje10 = sistema12 ? 11.31 : 23.31;
      if (voltaje >= voltaje10 && voltaje < voltaje20) {
        this.iconoBateria = "mdi-battery-10";
        return "10%";
      }
      this.iconoBateria = "mdi-battery-outline";

      return "0%";
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    abrirZona(id) {
      document.location.href = "/detalles/zona/" + id;
    },
  },
};
</script>
