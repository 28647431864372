<template>
  <div>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card class="cool" flat>
            <v-card-text>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" md="4">
                  <v-text-field
                    solo
                    v-model="search"
                    clearable
                    @keyup.enter="getDatos"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    class="rounded-pill"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-data-table
              :search="search"
              :headers="headers"
              :items="elementos"
              :options.sync="options"
              :server-items-length="totalElementos"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [10, 100, 500, 1000, -1],
              }"
            >
              <template v-slot:item.antena="{ item }">
                <v-chip
                  small
                  color="primary"
                  outlined
                  @click="abrirAntena(item)"
                >
                  <v-icon left>mdi-volume-high</v-icon>
                  {{ item.antena.cpe.producto }}</v-chip
                >
              </template>
              <template v-slot:item.cliente="{ item }">
                <v-chip small color="cyan" outlined @click="abrirCliente(item)">
                  <v-icon left>mdi-account</v-icon>
                  {{ item.FullName }}</v-chip
                >
              </template>
              <template v-slot:item.ip="{ item }">
                <v-btn text color="blue" @click="abrirIp(item.antena.ip)">{{
                  item.antena.ip
                }}</v-btn>
              </template>

              <template v-slot:item.status="{ item }">
                <div>
                  <v-chip
                    small
                    dense
                    :color="getColorStatus(item.status_cliente_id)"
                    @click="editarEstado(item)"
                    dark
                    class="ma-1"
                  >
                    <v-icon small left>mdi-laptop-mac</v-icon>

                    {{ item.status.status }}</v-chip
                  >
                </div>
              </template>

              <template v-slot:item.velocidad="{ item }">
                <div class="ma-1">
                  <v-icon class="ml-2" color="blue"
                    >mdi-arrow-up-bold-circle-outline</v-icon
                  >
                  <strong class="ml-2"> {{ item.servicios[0].subida }} </strong>
                  Mb.
                </div>

                <div class="ma-1">
                  <v-icon class="ml-2" color="pink"
                    >mdi-arrow-down-bold-circle-outline</v-icon
                  >
                  <strong class="ml-2">{{ item.servicios[0].bajada }} </strong>
                  Mb.
                </div>
              </template>

              <template v-slot:no-results>
                <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                  >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                >
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                  <v-row align="center">
                    <v-col> Ningún dato que mostrar :( </v-col>
                    <v-col class="shrink">
                      <v-btn @click="getDatos()">
                        <v-icon pl-4>mdi-cached</v-icon> Refrescar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <editarEstadoCliente ref="refEstadoCliente"></editarEstadoCliente>
  </div>
</template>
  
  <script>
import LocalStorage from "@/js/LocalStorage.js";
import editarEstadoCliente from "@/js/components/clientes/listar_clientes/cambiarEstadoCliente.vue";

export default {
  components: {
    editarEstadoCliente,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Antena", value: "antena", sortable: false },
        { text: "Ip", value: "ip", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Veolcidad", value: "velocidad", sortable: false },
      ],
      id: null,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estados: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    this.getEstados();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},

  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "router/clientes/sin/conexion/" + this.id,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.loading = false;
            this.elementos = result.data.clientes.data;
            this.totalElementos = result.data.clientes.total;
            this.colaTotal = result.data.colaTotal;
          }
        });
    },
    editarEstado(elemento) {
      const cliente = Object.assign({}, elemento);
      this.$refs.refEstadoCliente.cargarDatos(cliente).then((resultado) => {
        if (resultado) {
          this.clientes = [];
          this.getDatos();
        }
      });
    },

    getEstados() {
      this.$store
        .dispatch("UsersModule/OnGetSP", {
          url: `estados/todos`,
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },

    getColorStatus(item) {
      if (item == 1) return "orange";
      else if (item == 2) return "green";
      else if (item == 3) return "red";
      else if (item == 4) return "purple";
      else if (item == 5) return "grey";
      else return "back";
    },

    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    abrirCliente(cliente) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + cliente.id;
    },
    abrirAntena(item) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "/detalles/antena/vista/" + item.antena.id;
    },
  },
};
</script>
  