<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-dialog v-model="dialogEditarServicio" max-width="800" persistent>
          <v-card>
            <v-container grid-list-md>
              <v-card-title class="display-1" primary-title>
                <strong>Editar</strong>
              </v-card-title>
              <v-responsive
                id="scroll-target"
                class="overflow-y-auto"
                :max-height="460"
              >
                <v-card-text>
                  <v-form
                    dense
                    ref="form"
                    v-model="valid"
                    enctype="multipart/form-data"
                    lazy-validation
                  >
                    <v-layout row wrap>
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <v-text-field
                            label="Nombre del servicio"
                            v-model="servicio.nombre"
                            :rules="generalRegla"
                            prepend-icon="mdi-mixcloud"
                            hint="telefono, cobro a domicilio..."
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-select
                            v-model="servicio.tipo_servicio"
                            :items="tipos"
                            item-text="tipo"
                            item-value="id"
                            :rules="generalRegla"
                            label="Tipo de servicio"
                            prepend-icon="mdi-earth"
                          >
                            <template v-slot:append-item>
                              <v-divider class="mb-2"></v-divider>
                              <v-list-item @click="dialogTipo = true">
                                <v-list-item-avatar color="grey lighten-3">
                                  <v-icon>mdi-mixcloud</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>Agregar</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-text-field
                            label="Costo"
                            v-model="servicio.costo"
                            :rules="generalRegla"
                            prepend-icon="mdi-cash-usd"
                            hint="¿Cuanto cobraras por este servicio?"
                            type="number"
                            prefix="$"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field
                            label="Velocidad de bajada"
                            v-model="servicio.bajada"
                            :rules="velocidadRegla"
                            prepend-icon="mdi-cloud-download"
                            type="number"
                            suffix="MB"
                            hint="Max limit"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field
                            label="Velocidad de subida"
                            v-model="servicio.subida"
                            :rules="velocidadRegla"
                            prepend-icon="mdi-cloud-upload"
                            type="number"
                            suffix="MB"
                            hint="Max limit"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout row wrap>
                      <v-autocomplete
                        v-model="servicio.zonas"
                        :disabled="isUpdating"
                        :items="zonas"
                        chips
                        outlined
                        label="Servicio disponible en las zonas"
                        item-text="nombre"
                        item-value="id"
                        multiple
                      >
                        <v-spacer></v-spacer>
                        <template v-slot:selection="data">
                          <v-chip
                            dark
                            color="purple"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                          >
                            {{ data.item.nombre }}
                          </v-chip>
                        </template>
                        <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content>{{
                              data.item
                            }}</v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-avatar
                              color="teal"
                              class="headline font-weight-light white--text"
                            >
                              {{ data.item.nombre.charAt(0) }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ data.item.nombre }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md12>
                        <v-textarea
                          v-model="servicio.detalles"
                          label="Detalles"
                          rows="3"
                          outlined
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <strong>Configuraciones avanzadas</strong>
                        <br />
                        Modificar mas opciones de la cola simple que se
                        registrara en el Mikrotik
                      </v-flex>
                      <v-spacer></v-spacer>
                    </v-layout>
                    <v-layout row wrap>
                      <v-layout row wrap>
                        <v-flex xs12 md6> Burst limit </v-flex>
                        <v-flex xs12 md6> Burst threshold </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Bajada"
                            v-model="servicio.burst_limit_bajada"
                            :rules="velocidadRegla"
                            prepend-icon="mdi-speedometer"
                            type="number"
                            suffix="MB."
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Subida"
                            v-model="servicio.burst_limit_subida"
                            :rules="velocidadRegla"
                            type="number"
                            suffix="MB."
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Bajada"
                            v-model="servicio.burst_threshold_bajada"
                            :rules="velocidadRegla"
                            prepend-icon="mdi-ray-vertex"
                            type="number"
                            suffix="MB."
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Subida"
                            v-model="servicio.burst_threshold_subida"
                            :rules="velocidadRegla"
                            type="number"
                            suffix="MB."
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                    <v-layout row wrap>
                      <v-layout row wrap>
                        <v-flex xs12 md6> Burst Time </v-flex>
                        <v-flex xs12 md6> Limit At </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Bajada"
                            v-model="servicio.burst_time_bajada"
                            :rules="velocidadRegla"
                            prepend-icon="mdi-timer"
                            type="number"
                            suffix="s"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Subida"
                            v-model="servicio.burst_time_subida"
                            :rules="velocidadRegla"
                            type="number"
                            suffix="s"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Bajada"
                            v-model="servicio.limit_at_bajada"
                            :rules="velocidadRegla"
                            prepend-icon="mdi-sign-caution"
                            type="number"
                            suffix="MB."
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md3>
                          <v-text-field
                            label="Subida"
                            v-model="servicio.limit_at_subida"
                            :rules="velocidadRegla"
                            type="number"
                            suffix="MB."
                          ></v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-flex class="mt-2" xs12>
                        <v-slider
                          v-model="servicio.prioridad"
                          class="align-center"
                          label="Prioridad"
                          max="8"
                          min="1"
                          color="orange darken-3"
                          step="1"
                          ticks="always"
                          tick-size="5"
                          thumb-label="always"
                          :thumb-size="24"
                        >
                        </v-slider>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
              </v-responsive>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancelar()">Cancelar</v-btn>
                <v-btn
                  :disabled="!valid"
                  class="rounded-pill pa-3"
                  color="primary"
                  @click="actualizarServicio()"
                >
                  Actualizar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogTipo" max-width="400" persistent>
          <v-card>
            <v-container grid-list-md>
              <v-card-title class="display-1" primary-title>
                <strong>Nuevo</strong>
              </v-card-title>
              <v-card-text>
                <p class="text-subtitle-2">Actuliza los datos del paquete</p>
                <v-form
                  dense
                  ref="formTipo"
                  v-model="validTipo"
                  lazy-validation
                >
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field
                        label="Tipo de servicio"
                        v-model="nuevoTipo.tipo"
                        :rules="generalRegla"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogTipo = false">Cancelar</v-btn>
                <v-btn
                  color="primary"
                  class="rounded-pill pa-3"
                  @click="registrarTipo()"
                  :disabled="!validTipo"
                >
                  Registrar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      servicio: {
        nombre: "",
        costo: "",
        subida: 0,
        bajada: 0,
        detalles: "",
        tipo_servicio: "",
        burst_limit_bajada: null,
        burst_limit_subida: null,
        burst_threshold_bajada: null,
        burst_threshold_subida: null,
        burst_time_bajada: null,
        burst_time_subida: null,
        limit_at_bajada: null,
        limit_at_subida: null,
        prioridad: 7,
        zonas: [],
      },
      valid: true,
      validTipo: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      velocidadRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => v > 0 || "necesita ser mayor a 0",
      ],
      tipos: [],
      dialogTipo: false,
      nuevoTipo: {
        tipo: "",
      },
      dialogEditarServicio: false,
      resolve: null,
      reject: null,
      isUpdating: false,
      zonas: [],
    };
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    esperarDatos(servicioEditar) {
      this.getDatosInicio(servicioEditar);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    limpiar() {
      this.$refs.form.reset();
    },
    getDatosInicio(servicioEditar) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/formulario/servicio",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
          this.servicio = servicioEditar;
          this.zonas = result.data.zonas;
          this.dialogEditarServicio = true;
        });
    },
    registrarTipo() {
      if (this.$refs.formTipo.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_servicio";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.nuevoTipo,
          })
          .then((result) => {
            this.tipos.push(result.data.tipo);
            this.servicio.tipo_servicio = result.data.tipo.id;
            this.dialogTipo = false;
          });
      }
    },

    actualizarServicio() {
      if (this.$refs.form.validate()) {
        console.log("servicio a enviar: ", this.servicio);
        this.servicio.clientes = null;
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `servicio/` + this.servicio.id;

        this.servicio.zonas = this.servicio.zonas.map((item) => item.id);

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.servicio,
          })
          .then((result) => {
            this.limpiar();
            this.dialogEditarServicio = false;
            this.resolve(result.data.servicio);
          });
      }
    },
    cancelar() {
      this.resolve(false);
      this.dialogEditarServicio = false;
    },
    remove(item) {
      const index = this.servicio.zonas.indexOf(item.id);
      if (index >= 0) this.servicios.zonas.splice(index, 1);
    },
  },
};
</script>