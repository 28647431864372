<template>
  <v-layout row wrap>
    <v-flex v-if="showChart" class="justify-center" xs12 md6>
      <apexchart
        type="pie"
        width="530"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </v-flex>
    <v-flex xs12 md6>
      <v-card class="cool">
        <v-card-title>
          <v-text-field
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="elementos" :search="search">
          <template v-slot:item.id="{ item }">
            {{
              elementos
                .map(function (x) {
                  return x.id;
                })
                .indexOf(item.id) + 1
            }}
          </template>
          <template v-slot:item.icono="{ item }">
            <v-img
              class="cool-xs ma-1"
              :src="'http://icon.horse/icon/' + item['ip_destino']"
              height="30"
              width="30"
              ma-2
            ></v-img>
            <!--
         <v-img class="cool-xs ma-1" :src="'https://icons.duckduckgo.com/ip3/'+item['dst-address']" height="30" width="30"></v-img>
                    <v-img class="cool-xs ma-1" :src="'http://t2.gstatic.com/faviconV2?domain='+item['dst-address']" height="30" width="30"></v-img>
                  <v-img class="cool-xs ma-1" :src="'https://www.google.com/s2/favicons?domain='+item['dst-address']" height="30" width="30"></v-img>

                    -->
          </template>
          <template v-slot:item.consumo="{ item }">
            <strong>{{ bytesToMegabytes(item.bytes, 3) }} Mb</strong>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import Common from "@/js/Common";
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      showChart: false,

      search: "",
      headers: [
        {
          text: "#",
          align: "start",
          filterable: false,
          value: "id",
        },
        { text: "Icono", value: "icono" },
        { text: "Ip visitada", value: "ip_destino" },
        { text: "Paquetes", value: "paquetes" },
        { text: "Consumo", value: "consumo" },
        { text: "Fecha", value: "created_at" },
      ],
      elementos: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    var id = arreUrl.pop();
    this.getDatos(id);
  },
  computed: {},
  methods: {
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `/test/ip/acounting/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.series = result.data.grafica.series;
            var labels = result.data.grafica.labels;
            this.chartOptions.labels = labels;
            this.showChart = true;
            this.elementos = result.data.datos;
          }
        });
    },
    bytesToMegabytes(bytes, decimals) {
      const megabytes = bytes / (1024 * 1024);
      return megabytes.toFixed(decimals);
    },
  },
};
</script>