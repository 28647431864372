<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-container grid-list-md>
          <v-row>
            <v-col cols="12" md="3">
              <v-card @click="verInstalaciones()" class="cool pa-1">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Instalaciones pendientes</v-list-item-subtitle
                    >
                    <v-list-item-title class="display-1">
                      {{ instalacionesPendientes }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar
                    size="50"
                    color="orange"
                    class="shadow-lg"
                  >
                    <v-icon dark>mdi-account-cash</v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card @click="verActividades()" class="cool pa-1">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Actividades pendientes</v-list-item-subtitle
                    >
                    <v-list-item-title class="display-1">
                      {{ actividadesPendientes }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar
                    size="50"
                    color="amber "
                    class="shadow-lg"
                  >
                    <v-icon dark>mdi-bank</v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card @click="irAntenas()" class="cool pa-1">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Clientes pin alto</v-list-item-subtitle
                    >
                    <v-list-item-title class="display-1">
                      {{ pingsAltos }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar
                    size="50"
                    color="red "
                    class="shadow-lg"
                    dark
                  >
                    <v-icon dark>mdi-network-strength-1-alert</v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card @click="verClientes()" class="cool pa-1">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Clientes totales</v-list-item-subtitle
                    >
                    <v-list-item-title class="display-1">
                      {{ totalClientes }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar
                    size="50"
                    color="indigo "
                    class="shadow-lg"
                    dark
                  >
                    <v-icon dark>mdi-account-multiple-check</v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-card class="cool">
                <div id="chart">
                  <apexchart
                    v-if="pingPromedio.length > 0"
                    type="radialBar"
                    height="190"
                    :options="chartOptions"
                    :series="pingPromedio"
                  ></apexchart>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="8">
              <graficaEstabilidad></graficaEstabilidad>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="7">
              <v-card class="cool pa-1">
                <apexchart
                  type="bar"
                  height="350"
                  :options="chartOptionsMeses"
                  :series="seriesMeses"
                ></apexchart>
              </v-card>
            </v-col>
            <v-col cols="12" md="5">
              <v-card class="cool pa-1" height="373">
                <v-card-title>Estado de clientes</v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item class="pr-0">
                      <v-list-item-content>
                        <v-list-item-title>Activos</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ clientesActivos }}/{{ totalClientes }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-progress-linear
                      background-color="green lighten-3"
                      color="green lighten-1"
                      :value="clientesActivosPorcentaje"
                      class="pl-4"
                    ></v-progress-linear>
                    <v-list-item class="pr-0">
                      <v-list-item-content>
                        <v-list-item-title>Suspendidos</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ clientesSuspendidos }}/{{ totalClientes }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-progress-linear
                      background-color="orange lighten-3"
                      color="orange lighten-1"
                      :value="clientesSuspendidosPorcentaje"
                      class="pl-4"
                    ></v-progress-linear>
                    <v-list-item class="pr-0">
                      <v-list-item-content>
                        <v-list-item-title
                          >Antenas conectadas</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Actualización:
                          {{ ultimaActualizacion }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ clientesConConexion }}/{{ totalClientes }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-progress-linear
                      background-color="cyan lighten-3"
                      color="cyan lighten-1"
                      :value="clientesConConexionPorcentaje"
                      class="pl-4"
                    ></v-progress-linear>
                    <v-list-item class="pr-0">
                      <v-list-item-content>
                        <v-list-item-title
                          >Antenas desconectadas</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Actualización:
                          {{ ultimaActualizacion }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ clientesSinConexion }}/{{ totalClientes }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-progress-linear
                      background-color="red lighten-3"
                      color="red lighten-1"
                      :value="clientesSinConexionPorcentaje"
                      class="pl-4"
                    ></v-progress-linear>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <graficaClientesPagos></graficaClientesPagos>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-card @click="verPagos()" class="cool pa-1">
                <v-card-title primary-title> Pagos del mes </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Liquidado</v-list-item-title>
                        <v-list-item-subtitle
                          >Cantidad: {{ liquidadosTotal }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action class="green--text">
                        {{ liquidados }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Pendientes</v-list-item-title>
                        <v-list-item-subtitle
                          >Cantidad: {{ pendientesTotal }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action class="orange--text">
                        {{ pendientes }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Promesa</v-list-item-title>
                        <v-list-item-subtitle
                          >Cantidad: {{ promesasTotal }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action class="indigo--text">
                        {{ promesas }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Retrasados</v-list-item-title>
                        <v-list-item-subtitle
                          >Cantidad: {{ retrasadosTotal }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action class="red--text">
                        {{ retrasados }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card @click="verTickets()" class="cool pa-1">
                <v-card-title primary-title> Reportes </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Pendientes</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="orange--text">
                        {{ ticketsPendientes }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>En proceso</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="blue--text">
                        {{ ticketsEnProceso }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Completados</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="green--text">
                        {{ ticketsCompletados }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card
                v-if="estadoActividades"
                @click="verActividades()"
                class="cool pa-1 mt-4"
              >
                <v-card-title primary-title> Actividades </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Pendientes</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="orange--text">
                        {{ estadoActividades.pendientes }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>En proceso</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="blue--text">
                        {{ estadoActividades.proceso }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Completados</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="green--text">
                        {{ estadoActividades.completado }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card class="cool pa-1">
                <v-card-title primary-title> Agenda de hoy </v-card-title>
                <v-container grid-list-md>
                  <v-row>
                    <v-col>
                      <v-sheet height="460">
                        <v-calendar
                          color="primary"
                          type="day"
                          locale="es-mx"
                          ref="calendar"
                          v-model="value"
                          dense
                          :events="events"
                          :event-color="getEventColor"
                          :short-months="false"
                          :show-week="false"
                          @click:event="showEvent"
                        >
                        </v-calendar>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-layout row wrap>
            <v-flex xs12 md6> </v-flex>

            <v-flex xs12 md6> </v-flex>
          </v-layout>
        </v-container>
        <v-dialog v-model="dialog_bienvenida" persistent max-width="500px">
          <v-card>
            <v-container grid-list-md>
              <v-card-title primary-title> Bienvenido </v-card-title>
              <v-card-text>
                Gracias por confiar en nosotros para comenzar a utlizar el
                sistema se tiene que registrar un router para poder administrar
                tus clientes desde InnovaISP
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="irRouters()"
                  class="rounded-pill pa-3"
                  color="success"
                  >Registrar router</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "../../Common";
import apexchart from "vue-apexcharts";
import graficaEstabilidad from "@/js/components/home/graficaEstabilidad.vue";
import graficaClientesPagos from "@/js/components/home/graficaClientesDinero.vue";
export default {
  components: {
    apexchart,

    graficaEstabilidad,
    graficaClientesPagos,
  },
  data() {
    return {
      dialog_bienvenida: false,
      actividadesPendientes: 0,
      instalacionesPendientes: 0,
      pingsAltos: 0,
      totalClientes: 0,
      //grafica nueva
      pingPromedio: [],
      chartOptions: {
        chart: {
          height: 150,
          type: "radialBar",
          offsetY: -10,
        },
        colors: ["#4CAF50"],

        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: "22px",
                color: undefined,
                formatter: function (val) {
                  if (val == 1000) {
                    return "Sin datos";
                  }
                  return val + "ms";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 25, 50, 75, 100],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["Ping promedio"],
      },

      //calendario
      actividades: [],

      //estao actividades
      estadoActividades: null,

      /////
      clientesActivos: 0,
      clientesActivosPorcentaje: 0,
      clientesSuspendidos: 0,
      clientesSuspendidosPorcentaje: 0,
      clientesConConexion: 0,
      clientesConConexionPorcentaje: 0,
      clientesSinConexion: 0,
      clientesSinConexionPorcentaje: 0,
      ultimaActualizacion: "",

      liquidados: 0,
      liquidadosTotal: 0,
      pendientes: 0,
      pendientesTotal: 0,
      promesas: 0,
      promesasTotal: 0,
      retrasados: 0,
      retrasadosTotal: 0,

      ticketsPendientes: 0,
      ticketsEnProceso: 0,
      ticketsCompletados: 0,

      value: "",
      //clientes por meses
      seriesMeses: [
        {
          name: "Clientes nuevos",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Cancelaciones",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptionsMeses: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        colors: ["#00B8D4", "#FF6D00"],
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
          position: "top",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getDatosEstaticos();
  },
  computed: {
    events() {
      return this.actividades.map((entry) => {
        let fecha = "";
        let hora_inicio = "";
        let nombre = "";
        let tipo = "";
        let color = "deep-purple";
        if (entry.trabajo) {
          nombre = "Trabajo: " + entry.trabajo;
          tipo = "Trabajo";
          color = "deep-purple";
        }
        if (entry.asunto) {
          nombre = "Ticket: " + entry.asunto;
          tipo = "Ticket";
          color = "orange";
        }
        if (entry.FullName) {
          nombre = "Instalacion: " + entry.FullName;
          tipo = "Instalacion";
          color = "cyan";
        }
        if (entry.fecha) {
          fecha = entry.fecha;
        }
        if (entry.fecha_instalacion) {
          fecha = entry.fecha_instalacion;
        }
        if (entry.hora_inicio) {
          hora_inicio = entry.hora_inicio;
        }
        if (entry.hora) {
          hora_inicio = entry.hora;
        }

        if (entry.hora_instalacion) {
          hora_inicio = entry.hora_instalacion;
        }
        const name = nombre;
        const start = fecha + " " + hora_inicio;
        if (entry.hora_fin) {
          const end = entry.fecha + " " + entry.hora_fin;
          return Object.assign({}, entry, { name, start, end, color, tipo });
        }
        return Object.assign({}, entry, { name, start, color, tipo });
      });
    },
  },
  methods: {
    getDatosEstaticos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `home/empresa/datos/estaticos`,
          data: {},
        })
        .then((result) => {
          if (result.data.sin_router) {
            this.dialog_bienvenida = true;
          }

          this.seriesMeses = [
            {
              name: "Clientes nuevos",
              data: result.data.clientesPorMes,
            },
            {
              name: "Cancelaciones",
              data: result.data.cancelacionesPorMes,
            },
          ];

          var promedio = result.data.pingPromedio;
          this.pingPromedio[0] = promedio;

          if (promedio >= 0) {
            this.chartOptions.colors = ["#4CAF50"];
          }
          if (promedio >= 50) {
            this.chartOptions.colors = ["#0D47A1"];
          }
          if (promedio >= 100) {
            this.chartOptions.colors = ["#F44336"];
          }
          if (promedio == 1000) {
            this.chartOptions.colors = ["#FFFFFF"];
          }

          this.actividadesPendientes = result.data.actividadesPendientes;
          this.instalacionesPendientes = result.data.instalacionesPendientes;
          this.pingsAltos = result.data.pingsAltos;
          this.totalClientes = result.data.totalClientes;

          this.clientesActivos = parseInt(result.data.clientesActivos);
          this.clientesSuspendidos = parseInt(result.data.clientesSuspendidos);
          this.clientesConConexion = parseInt(result.data.clientesConConexion);
          this.clientesSinConexion = parseInt(result.data.clientesSinConexion);
          var pocentaje = (this.clientesActivos * 100) / this.totalClientes;
          this.clientesActivosPorcentaje = parseInt(pocentaje);
          var pocentajeInactivos =
            (this.clientesSuspendidos * 100) / this.totalClientes;
          this.clientesSuspendidosPorcentaje = parseInt(pocentajeInactivos);
          this.clientesConConexion = result.data.clientesConConexion;
          this.clientesSinConexion = result.data.clientesSinConexion;
          this.clientesConConexionPorcentaje =
            result.data.conConexionPorcentaje;
          this.clientesSinConexionPorcentaje =
            result.data.sinConexionPorcentaje;

          if (result.data.ultimaActualizacion) {
            this.ultimaActualizacion =
              result.data.ultimaActualizacion.created_at;
          }

          this.liquidados = Common.formatMoney(result.data.liquidados);
          this.liquidadosTotal = result.data.liquidadosTotal;
          this.pendientes = Common.formatMoney(result.data.pendientes);
          this.pendientesTotal = result.data.pendientesTotal;
          this.promesas = Common.formatMoney(result.data.promesas);
          this.promesasTotal = result.data.promesasTotal;
          this.retrasados = Common.formatMoney(result.data.retrasados);
          this.retrasadosTotal = result.data.retrasadosTotal;

          this.ticketsPendientes = result.data.ticketsPendientes;
          this.ticketsEnProceso = result.data.ticketsEnProceso;
          this.ticketsCompletados = result.data.ticketsCompletados;
          this.actividades = result.data.calendario;

          this.estadoActividades = result.data.actividades;
        });
    },
    getEventColor(event) {
      return event.color;
    },
    showEvent({ nativeEvent, event }) {
      switch (event.tipo) {
        case "Instalacion":
          window.location.href = "vista/detalles/instalacion/" + event.id;
          break;
        case "Trabajo":
          window.location.href = "vista/detalles/actividad/" + event.id;
          break;
        case "Ticket":
          window.location.href = "vista/detalles/ticket/" + event.id;
          break;
        default:
      }
    },
    verActividades() {
      document.location.href = "/actividades/vista";
    },
    verInstalaciones() {
      document.location.href = "/calendario/actividades";
    },
    irAntenas() {
      document.location.href = "/antenas/vista";
    },
    verClientes() {
      document.location.href = "/clientes/vista";
    },
    verPagos() {
      document.location.href = "/pagos/vista";
    },
    verTickets() {
      document.location.href = "/reportes/vista";
    },
    irRouters() {
      document.location.href = "/routers/vista";
    },
  },
};
</script>
<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
