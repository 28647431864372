<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar" id="chart">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [
        {
          name: "Clientes",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Cantidad de clientes por paquete de servicio",
          position: "center",
          offsetX: 0,
          style: {
            fontSize: "12px",
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [],
          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: "Clientes",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
      mostrar: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `graficas/datos/clientes`,
          data: {},
        })
        .then((result) => {
          let seriePaquetes = [];
          let paquetesNombres = [];
          for (let i = 0; i < result.data.planes.length; i++) {
            seriePaquetes.push(result.data.planes[i].clientesCantidad);
            paquetesNombres.push(result.data.planes[i].nombre);
          }
          this.series = [
            {
              name: "Clientes",
              data: seriePaquetes,
            },
          ];
          this.chartOptions.xaxis.categories = paquetesNombres;

          this.mostrar = true;
        });
    },
  },
};
</script>