<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-btn @click="nuevo()" color="red" class="rounded-pill" dark>
              <v-icon left>mdi-plus</v-icon>
              Agregar
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="rounded-pill"
              v-model="search"
              @keyup.enter="getDatos"
              append-icon="mdi-magnify"
              label="Buscar"
              solo
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="elementos"
        :options.sync="options"
        :server-items-length="totalElementos"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-format-horizontal-align-left',
          lastIcon: 'mdi-format-horizontal-align-right',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Registros por página:',
          itemsPerPageOptions: [10, 50, 500, 1000, -1],
        }"
      >
        <template v-slot:no-results>
          <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
            >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
          >
        </template>
        <template v-slot:item.id="{ item }">
          {{
            elementos
              .map(function (x) {
                return x.id;
              })
              .indexOf(item.id) + 1
          }}
        </template>
        <template v-slot:item.antenas="{ item }">
          <v-icon color="grey" left>mdi-volume-high</v-icon>
          <strong>{{ item.clientesCantidad }}</strong>
        </template>
        <template v-slot:item.routers="{ item }">
          <v-icon color="black" left>mdi-router-wireless</v-icon>

          <strong>{{ item.serviciosCantidad }}</strong>
        </template>

        <template v-slot:item.opciones="{ item }">
          <v-tooltip v-if="item.eliminado == false" top>
            <template v-slot:activator="{ on }">
              <v-btn small @click="editar(item)" icon v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip v-if="item.eliminado == false" top>
            <template v-slot:activator="{ on }">
              <v-btn small @click="eliminar(item)" icon v-on="on">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Desactivar</span>
          </v-tooltip>

          <v-tooltip v-if="item.eliminado" top>
            <template v-slot:activator="{ on }">
              <v-btn
                dense
                @click="restaurar(item)"
                :color="item.eliminado == true ? 'red' : 'green'"
                v-on="on"
                fab
                x-small
                dark
              >
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </template>
            <span>Restaurar</span>
          </v-tooltip>
        </template>

        <template v-slot:no-data>
          <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
            <v-row align="center">
              <v-col> Ningún dato que mostrar :( </v-col>
              <v-col class="shrink">
                <v-btn @click="getDatos()"
                  ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <editarTorre ref="editarTorre"></editarTorre>
    <nuevaTorre ref="nuevaTorre"></nuevaTorre>
  </div>
</template>
<script>
import Common from "@/js/Common";
import nuevaTorre from "@/js/components/red/torres/nuevaTorre.vue";
import editarTorre from "@/js/components/red/torres/editarTorre.vue";

export default {
  components: {
    nuevaTorre,
    editarTorre,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Antenas", value: "antenas", sortable: false },
        { text: "Routers", value: "routers", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialog: false,
      torreActualizar: {},
      zona_id: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.zona_id = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `torres/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            zona: this.zona_id,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.torres.data;
          this.totalElementos = result.data.torres.total;
        });
    },

    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `torre/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      this.$refs.nuevaTorre.mostrar().then((resultado) => {
        if (resultado) {
          this.totalElementos = this.elementos.unshift(resultado);
        }
      });
    },
    editar(elemento) {
      this.torreActualizar = elemento;
      const torre = Object.assign({}, elemento);
      this.$refs.editarTorre.esperarDatos(torre).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.torreActualizar),
            1,
            confirm
          );
        }
      });
    },

    detalles(id) {
      document.location.href = "detalles/zona/" + id;
    },
  },
};
</script>