<template>
  <div>
    <v-form
      dense
      ref="form"
      v-model="valid"
      enctype="multipart/form-data"
      lazy-validation
    >
      <v-container fluid grid-list-xl>
        <v-layout row wrap>
          <v-flex xs12 md3>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Dia de instalación"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  filled
                  :rules="generalRegla"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es-MX"
                v-model="cliente.fecha_instalacion"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md3>
            <v-menu
              ref="menu"
              v-model="menu_reloj"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="cliente.hora_instalacion"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="cliente.hora_instalacion"
                  label="Hora de instalación"
                  :rules="generalRegla"
                  prepend-inner-icon="mdi-av-timer"
                  readonly
                  filled
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu_reloj"
                v-model="cliente.hora_instalacion"
                @click:minute="$refs.menu.save(cliente.hora_instalacion)"
              ></v-time-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              label="Costo de instalación"
              v-model="cliente.costo_instalacion"
              type="number"
              :rules="generalRegla"
              prepend-inner-icon="mdi-cash-usd"
              filled
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-autocomplete
              v-model="cliente.zona_id"
              :items="zonas"
              :rules="generalRegla"
              label="Zona"
              filled
              item-value="id"
              item-text="nombre"
              no-data-text="No hay elementos registrados"
            >
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="agregarNuevaZona()">
                  <v-list-item-avatar color="grey lighten-3">
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Nueva zona</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs6 md3>
            <v-select
              :items="[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
              ]"
              label="Dia de creación de factura"
              v-model="cliente.dia_factura"
              :rules="diaFacturaRules"
              prepend-inner-icon="mdi-calendar-multiple-check"
              filled
              hint="cuando se creara la factura del pago mensual?"
            ></v-select>
          </v-flex>
          <v-flex xs6 md3>
            <v-select
              label="Dia de pago"
              :items="diasDePago"
              v-model="cliente.dia_pago"
              type="number"
              :rules="diaPagoRules"
              prepend-inner-icon="mdi-calendar-multiple-check"
              filled
            ></v-select>
          </v-flex>
          <v-flex xs6 md3>
            <v-select
              label="Dias de gracia de pago"
              :items="diasDeGracia"
              v-model="cliente.dias_gracia"
              type="number"
              :rules="diasGraciaRules"
              prepend-inner-icon="mdi-calendar-multiple-check"
              filled
              hint="cuantos dias tiene el cliente para liquidar su pago?"
            ></v-select>
          </v-flex>
          <v-flex xs6 md3>
            <v-text-field
              disabled
              readonly
              label="El dia de suspención"
              v-model="dia_cancelacion"
              prepend-inner-icon="mdi-calendar-multiple-check"
              filled
              hint="Dia de cancelación de servicio"
              persistent-hint
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 md3>
            <v-autocomplete
              v-model="cliente.instalador_id"
              :items="personas"
              :rules="generalRegla"
              item-text="nombre"
              item-value="id"
              filled
              prepend-inner-icon="mdi-hard-hat"
              label="Instalador"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    no se encuentra el
                    <strong>Personal</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="indigo"
                  class="headline font-weight-light white--text"
                >
                  {{ item.nombre.charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.FullName }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs12 md5>
            <v-autocomplete
              v-model="cliente.ayudantes"
              :items="personas"
              item-text="FullName"
              item-value="id"
              filled
              multiple
              chips
              prepend-inner-icon="mdi-account-multiple"
              label="Ayudantes del instalador"
            >
            </v-autocomplete>
          </v-flex>
          <v-flex xs12 md2>
            <v-select
              v-model="cliente.tipo_cliente_id"
              :items="tiposCliente"
              label="Tipo de cliente"
              item-value="id"
              filled
              :rules="generalRegla"
              item-text="tipo"
              no-data-text="No hay elementos registrados"
            >
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="nuevoTipoCliente()">
                  <v-list-item-avatar color="grey lighten-3">
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Agregar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-flex>

          <v-flex xs12 md2>
            <v-select
              v-model="cliente.captacion_id"
              :items="captacionesTodas"
              :rules="generalRegla"
              item-text="captacion"
              item-value="id"
              label="Publicidad"
              filled
              no-data-text="No hay elementos registrados"
            >
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="nuevaCaptacion()">
                  <v-list-item-avatar color="grey lighten-3">
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      >Agregar tipo de captacion</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-textarea
              v-model="cliente.detalles"
              label="Detalles de la instalación"
              required
              rows="3"
              filled
              shaped
              hint="Ejemplo: preguntar por Fulanito de tal, vive cerca de la miscelanea tal, llevar tubo de 6m, instalar a las entre las 10am y 11am"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="regresar()">Regresar</v-btn>
        <v-btn
          :disabled="!valid"
          color="primary"
          class="rounded-pill pa-3"
          @click="validate"
          >Continuar</v-btn
        >
      </v-card-actions>
    </v-form>
    <formTipoCliente ref="formNuevoTipoCliente"></formTipoCliente>
    <formCaptacion ref="refCaptacionForm"></formCaptacion>
    <formNuevaZona ref="refZona"></formNuevaZona>
  </div>
</template>
<script>
import formTipoCliente from "@/js/components/clientes/recursos/tablas/tipos_cliente/nuevo.vue";
import formCaptacion from "@/js/components/clientes/recursos/tablas/captaciones/nuevo.vue";
import formNuevaZona from "@/js/components/red/zonas/nuevaZona.vue";
export default {
  components: {
    formTipoCliente,
    formNuevaZona,
    formCaptacion,
  },
  data() {
    return {
      cliente: {
        fecha_instalacion: null,
        costo_instalacion: "",
        dia_pago: 1,
        dia_factura: 1,
        dias_gracia: 3,
        tipo_cliente_id: "",
        zona_id: "",
        instalador_id: "",
        captacion_id: "",
        status_cliente_id: 4,
        detalles: "",
        hora_instalacion: null,
        ayudantes: [],
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      diaFacturaRules: [
        (v) =>
          !!v ||
          "Se necesita el dia de cracion de factura del pago rango 1-28.",
      ],
      diaPagoRules: [(v) => !!v || "Se necesita el dia de pago rango 1-28."],
      diasGraciaRules: [
        (v) =>
          !!v ||
          "Se necesita para saber cuantos dias tiene el cliente para liquidar su pago",
        (v) => v >= 0 || "Se necesita el campo",
      ],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      zonas: [],
      personas: [],
      isLoading: false,
      search: null,
      captacionesTodas: [],
      tiposCliente: [],
      menu_reloj: false,
      status_clientes: [],
    };
  },
  mounted() {
    this.getDatos();
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.cliente.fecha_instalacion) {
        return this.formatDate(this.cliente.fecha_instalacion);
      } else {
        return "";
      }
    },

    diasDePago() {
      var dias = [];
      var tamanio = this.cliente.dia_factura;
      for (var i = tamanio; i < 29; i++) {
        dias.push(i);
      }
      if (!dias.includes(this.cliente.dia_pago)) {
        this.cliente.dia_pago = "";
      }
      return dias;
    },
    diasDeGracia() {
      var dias = [];
      var tope = 29 - this.cliente.dia_pago;
      for (var i = 0; i < tope; i++) {
        dias.push(i);
      }
      if (!dias.includes(this.cliente.dias_gracia)) {
        this.cliente.dias_gracia = "";
      }
      return dias;
    },
    dia_cancelacion() {
      return this.cliente.dia_pago + this.cliente.dias_gracia;
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("PasoRegistroInstalacion", {
          paso: 3,
          cliente: this.cliente,
        });
      }
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/empresa/registro/cliente",
          data: {},
        })
        .then((result) => {
          this.zonas = result.data.zonas;
          this.personas = result.data.personas;
          this.tiposCliente = result.data.tiposCliente;
          this.status_clientes = result.data.status_clientes;
          this.captacionesTodas = result.data.captaciones;
        });
    },
    regresar() {
      this.$store.dispatch("regresarRegistroCliente", 1);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    nuevoTipoCliente() {
      this.$refs.formNuevoTipoCliente.mostrar().then((resultado) => {
        if (resultado) {
          this.tiposCliente.push(resultado);
          this.cliente.tipo_cliente_id = resultado.id;
        }
      });
    },
    agregarNuevaZona() {
      this.$refs.refZona.mostrar().then((resultado) => {
        if (resultado) {
          this.zonas.push(resultado);
          this.cliente.zona_id = resultado.id;
        }
      });
    },
    nuevaCaptacion() {
      this.$refs.refCaptacionForm.mostrar().then((resultado) => {
        if (resultado) {
          this.captacionesTodas.push(resultado);
          this.cliente.captacion_id = resultado.id;
        }
      });
    },
  },
};
</script>
