<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-row justify="center">
      <v-dialog v-model="dialogTipo" max-width="300" persistent>
        <v-card>
          <v-container grid-list-md>
            <v-card-title primary-title class="display-1">
              <strong>Nuevo</strong>
            </v-card-title>
            <v-card-text>
              <v-form dense ref="formTipo" v-model="validTipo" lazy-validation>
                <v-text-field
                  label="Tipo"
                  v-model="tipo.tipo"
                  prepend-icon="mdi-cube-send"
                  :rules="generalRegla"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogTipo = false">Cancelar</v-btn>
              <v-btn
                class="rounded-pill pa-3"
                color="primary"
                @click="nuevoTipo()"
              >
                Registrar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="dialogEditarNegocio" max-width="800" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="display-1">
            <strong>Editar</strong>
          </v-card-title>
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="500"
          >
            <v-card-text>
              <v-form
                dense
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                lazy-validation
              >
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="negocio.nombre"
                      label="Nombre"
                      :rules="generalRegla"
                      prepend-icon="mdi-store"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-select
                      v-model="negocio.tipo_negocio_id"
                      :items="tipos"
                      item-text="tipo"
                      item-value="id"
                      label="Tipo del negocio"
                      :rules="generalRegla"
                      prepend-icon="mdi-format-list-bulleted-type"
                      no-data-text="No hay elementos registrados"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="dialogTipo = true">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-model="negocio.persona_id"
                      :items="itemsPersonal"
                      item-text="todo"
                      item-value="id"
                      :rules="generalRegla"
                      max-width="30"
                      ref="autoPersonal"
                      :search-input.sync="searchPersonal"
                      hide-no-data
                      label="Encargado o propietario"
                      placeholder="Empieza a escribir para búscar"
                      prepend-icon="mdi-worker"
                    >
                      <template max-width="60" v-slot:item="{ item }">
                        <v-list-item-avatar
                          color="indigo"
                          class="headline font-weight-light white--text"
                        >
                          {{ item.nombre.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.nombre }} {{ item.apellido_paterno }}
                            {{ item.apellido_materno }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Celular: {{ item.celular }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Celular del negocio"
                      v-model="negocio.celular"
                      type="number"
                      counter="10"
                      prepend-icon="mdi-cellphone-android"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Telefono del negocio"
                      v-model="negocio.telefono"
                      type="number"
                      counter="10"
                      prepend-icon="mdi-phone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Correo del negocio"
                      v-model="negocio.correo"
                      prepend-icon="mdi-gmail"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Usuario"
                      v-model="negocio.usuario_recargas"
                      prepend-icon="mdi-account-key"
                      hint="Usuario de servicios como recargas, fichas, etc"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Contraseña"
                      v-model="negocio.contraseña_recargas"
                      :type="show ? 'text' : 'password'"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-icon="mdi-textbox-password"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Costo total negocio"
                      hint="Cuanto fue la inversion de este negocio"
                      prefix="$"
                      v-model="negocio.costo"
                      :rules="cantidadRegla"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <p class="text-subtitle-2">Dirección</p>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="cp"
                      label="Código postal"
                      :rules="generalRegla"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="negocio.estado"
                      label="Estado"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="negocio.municipio"
                      label="Municipio"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex v-if="colonias.length != 0" xs12 md6>
                    <v-select
                      v-model="negocio.colonia"
                      :items="colonias"
                      label="Colonias"
                    ></v-select>
                  </v-flex>
                  <v-flex v-if="colonias.length == 0" xs12 md6>
                    <v-text-field
                      v-model="negocio.colonia"
                      label="Colonia"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="negocio.direccion"
                      label="Dirección"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-textarea
                      label="Detalles"
                      v-model="negocio.detalles"
                      variant="filled"
                      auto-grow
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <mapa ref="mapa"></mapa>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              class="rounded-pill pa-3"
              @click="actualizar()"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import mapa from "@/js/components/mapa/mapaComponent.vue";

export default {
  components: {
    mapa,
  },
  data() {
    return {
      negocio: {
        nombre: "",
        codigo_postal: "",
        direccion: "",
        estado: "",
        municipio: "",
        colonia: "",
        longitud: "",
        latitud: "",
        telefono: "",
        correo: "",
        celular: "",
        persona_id: "",
        usuario_recargas: "",
        contraseña_recargas: "",
        costo: "0",
        tipo_negocio_id: "",
        detalles: "",
      },
      tipo: {
        tipo: "",
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      tipos: [],
      validTipo: true,
      dialogTipo: false,
      searchPersonal: "",
      show: false,
      emailRules: [
        (v) =>
          /.+@.+/.test(v) ||
          v == null ||
          v.length == 0 ||
          "Introduce un correo valido",
      ],
      cantidadRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => v >= 0 || "necesita ser mayor a 0",
      ],
      dialog_mapa: false,
      colonias: [],
      cp: "",
      personal: [],
      resolve: null,
      reject: null,
      dialogEditarNegocio: false,
    };
  },
  mounted() {
    this.getDatosInicio();
  },
  watch: {
    cp(val) {
      if (val) {
        //this.getDatosApi(val);
        this.negocio.codigo_postal = val;
      }
    },
  },
  computed: {
    itemsPersonal() {
      return this.personal.map((entry) => {
        const todo =
          entry.nombre +
          " " +
          entry.apellido_paterno +
          " " +
          entry.apellido_materno +
          " ";
        return Object.assign({}, entry, { todo });
      });
    },
  },
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.negocio.latitud,
        this.negocio.longitud,
        "markerNegocio.png",
        "Negocio",
        true
      );
    },
    esperarDatos(item) {
      this.negocio = item;
      this.cp = item.codigo_postal;
      this.dialogEditarNegocio = true;
      this.esperarComponente();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    limpiar() {
      this.$refs.form.reset();
    },
    getDatosInicio() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/negocio",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
          this.personal = result.data.personas;
        });
    },
    nuevoTipo() {
      if (this.$refs.formTipo.validate) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_negocio";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.tipos.push(result.data.tipo);
            this.negocio.tipo_negocio_id = result.data.tipo.id;
            this.dialogTipo = false;
            this.$refs.formTipo.reset();
          });
      }
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        this.$store.dispatch("mostrarCarga", true);
        axios
          .get(
            "http://api-sepomex.hckdrk.mx/query/info_cp/" +
              cp +
              "?type=simplified"
          )
          .then((response) => {
            let datos = response.data.response;
            this.negocio.estado = datos.estado;
            this.negocio.municipio = datos.municipio;
            this.colonias = datos.asentamiento;

            this.$store.dispatch("mostrarCarga", false);
          })
          .catch((error) => {
            this.$store.dispatch("mostrarCarga", false);
          });
      }
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        this.negocio.latitud = this.$refs.mapa.getLatitud();
        this.negocio.longitud = this.$refs.mapa.getLongitud();
        this.resolve(this.negocio);
        this.dialogEditarNegocio = false;
        return this.Negocio;
      } else {
        return null;
      }
    },
    guardarCoordenadas() {
      this.dialog_mapa = false;
      this.negocio.latitud = this.$store.getters.getCoordenadas.lat;
      this.negocio.longitud = this.$store.getters.getCoordenadas.lng;
    },
    cancelar() {
      this.resolve(false);
      this.dialogEditarNegocio = false;
    },
  },
};
</script>