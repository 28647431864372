<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap v-if="ticket">
          <v-flex xs12>
            <v-row>
              <v-col cols="12">
                <v-card class="cool" outlined>
                  <v-card-title primary-title>
                    Detalles del Ticket
                  </v-card-title>
                  <v-card-text>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon
                          v-if="ticket.estado_reporte_id == 1"
                          class="orange"
                          dark
                        >
                          mdi-calendar
                        </v-icon>
                        <v-icon
                          v-if="ticket.estado_reporte_id == 2"
                          class="blue"
                          dark
                        >
                          mdi-calendar-clock
                        </v-icon>
                        <v-icon
                          v-if="ticket.estado_reporte_id == 3"
                          class="green"
                          dark
                        >
                          mdi-calendar-check
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div>
                          {{ ticket.asunto }}
                        </div>
                        <v-list-item-subtitle
                          >Dia: {{ ticket.fecha }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          >Hora: {{ ticket.hora }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top v-if="ticket.estado_reporte_id == 1">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="blue"
                              fab
                              dark
                              @click="iniciarTicket()"
                              v-on="on"
                            >
                              <v-icon>mdi-play</v-icon>
                            </v-btn>
                          </template>
                          <span>Iniciar Ticket</span>
                        </v-tooltip>
                        <v-tooltip top v-if="ticket.estado_reporte_id == 2">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="green"
                              fab
                              dark
                              @click="finalizarTicket()"
                              v-on="on"
                            >
                              <v-icon>mdi-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Finalizar Ticket</span>
                        </v-tooltip>

                        <v-chip
                          v-if="ticket.estado_reporte_id == 3"
                          class="ma-2"
                          color="green"
                          outlined
                        >
                          <v-icon start
                            >mdi-checkbox-marked-circle-outline</v-icon
                          >
                          Finalizado
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card v-if="ticket.cliente" outlined class="cool pa-2">
                  <v-card-title class="px-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          ticket.cliente.FullName
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Cliente</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn color="grey" icon @click="detalles()">
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-subtitle>
                    <v-chip dark color="orange" outlined>
                      <v-icon start>mdi-account-circle</v-icon>
                      {{ ticket.cliente.tipo.tipo }}</v-chip
                    >
                    <v-chip
                      @click="abrirIp(ticket.cliente.antena.ip)"
                      class="ma-2"
                      color="green"
                      outlined
                    >
                      <v-icon start>mdi-ip</v-icon>
                      {{ ticket.cliente.antena.ip }}
                    </v-chip>
                    <v-chip class="ma-2" color="purple" outlined>
                      <v-icon start>mdi-access-point-network</v-icon>
                      {{ ticket.cliente.zona.nombre }}
                    </v-chip>
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-list two-line>
                      <v-list-item
                        @click="
                          abrirEnMaps(
                            ticket.cliente.latitud,
                            ticket.cliente.longitud
                          )
                        "
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-map-marker </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ ticket.cliente.direccion }},
                            {{ ticket.cliente.colonia }},
                            {{ ticket.cliente.municipio }}
                            {{ ticket.cliente.codigo_postal }},
                            {{ ticket.cliente.estado }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="llamar(ticket.cliente.telefono)"
                        v-show="ticket.cliente.telefono"
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-phone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            ticket.cliente.telefono
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="llamar(ticket.cliente.celular)"
                        v-show="ticket.cliente.celular"
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-cellphone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            ticket.cliente.celular
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Celular</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-show="ticket.cliente.correo">
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-email </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            ticket.cliente.correo
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Correo</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="cool pa-2" outlined>
                  <mapa v-if="mostrarMapa" ref="mapa"></mapa>

                  <v-card-text>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-account-cash </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            formatoDinero(ticket.costo)
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Costo ticket</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-calendar-check </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            ticket.created_at
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Día de registr</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item v-if="ticket.personal" class="grow">
                        <v-list-item-avatar color="grey darken-3">
                          <img
                            v-if="ticket.personal.imagen"
                            :src="ticket.personal.imagen"
                          />
                          <img v-else src="/img/user_default.png" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ ticket.personal.nombre }}
                            {{ ticket.personal.apellido_paterno }}
                            {{ ticket.personal.apellido_materno }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Instalador</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <strong v-if="ticket.ayudantes.length > 0" class="ma-2"
                        >Ayudantes del instalador</strong
                      >
                      <v-list
                        v-for="ayudante in ticket.ayudantes"
                        :key="ayudante.id"
                        two-line
                      >
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="teal"> mdi-account </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              ayudante.nombre
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Empleado</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Reporte from "@/js/components/reportes/Reporte.js";

import mapa from "@/js/components/clientes/detalles/cliente/mapa.vue";
import Common from "@/js/Common";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    mapa,
  },
  data() {
    return {
      mostrarMapa: true,
      ticket: new Reporte(),
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  watch: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();

      this.$refs.mapa.mostrarMarker(
        this.ticket.cliente.latitud,
        this.ticket.cliente.longitud,
        this.ticket.cliente.nombre
      );
    },
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `reporte/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.ticket = result.data.reporte;
            if (
              this.ticket.cliente.latitud &&
              this.ticket.cliente.longitud &&
              this.ticket.cliente.nombre
            ) {
              this.$store.dispatch(
                "showCliente",
                Object.assign({}, result.data.cliente)
              );

              this.esperarComponente();
            }
          }
        });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad, 2);
    },
    iniciarTicket() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `iniciar/ticket/` + this.ticket.id,
          data: {},
        })
        .then((result) => {
          this.ticket.estado_reporte_id = result.data.ticket.estado_reporte_id;
        });
    },
    finalizarTicket() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `finalizar/ticket/` + this.ticket.id,
          data: {},
        })
        .then((result) => {
          this.ticket.estado_reporte_id = result.data.ticket.estado_reporte_id;
        });
    },
    llamar(numero) {
      document.location.href = "tel:" + numero;
    },
    abrirEnMaps(latitud, longitud) {
      if (latitud && longitud) {
        const url = `https://www.google.com/maps?q=${latitud},${longitud}`;
        window.open(url);
      }
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    detalles() {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + this.ticket.cliente.id;
    },
  },
};
</script>
