<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";

export default {
  components: {
    apexchart,
  },
  data() {
    return {
      mostrar: false,
      series: [
        {
          name: "Promesa",
          data: [],
        },
        {
          name: "Liquidado",
          data: [],
        },
        {
          name: "Pendiente",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        title: {
          text: "Pagos por zona",
          offsetX: 0,
          style: {
            fontSize: "15px",
          },
        },
        responsive: [
          {
            breakpoint: 100,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "$ Cantidad",
          },
          labels: {
            formatter: function (y) {
              return Common.formatMoney(y);
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
          show: false,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/estadisticas/por/zona`,
          data: {},
        })
        .then((result) => {
          this.chartOptions.xaxis.categories = result.data.zonas;

          this.series = [
            {
              name: "Promesa",
              data: result.data.promesas,
            },
            {
              name: "Liquidado",
              data: result.data.liquidados,
            },
            {
              name: "Pendiente",
              data: result.data.pendientes,
            },
          ];
          this.mostrar = true;
        });
    },
  },
};
</script>
