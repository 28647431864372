<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card>
        <v-card-title primary-title> Editar servicio </v-card-title>
        <v-container grid-list-md>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-flex xs12 md12>
              <v-autocomplete
                v-model="servicio_nuevo_id"
                :items="servicios"
                label="Servicios"
                item-value="id"
                :rules="generalRegla"
                item-text="nombre"
                no-data-text="No hay elementos registrados"
                prepend-icon="mdi-server-plus"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-avatar
                    color="indigo"
                    class="headline font-weight-light white--text"
                  >
                    {{ item.nombre.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.nombre }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >${{ item.costo }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Tipo:{{ item.tipo.tipo }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle v-if="item.tipo.tipo == 'Internet'">{{
                      item.bajada + "MB/" + item.subida + "MB"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item @click="nuevo()">
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon>mdi-server-plus</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Registrar nuevo</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelar()">Cancelar</v-btn>
          <v-btn
            class="rounded-pill"
            color="primary"
            @click="cambiar_servicio()"
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <nuevoServicio ref="refServicio"></nuevoServicio>
  </div>
</template>
<script>
import nuevoServicio from "@/js/components/servicios/dialogServicioComponent.vue";

export default {
  components: {
    nuevoServicio,
  },
  data() {
    return {
      dialog: false,
      servicio_nuevo_id: 0,
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      servicios: [],
      resolve: null,
      reject: null,
      id: "",
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "todos/servicios/empresa",
          data: {},
        })
        .then((result) => {
          this.servicios = result.data.servicios;
        });
    },

    esperarDatos() {
      this.getDatos();
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cambiar_servicio() {
      let dispatch = "UsersModule/OnSave";
      let endpoint =
        "remplar/servicio/cliente/" + this.servicio_nuevo_id + "/" + this.id;
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
        })
        .then((result) => {
          this.limpiar();
          this.dialog = false;
          this.resolve(result.data.servicio);
        });
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
    nuevo() {
      this.$refs.refServicio.mostrar().then((confirm) => {
        if (confirm) {
          this.servicios.push(confirm);
          this.servicio_nuevo_id = confirm.id;
        }
      });
    },
  },
};
</script>