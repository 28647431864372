<template>
  <div v-if="servicio">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card class="cool">
            <v-card-title>
              <span>{{ servicio.nombre }}</span>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editar()" icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar Servicio</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="eliminar()" icon v-on="on">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Desactivar Servicio</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-row v-if="servicio.tipo" class="ml-2"
                ><v-chip color="primary">
                  {{ servicio.tipo.tipo }}
                </v-chip></v-row
              >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="pb-1"> Costo </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ servicio.costo }} MXN
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-currency-mxn</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="pb-1">
                      Velocidad de subida (Max limit)
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ servicio.subida }} Mbps
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-upload</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Velocidad de bajada (Max limit)
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ servicio.bajada }} Mbps
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-download</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="">
                      Cantidad de clientes usando el servicio
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ servicio.clientesCantidad }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-account-multiple-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Prioridad del servicio
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ servicio.prioridad }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-star</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-row>
                <v-col cols="12" md="6">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-speedometer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_limit_bajada
                          }}
                          M</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Burst limit Bajada</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-ray-vertex</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_threshold_bajada
                          }}
                          M</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Burst threshold bajada</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-sign-caution</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ servicio.limit_at_bajada }} M</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Limit at bajada</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-timer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_time_bajada
                          }}
                          s.</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Burst time bajada</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="12" md="6">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-speedometer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_limit_subida
                          }}
                          M</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Burst limit subida</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="grey">mdi-ray-vertex</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_threshold_subida
                          }}
                          M</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Burst threshold subida</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-sign-caution</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ servicio.limit_at_subida }} M</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Limit at bajada</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-timer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_time_subida
                          }}
                          s.</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Burst time subida</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12">
                  <v-card flat>
                    <v-divider></v-divider>
                    <v-card-title primary-title> Detalles </v-card-title>
                    <v-card-text>
                      <p>
                        {{ servicio.detalles }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="cool pa-3">
            <v-card-title primary-title> Actualizar servicios </v-card-title>
            <v-card-subtitle>
              Sincroniza las velocidades de los clientes asociados a este
              paquete con las velocidades asignadas en el Mikrotik
            </v-card-subtitle>
            <v-card-text>
              <v-btn @click="actualizarServicios()" color="success"
                >Actualizar</v-btn
              >
            </v-card-text>
          </v-card>
          <v-card color="blue lighten-1" class="cool pa-3 mt-2">
            <v-card-title>
              Clientes
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search_clientes"
                clearable
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers_clientes"
              :items="servicio.clientes"
              :search="search_clientes"
              :items-per-page="8"
            >
              <template v-slot:item.opciones="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      @click="detallesCliente(item.id)"
                      icon
                      v-on="on"
                    >
                      <v-icon small> mdi-dots-horizontal </v-icon>
                    </v-btn>
                  </template>
                  <span>Ver detalles</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
          <v-card color="deep-purple accent-1" class="cool pa-3 mt-2">
            <v-card-title>
              Zonas <v-spacer></v-spacer>
              <v-text-field
                v-model="search_zonas"
                clearable
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers_zonas"
              :items="servicio.zonas"
              :search="search_zonas"
              :items-per-page="3"
            >
              <template v-slot:item.opciones="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="detallesZona(item.id)" icon v-on="on">
                      <v-icon small> mdi-dots-horizontal </v-icon>
                    </v-btn>
                  </template>
                  <span>Ver detalles</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <formEditarServicio ref="refEditarServicio"></formEditarServicio>
  </div>
</template>
<script>
import Common from "@/js/Common";
import LocalStorage from "@/js/LocalStorage.js";
import formEditarServicio from "@/js/components/servicios/dialogEditarServicioComponent.vue";
import Servicio from "@/js/clases/Servicio.js";
export default {
  components: {
    formEditarServicio,
  },
  data() {
    return {
      servicio: new Servicio(),
      search_clientes: "",
      headers_clientes: [
        { text: "Nombre", value: "FullName" },
        { text: "Municipio", value: "municipio" },
        { text: "Direccion", value: "direccion" },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      search_zonas: "",
      headers_zonas: [
        { text: "Nombre", value: "nombre" },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  watch: {},
  methods: {
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "servicio/" + id,
          data: {},
        })
        .then((result) => {
          this.servicio = result.data.servicio;
        });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad, 2);
    },
    detallesCliente(id) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 3);
      document.location.href = "detalles/cliente/" + id;
    },
    detallesZona(id) {
      document.location.href = "detalles/zona/" + id;
    },
    eliminar() {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `servicio/` + this.servicio.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: this.servicio,
              })
              .then((result) => {
                window.location.href = "servicios/vista";
              });
          }
        });
    },
    editar() {
      let servicio = Object.assign({}, this.servicio);
      this.$refs.refEditarServicio.esperarDatos(servicio).then((confirm) => {
        if (confirm) {
          this.servicio = confirm;
        }
      });
    },
    actualizarServicios() {
      this.$refs.confirmar
        .open(
          "Confirmación",
          "El sistema se conectara con los Mikrotiks y actualizara las velocidades de todos los clientes asociados a este servicio, esta seguro de hacer esta acción?",
          {
            color: "blue",
          }
        )
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnSave";
            let endpoint =
              "actualizar/servicios/clientes/mikrotik/" + this.servicio.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
              })
              .then((result) => {});
          }
        });
    },
  },
};
</script>