<template>
  <div>
    <v-card>
      <v-container grid-list-md>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation dense>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="router.nombre"
                  :rules="generalRegla"
                  label="Nombre o alias"
                  filled
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md4>
                <v-autocomplete
                  v-model="router.producto_id"
                  :items="productos"
                  label="Tipo de router"
                  item-value="id"
                  :rules="generalRegla"
                  item-text="producto"
                  no-data-text="No hay elementos registrados"
                  append-icon="mdi-router-wireless"
                  filled
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar color="indigo">
                      <img v-if="item.imagen" :src="item.imagen" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.producto }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Marca: {{ item.marca }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Cantidad en stock:
                        {{ item.cantidad }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="nuevoProducto()">
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 md4>
                <v-autocomplete
                  v-model="router.torre_id"
                  :items="torres"
                  item-text="nombre"
                  item-value="id"
                  label="torres"
                  append-icon="mdi-city"
                  :rules="generalRegla"
                  no-data-text="No hay elementos registrados"
                  filled
                >
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="nuevaTorre()">
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-card outlined elevation="0" class="pa-3 mb-8">
              <v-layout row wrap>
                <v-radio-group row horizontal v-model="router.tipo_conexion">
                  <template v-slot:label>
                    <div><strong>Tipo de conexión.</strong></div>
                  </template>
                  <v-radio color="primary" value="1">
                    <template v-slot:label>
                      <div>
                        <strong class="primary--text">IP publica o DDNS</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio color="success" value="2">
                    <template v-slot:label>
                      <div>
                        <strong class="success--text">Script </strong>(Tunel
                        VPN)
                      </div>
                    </template>
                  </v-radio>
                  <v-radio color="secondary" value="3">
                    <template v-slot:label>
                      <div>
                        <strong class="secondary--text">Ninguno </strong>(Solo
                        registro)
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-layout>

              <v-layout v-if="router.tipo_conexion == 1" row wrap>
                <v-flex xs12 md3>
                  <v-text-field
                    label="IP Publica"
                    v-model="router.ip"
                    :rules="ipRegla"
                    append-icon="mdi-ip"
                    hint="Puede ser una IP publica o url de algún servicio DDNS como no-ip, ip claud, Duckdns, etc."
                    filled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Usuario"
                    v-model="router.usuario"
                    append-icon="mdi-account-circle"
                    :rules="generalRegla"
                    filled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Contraseña"
                    v-model="router.contrasena"
                    :rules="generalRegla"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    filled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Puerto de la api"
                    v-model="router.api"
                    :rules="apiRegla"
                    type="number"
                    append-icon="mdi-serial-port"
                    filled
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="router.tipo_conexion == 3" row wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    label="IP privada"
                    v-model="router.ip"
                    :rules="ipPrivadaRegla"
                    append-icon="mdi-ip"
                    hint="Ip privada del rango 192.168.x.x o 172.16.x.x o 10.x.x.x"
                    filled
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>

            <v-layout v-if="router.tipo_conexion != 3" row wrap>
              <v-flex xs12 md6>
                <v-layout row wrap>
                  <v-flex xs10>
                    <strong> Reglas de suspención </strong>
                    <br />
                    Escribir distintas reglas en el mikrotik para que este pueda
                    suspender el servicio de internet al cliente y redireccionar
                    a una pagina de suspención.
                  </v-flex>
                  <v-flex xs2>
                    <v-switch
                      v-model="router.reglas"
                      hide-details
                      inset
                    ></v-switch>
                  </v-flex>
                </v-layout>
                <v-layout v-if="router.reglas" row wrap>
                  <v-flex xs10>
                    <strong> Cancelar todos los clientes </strong>
                    <br />
                    Este Mikrotik puede cancelar el servicio de internet de
                    todos clientes anque no esten administrados por este
                  </v-flex>
                  <v-flex xs2>
                    <v-switch
                      v-model="router.cancelar_todos_clientes"
                      hide-details
                      inset
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md6>
                <v-layout row wrap>
                  <v-flex xs10>
                    <strong> Monitoreo de estado </strong>
                    <br />
                    Cada cierto tiempo el sistema consultara el estado de su
                    Mikrotik y recolectara datos como temperatura, energia
                    electria, memoria, etc.
                  </v-flex>
                  <v-flex xs2>
                    <v-switch
                      v-model="router.monitoreo"
                      hide-details
                      inset
                    ></v-switch>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs10>
                    <strong> Historial de clientes </strong>
                    <br />
                    Activar larecolección de trafico para poder visualizar el
                    historial de los clientes.
                  </v-flex>
                  <v-flex xs2>
                    <v-switch
                      v-model="router.historial"
                      hide-details
                      inset
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="procesar()"
            class="rounded-pill pa-3"
            color="primary"
            fab
            ><v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
    <formProducto ref="refProducto"></formProducto>
    <nuevaTorre ref="nuevaTorre"></nuevaTorre>
  </div>
</template>
<script>
import formProducto from "@/js/components/almacen/productos/nuevoProducto.vue";
import nuevaTorre from "@/js/components/red/torres/nuevaTorre.vue";

export default {
  components: {
    formProducto,
    nuevaTorre,
  },
  data() {
    return {
      router: {
        ip: "",
        nombre: "",
        contrasena: "",
        usuario: "",
        contrasena_snmp: "",
        tiempo_escaneo: "",
        comunidad_snmp_id: "",
        api: "",
        torre_id: "",
        version_snmp_id: "",
        producto_id: "",
        tipo_conexion: "1",
        historial: false,
        monitoreo: false,
        reglas: true,
        cancelar_todos_clientes: false,
      },
      show: false,
      valid: true,
      torres: [],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      interfazRegla: [(v) => v.length > 0 || "Se necesita el campo"],
      ipRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => this.quitaPuntos(v) || "Sin el puerto",
      ],
      apiRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => parseInt(v) > 1023 || "Debe ser mayor a 1204",
        (v) => parseInt(v) < 49151 || "Debe ser menor a 49151",
        (v) =>
          parseInt(v) != 10022 || "cambia el puerto a uno diferente de 10022",
        (v) =>
          parseInt(v) != 10080 || "cambia el puerto a uno diferente de 10080",
        (v) =>
          parseInt(v) != 18291 || "cambia el puerto a uno diferente de 180291",
      ],
      ipPrivadaRegla: [
        (v) =>
          /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            v
          ) || "Ip no valida. Ejemplo: 192.168.1.10",
      ],

      productos: [],
    };
  },
  mounted() {
    this.getDatos();
  },
  watch: {},
  computed: {
    routerCache() {
      return this.$store.getters.getRouterRegistrado;
    },
  },
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/router",
          data: {},
        })
        .then((result) => {
          this.productos = result.data.productos;
          this.torres = result.data.torres;
        });
    },
    procesar() {
      if (this.routerCache) {
        this.router.id = this.routerCache.id;

        this.actualizar();
      } else {
        this.nuevo();
      }
    },
    nuevo() {
      if (this.$refs.form.validate() && this.router.tipo_conexion) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "router";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.router,
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              if (result.data.router.tipo_conexion == 1) {
                this.$store.dispatch("PasoRegistroRouter", {
                  paso: 3,
                  router: result.data.router,
                });
              }
              if (result.data.router.tipo_conexion == 2) {
                this.$store.dispatch("PasoRegistroRouter", {
                  paso: 2,
                  router: result.data.router,
                });
              }
              if (result.data.router.tipo_conexion == 3) {
                document.location.href = "/routers/vista";
              }
            }
          });
      }
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `router/` + this.router.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.router,
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              if (result.data.router.tipo_conexion == 1) {
                this.$store.dispatch("PasoRegistroRouter", {
                  paso: 3,
                  router: result.data.router,
                });
              }
              if (result.data.router.tipo_conexion == 2) {
                this.$store.dispatch("PasoRegistroRouter", {
                  paso: 2,
                  router: result.data.router,
                });
              }
              if (result.data.router.tipo_conexion == 3) {
                document.location.href = "/routers/vista";
              }
            }
          });
      }
    },

    nuevaTorre() {
      this.$refs.nuevaTorre.mostrar().then((resultado) => {
        if (resultado) {
          this.torres.push(resultado);
          this.router.torre_id = resultado.id;
        }
      });
    },
    nuevoProducto() {
      this.$refs.refProducto.cargarDatos().then((confirm) => {
        if (confirm) {
          this.productos.push(confirm);
          this.router.producto_id = confirm.id;
        }
      });
    },
    quitaPuntos(ip) {
      if (ip == null) {
        return true;
      }
      for (let index = 0; index < ip.length; index++) {
        if (ip[index] == ":") {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
