<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-row v-if="cliente">
              <v-col cols="12">
                <v-card class="cool" outlined>
                  <v-card-title primary-title>
                    Detalles del instalación
                  </v-card-title>
                  <v-card-text>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon
                          v-if="cliente.status_cliente_id == 4"
                          class="orange"
                          dark
                        >
                          mdi-calendar
                        </v-icon>
                        <v-icon
                          v-if="cliente.status_cliente_id == 1"
                          class="blue"
                          dark
                        >
                          mdi-calendar-clock
                        </v-icon>
                        <v-icon
                          v-if="cliente.status_cliente_id == 2"
                          class="green"
                          dark
                        >
                          mdi-calendar-check
                        </v-icon>
                        <v-icon
                          v-if="cliente.status_cliente_id == 3"
                          class="orange"
                          dark
                        >
                          mdi-network-off
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Instalación</v-list-item-title>
                        <v-list-item-subtitle
                          >Dia:
                          {{ cliente.fecha_instalacion }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          >Hora:
                          {{ cliente.hora_instalacion }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top v-if="cliente.status_cliente_id == 4">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="blue"
                              fab
                              dark
                              @click="iniciarInstalacion()"
                              v-on="on"
                            >
                              <v-icon>mdi-play</v-icon>
                            </v-btn>
                          </template>
                          <span>Iniciar Instalación</span>
                        </v-tooltip>
                        <v-tooltip top v-if="cliente.status_cliente_id == 1">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="green"
                              fab
                              dark
                              @click="finalizarInstalacion()"
                              v-on="on"
                            >
                              <v-icon>mdi-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Finalizar Instalación</span>
                        </v-tooltip>

                        <v-chip
                          v-if="cliente.status_cliente_id == 2"
                          class="ma-2"
                          color="green"
                          outlined
                        >
                          <v-icon start>mdi-wifi</v-icon>
                          Activo
                        </v-chip>
                        <v-chip
                          v-if="cliente.status_cliente_id == 3"
                          class="ma-2"
                          color="orange"
                          outlined
                        >
                          <v-icon start>mdi-wifi-off</v-icon>
                          Suspendido
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card outlined class="cool pa-2">
                  <v-card-title class="px-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          cliente.FullName
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Cliente</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-subtitle>
                    <v-chip dark color="orange" outlined>
                      <v-icon start>mdi-account-circle</v-icon>
                      {{ cliente.tipo.tipo }}</v-chip
                    >
                    <v-chip
                      @click="abrirIp(cliente.antena.ip)"
                      class="ma-2"
                      color="green"
                      outlined
                    >
                      <v-icon start>mdi-ip</v-icon>
                      {{ cliente.antena.ip }}
                    </v-chip>
                    <v-chip class="ma-2" color="purple" outlined>
                      <v-icon start>mdi-access-point-network</v-icon>
                      {{ cliente.zona.nombre }}
                    </v-chip>
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-list two-line>
                      <v-list-item
                        @click="abrirEnMaps(cliente.latitud, cliente.longitud)"
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-map-marker </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ cliente.direccion }}, {{ cliente.colonia }},
                            {{ cliente.municipio }}
                            {{ cliente.codigo_postal }},
                            {{ cliente.estado }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="llamar(cliente.telefono)"
                        v-show="cliente.telefono"
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-phone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            cliente.telefono
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="llamar(cliente.celular)"
                        v-show="cliente.celular"
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-cellphone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            cliente.celular
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Celular</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-show="cliente.correo">
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-email </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            cliente.correo
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Correo</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-card outlined class="cool">
                      <v-card-text>
                        <v-card-title primary-title> Detalles </v-card-title>
                        <v-card-text>
                          {{ cliente.detalles }}
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="cool pa-2" outlined>
                  <mapa v-if="mostrarMapa" ref="mapa"></mapa>

                  <v-card-text>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-account-cash </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            formatoDinero(cliente.costo_instalacion)
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Costo Instalación</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-calendar-check </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            cliente.created_at
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Registrado</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <strong v-if="cliente.ayudantes.length > 0" class="ma-2"
                        >Servicios</strong
                      >
                      <v-list
                        v-for="servicio in cliente.servicios"
                        :key="servicio.id"
                        two-line
                      >
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="pink"> mdi-speedometer </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              servicio.nombre
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Servicio</v-list-item-subtitle
                            >
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              servicio.burst_limit_bajada + "MB"
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Velocidad</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-list-item class="grow">
                        <v-list-item-avatar color="grey darken-3">
                          <img
                            v-if="cliente.instalador.imagen"
                            :src="cliente.instalador.imagen"
                          />
                          <img v-else src="/img/user_default.png" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ cliente.instalador.nombre }}
                            {{ cliente.instalador.apellido_paterno }}
                            {{ cliente.instalador.apellido_materno }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Instalador</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <strong v-if="cliente.ayudantes.length > 0" class="ma-2"
                        >Ayudantes del instalador</strong
                      >
                      <v-list
                        v-for="ayudante in cliente.ayudantes"
                        :key="ayudante.id"
                        two-line
                      >
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="teal"> mdi-account </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              ayudante.FullName
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Empleado</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-list-item class="grow">
                        <v-list-item-avatar color="grey darken-3">
                          <img
                            v-if="cliente.recepcionista.imagen"
                            :src="cliente.recepcionista.imagen"
                          />
                          <img v-else src="/img/user_default.png" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ cliente.recepcionista.nombre }}
                            {{ cliente.recepcionista.apellido_paterno }}
                            {{ cliente.recepcionista.apellido_materno }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Registrador</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import mapa from "@/js/components/clientes/detalles/cliente/mapa.vue";
import Common from "@/js/Common";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    mapa,
  },
  data() {
    return {
      mostrarMapa: true,
      cliente: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  watch: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.cliente.latitud,
        this.cliente.longitud,
        this.cliente.FullName
      );
    },
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `cliente/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.cliente = result.data.cliente;

            this.$store.dispatch(
              "showCliente",
              Object.assign({}, result.data.cliente)
            );
            this.esperarComponente();
          }
        });
    },
    detalles() {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + this.cliente.id;
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad, 2);
    },
    iniciarInstalacion() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `iniciar/instalacion/cliente/` + this.cliente.id,
          data: {},
        })
        .then((result) => {
          this.cliente.status_cliente_id =
            result.data.cliente.status_cliente_id;
        });
    },
    finalizarInstalacion() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `finalizar/instalacion/cliente/` + this.cliente.id,
          data: {},
        })
        .then((result) => {
          this.cliente.status_cliente_id =
            result.data.cliente.status_cliente_id;
        });
    },
    llamar(numero) {
      document.location.href = "tel:" + numero;
    },
    abrirEnMaps(latitud, longitud) {
      if (latitud && longitud) {
        const url = `https://www.google.com/maps?q=${latitud},${longitud}`;
        window.open(url);
      }
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
  },
};
</script>