<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-card>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-card-title>
                Notificaciones via correo electronico
                <v-spacer></v-spacer>
              </v-card-title>
            </v-layout>
            <v-container pa-5 grid-list-md>
              <v-layout row wrap>
                <v-layout row wrap>
                  <v-btn-toggle shaped dense>
                    <v-btn dense @click="nuevo()" color="primary">
                      <v-icon left>mdi-plus</v-icon>
                      Crear correo
                    </v-btn>
                    <v-btn dense @click="configurarCorreo()" color="primary">
                      <v-icon left>mdi-settings</v-icon>
                      Correos automaticos
                    </v-btn>
                  </v-btn-toggle>
                  <v-spacer></v-spacer>
                  <v-text-field
                    dense
                    v-model="search"
                    clearable
                    @keyup.enter="getDatos"
                    append-icon="mdi-magnify"
                    label="Buscador"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-layout>
              </v-layout>
            </v-container>
            <v-data-table
              dense
              :search="search"
              :headers="headers"
              :items="elementos"
              :options.sync="options"
              :server-items-length="totalElementos"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [15, 50, 100, 500, -1],
              }"
            >
              <template v-slot:item.id="{ item }">
                {{
                  elementos
                    .map(function (x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:item.enviado="{ item }">
                <v-chip dark :color="item.enviado ? 'green' : 'red'" small>{{
                  item.enviado ? "Si" : "Pendiente"
                }}</v-chip>
              </template>
              <template v-slot:item.opciones="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="editar(item)" icon v-on="on">
                      <v-icon small>mdi-send</v-icon>
                    </v-btn>
                  </template>
                  <span>Reenviar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="eliminar(item)" icon v-on="on">
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                  >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                >
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                  <v-row align="center">
                    <v-col> Ningún dato que mostrar :( </v-col>
                    <v-col class="shrink">
                      <v-btn @click="getDatos()"
                        ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
        <nuevo ref="nuevo"></nuevo>
        <editar ref="editar"></editar>
        <configurarCorreo ref="configuracionCliente"></configurarCorreo>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import nuevo from "@/js/components/clientes/detalles/notificaciones/NuevoCorreo.vue";
import editar from "@/js/components/clientes/detalles/notificaciones/editarCorreo.vue";
import configurarCorreo from "@/js/components/clientes/detalles/notificaciones/configuracionCorreoCliente.vue";
export default {
  components: {
    nuevo,
    editar,
    configurarCorreo,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: false,
      options: {},
      clienteId: null,
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "Asunto", value: "asunto", sortable: false },
        { text: "Correo", value: "correo", sortable: false },
        { text: "Mensaje", value: "mensaje", sortable: false },
        { text: "Fecha", value: "fecha" },
        { text: "Hora", value: "hora" },
        { text: "Enviado", value: "enviado" },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.clienteId = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `correos/cliente/` + this.clienteId,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.correos.data;
          this.totalElementos = result.data.correos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    nuevo() {
      this.$refs.nuevo.mostrar().then((notificacion) => {
        if (notificacion) {
          this.elementos.unshift(notificacion);
          this.totalElementos = this.elementos.length;
        }
      });
    },
    editar(elemento) {
      this.notificacionActualizar = elemento;
      var correo = Object.assign({}, elemento);
      this.$refs.editar.cargarDatos(correo).then((resultado) => {
        if (resultado) {
          this.elementos.unshift(resultado);
        }
      });
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open(
          "Confirmación",
          "Solo se elimina el registro, la notificacion no se puede cancelar. Esta seguro de eliminar este elemento?",
          { color: "warninig" }
        )
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `correo/` + elemento.id;
            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          }
        });
    },
    configurarCorreo() {
      this.$refs.configuracionCliente.cargarDatos(this.clienteId);
    },
  },
};
</script>
