<template>
  <div>
    <v-row v-if="negocio">
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title primary-title>
            <strong>{{ negocio.nombre }}</strong>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="subtitle-1">
            <v-chip v-if="negocio.tipo" dark color="amber" outlined
              >{{ negocio.tipo.tipo }}
              <v-icon right>mdi-store</v-icon>
            </v-chip>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-list two-line>
              <v-list-item v-if="negocio.encargado">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-account </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    negocio.encargado.FullName
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Encargado</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-map-marker </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    negocio.codigo_postal +
                    ", " +
                    negocio.direccion +
                    ", " +
                    negocio.colonia +
                    ", " +
                    negocio.municipio
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="negocio.telefono">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-phone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ negocio.telefono }}</v-list-item-title>
                  <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="negocio.celular">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-cellphone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ negocio.celular }}</v-list-item-title>
                  <v-list-item-subtitle>Celular</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="negocio.corre">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-email </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ negocio.correo }}</v-list-item-title>
                  <v-list-item-subtitle>Celular</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" flat>
        <v-row>
          <v-col cols="12">
            <mapa v-if="mostrarMapa" ref="mapa"></mapa>
          </v-col>
          <v-col cols="12" md="12">
            <v-card class="cool">
              <v-card-text>
                <v-card-title primary-title> Detalles </v-card-title>
                <v-card-text>
                  {{ negocio.detalles }}
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <balance></balance>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <ingresosEgresos></ingresosEgresos>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Common from "@/js/Common";
import mapa from "@/js/components/mapa/mapaComponent.vue";
import Negocio from "@/js/components/negocios/Negocio.js";
import balance from "@/js/components/negocios/detalles/balanceNegocio.vue";
import ingresosEgresos from "@/js/components/negocios/detalles/graficaIngresosEgresosNegocio.vue";

export default {
  components: {
    mapa,
    balance,
    ingresosEgresos,
  },
  data() {
    return {
      negocio: new Negocio(),
      mostrarMapa: true,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.getDatos(arreUrl.pop());
  },
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.negocio.latitud,
        this.negocio.longitud,
        "markerNegocio.png",
        this.negocio.nombre,
        false
      );
    },
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `negocio/` + id,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.negocio = result.data.negocio;
            this.esperarComponente();
          }
        });
    },
  },
};
</script>