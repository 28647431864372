<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-row v-if="actividad">
              <v-col cols="12">
                <v-card class="cool" outlined>
                  <v-card-title primary-title>
                    Detalles Actividad
                  </v-card-title>
                  <v-card-text>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon
                          v-if="actividad.estado_id == 1"
                          class="orange"
                          dark
                        >
                          mdi-calendar
                        </v-icon>
                        <v-icon
                          v-if="actividad.estado_id == 2"
                          class="blue"
                          dark
                        >
                          mdi-calendar-clock
                        </v-icon>
                        <v-icon
                          v-if="actividad.estado_id == 3"
                          class="green"
                          dark
                        >
                          mdi-calendar-check
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div>{{ actividad.trabajo }}</div>
                        <v-list-item-subtitle
                          >Dia: {{ actividad.fecha }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top v-if="actividad.estado_id == 1">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="blue"
                              fab
                              dark
                              @click="iniciarActividad()"
                              v-on="on"
                            >
                              <v-icon>mdi-play</v-icon>
                            </v-btn>
                          </template>
                          <span>Iniciar Actividad</span>
                        </v-tooltip>
                        <v-tooltip top v-if="actividad.estado_id == 2">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="green"
                              fab
                              dark
                              @click="finalizarActividad()"
                              v-on="on"
                            >
                              <v-icon>mdi-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Finalizar Actividad</span>
                        </v-tooltip>

                        <v-chip
                          v-if="actividad.estado_id == 3"
                          class="ma-2"
                          color="green"
                          outlined
                        >
                          <v-icon start
                            >mdi-checkbox-marked-circle-outline</v-icon
                          >
                          Finalizada
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="cool pa-2" outlined>
                  <v-card-title primary-title> Ubicación </v-card-title>
                  <mapaCustom v-if="mostrarMapa" ref="mapa"></mapaCustom>

                  <v-card-text>
                    <v-list two-line>
                      <v-list-item
                        @click="
                          abrirEnMaps(actividad.latitud, actividad.longitud)
                        "
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-map-marker </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ actividad.direccion }},
                            {{ actividad.colonia }},{{ actividad.municipio }}
                            {{ actividad.codigo_postal }}</v-list-item-title
                          >
                          <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-latitude </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            actividad.latitud
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Latitud</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-longitude </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            actividad.longitud
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Longitud</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-card class="cool" outlined>
                      <v-card-text>
                        <v-card-title primary-title> Materiales </v-card-title>
                        <v-card-text>
                          {{ actividad.materiales }}
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <v-card class="cool" outlined>
                      <v-card-text>
                        <v-card-title primary-title> Detalles </v-card-title>
                        <v-card-text>
                          {{ actividad.detalles }}
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <v-card class="cool" outlined>
                      <v-card-text>
                        <v-card-text>
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-cash </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  actividad.costo
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Costo Actividad</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo">
                                  mdi-clock-start
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  actividad.hora_inicio
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Hora inicio</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-clock-end </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  actividad.hora_fin
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Hora fin</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item class="grow">
                              <v-list-item-avatar color="grey darken-3">
                                <img
                                  v-if="actividad.personal.imagen"
                                  :src="actividad.personal.imagen"
                                />
                                <img v-else src="/img/user_default.png" />
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  >{{ actividad.personal.nombre }}
                                  {{ actividad.personal.apellido_paterno }}
                                  {{
                                    actividad.personal.apellido_materno
                                  }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Instalador encargado</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <strong
                              v-if="actividad.ayudantes.length > 0"
                              class="ma-2"
                              >Ayudantes</strong
                            >
                            <v-list
                              v-for="ayudante in actividad.ayudantes"
                              :key="ayudante.id"
                              two-line
                            >
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="teal"> mdi-account </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    ayudante.FullName
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Empleado</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                            <v-list-item class="grow">
                              <v-list-item-avatar color="grey darken-3">
                                <img
                                  v-if="actividad.asignado.imagen"
                                  :src="actividad.asignado.imagen"
                                />
                                <img v-else src="/img/user_default.png" />
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  >{{ actividad.asignado.nombre }}
                                  {{ actividad.asignado.apellido_paterno }}
                                  {{
                                    actividad.asignado.apellido_materno
                                  }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >Registrador</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import mapaCustom from "@/js/components/mapa/mapaCustom.vue";
import Common from "@/js/Common";
export default {
  components: {
    mapaCustom,
  },
  data() {
    return {
      mostrarMapa: true,
      actividad: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  watch: {},
  methods: {
    async esperarComponente2() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.actividad.latitud,
        this.actividad.longitud,
        this.actividad.trabajo
      );
    },
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.actividad.latitud,
        this.actividad.longitud,
        "markerTrabajo.png",
        this.actividad.trabajo,
        true
      );
    },
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "actividad/" + id,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.actividad = result.data.actividad;
            this.esperarComponente();
          }
        });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad, 2);
    },
    iniciarActividad() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `iniciar/actividad/` + this.actividad.id,
          data: {},
        })
        .then((result) => {
          this.actividad.estado_id = result.data.actividad.estado_id;
        });
    },
    finalizarActividad() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `finalizar/actividad/` + this.actividad.id,
          data: {},
        })
        .then((result) => {
          this.actividad.estado_id = result.data.actividad.estado_id;
        });
    },
    abrirEnMaps(latitud, longitud) {
      if (latitud && longitud) {
        const url = `https://www.google.com/maps?q=${latitud},${longitud}`;
        window.open(url);
      }
    },
  },
};
</script>