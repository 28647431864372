<template>
  <div>
    <v-dialog v-model="dialog" max-width="300" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title
            ><strong> Forma de liquidacion</strong>
          </v-card-title>
          <v-container grid-list-md>
            <v-form dense ref="form" v-model="valid" lazy-validation>
              <v-layout row wrap>
                <v-select
                  :items="tipos"
                  v-model="tipoPago"
                  :rules="generalRegla"
                  item-text="tipo"
                  item-value="id"
                  label="Tipos de pagos"
                >
                  <template v-slot:append-item>
                    <v-divider></v-divider>
                    <v-list-item @click="nuevoTipoPago()">
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Nuevo</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-layout>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancelar()">Cancelar</v-btn>
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="rounded-pill pa-3"
                  @click="liquidarTodo()"
                >
                  <v-icon>mdi-lock</v-icon>
                  Liquidar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-container>
      </v-card>
    </v-dialog>
    <nuevoTipoPago ref="tipo_pago"></nuevoTipoPago>
  </div>
</template>
<script>
import nuevoTipoPago from "@/js/components/pagos/recursos/tablas/tipos_pagos/nuevoTipoPago.vue";

export default {
  components: {
    nuevoTipoPago,
  },
  data() {
    return {
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      valid: true,
      dialog: false,
      resolve: null,
      reject: null,
      tipoPago: 0,
      pagos: [],
      tipos: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    nuevoTipoPago() {
      this.$refs.tipo_pago.mostrar().then((resultado) => {
        if (resultado) {
          this.tipos.push(resultado);
          this.tipoPago = resultado.id;
        }
      });
    },
    mostrar(pagos) {
      this.pagos = pagos;
      console.log("this.pagos a liquidar: ", this.pagos);
      this.getTiposPago();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    liquidarTodo() {
      if (this.$refs.form.validate()) {
        let datos = {
          pagos: this.pagos.filter((pago) => pago.status_pago_id !== 1),
          tipoPago: this.tipoPago,
        };
        let dispatch = "UsersModule/OnSave";
        let endpoint = `liquidar/pagos/pendientes`;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: datos,
          })
          .then((result) => {
            if (result.data.message) {
              this.$store.dispatch("aviso", {
                mostrar: true,
                color: "primary",
                titulo: "Aviso",
                mensaje: result.data.message,
              });
            }
            var datos = {
              cliente: result.data.cliente,
              pagosLiquidados: result.data.pagos,
            };
            this.resolve(datos);
            this.dialog = false;
          });
      }
    },
    getTiposPago() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "tipos_pagos/empresa",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos_pago;
          this.dialog = true;
        });
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>