<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-container v-if="cliente" grid-list-xs>
          <v-card class="cool pa-2">
            <v-card-title primary-title>
              <v-spacer></v-spacer>
              <img
                src="/img/user_default.png"
                height="160"
                width="160"
                class="rounded-circle"
              />
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-title primary-title class="py-0">
              <v-spacer></v-spacer>
              {{ cliente.nombre }} {{ cliente.apellido_paterno }}
              {{ cliente.apellido_materno }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-title class="py-0">
              <v-spacer></v-spacer>
              <v-chip
                v-if="cliente.status_cliente_id == 1"
                color="orange"
                label
                outlined
              >
                En proceso de instalación
                <v-icon right>mdi mdi-hard-hat</v-icon>
              </v-chip>
              <v-chip
                v-if="cliente.status_cliente_id == 2"
                color="green"
                label
                outlined
              >
                Activo
                <v-icon right>mdi-lan-connect</v-icon>
              </v-chip>
              <v-chip
                v-if="cliente.status_cliente_id == 3"
                color="red"
                label
                outlined
              >
                Suspendido
                <v-icon right>mdi-lan-disconnect</v-icon>
              </v-chip>
              <v-chip
                v-if="cliente.status_cliente_id == 4"
                color="indigo"
                outlined
              >
                Pendiente
                <v-icon right>mdi-wifi-off</v-icon>
              </v-chip>
              <v-chip
                v-if="cliente.status_cliente_id == 5"
                color="grey"
                outlined
              >
                Baja temporal
                <v-icon right>mdi-account-off</v-icon>
              </v-chip>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-subheader>Mis Datos</v-subheader>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-email</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          cliente.correo
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Correo Electronico</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-cellphone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          cliente.celular
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Celular</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          cliente.telefono
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="red">mdi-cash-register</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >${{ cliente.saldo }}</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >Saldo Pendiente</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="green">mdi-wifi-strength-4-lock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ cliente.antena.contrasena_wifi }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >Contraseña de Wi-Fi</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-subheader>Mis Fechas</v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >El dia {{ cliente.dia_pago }} de cada
                      mes</v-list-item-title
                    >
                    <v-list-item-subtitle>Dia de pago</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-calendar-export</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >El dia {{ cliente.dia_factura }} de cada
                      mes</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Dia de creación de factura</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-calendar-remove</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >El dia {{ cliente.dia_pago + cliente.dias_gracia }} de
                      cada mes</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Dia de corte de servicio</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
import mapa from "@/js/components/clientes/detalles/cliente/mapa.vue";
export default {
  components: {
    mapa,
  },
  data() {
    return {
      cliente: null,
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "cliente/datos/home",
          data: {},
        })
        .then((result) => {
          this.cliente = result.data.cliente;

          this.$store.dispatch("showCliente", result.data.cliente);
        });
    },
  },
};
</script>