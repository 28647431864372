<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1">
                <strong> Tipos de notificación </strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      dense
                      @click="updateOrCreate()"
                      color="red"
                      class="rounded-pill"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      clearable
                      class="rounded-pill"
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      solo
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [15, 50, 100, 500, -1],
                }"
              >
                <template v-slot:item.id="{ item }">
                  {{ elementos.map((x) => x.id).indexOf(item.id) + 1 }}
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="updateOrCreate(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>

                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <exportar ref="refExportar"></exportar>
        <editarTipoNotificacion ref="refEditar"></editarTipoNotificacion>
      </div>
    </base-component>
  </div>
</template>

<script>
import editarTipoNotificacion from "@/js/components/notificaciones/tipos/editarTipoNotificacion.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";

export default {
  components: {
    editarTipoNotificacion,
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: false,
      options: {},
      status_id: "",
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Titulo", value: "title", sortable: false },
        { text: "Contenido", value: "content", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  methods: {
    getDatos() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "notification-type",
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.data.data;
          this.totalElementos = result.data.data.total;
        });
    },
    sonTodos(cantidad) {
      return cantidad === -1 ? this.totalElementos : cantidad;
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        return (
          this.options.sortBy[0] + (this.options.sortDesc[0] ? "|desc" : "|asc")
        );
      }
      return "id|desc";
    },
    updateOrCreate(item = {}) {
      this.$refs.refEditar.cargarDatos({ ...item }).then((confirm) => {
        if (confirm) {
          if (item.id) {
            this.elementos.splice(this.elementos.indexOf(item), 1, confirm);
          } else {
            this.elementos.push(confirm);
          }
        }
      });
    },
  },
};
</script>
