var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component mt-4"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"cool pa-3"},[_c('v-card-title',{staticClass:"display-1"},[_c('strong',[_vm._v("Trabajos")])]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"solo":"","append-icon":"mdi-magnify","label":"Buscador","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getDatos.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-format-horizontal-align-left',
                                lastIcon: 'mdi-format-horizontal-align-right',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageAllText: 'Todos',
                                itemsPerPageText:'Registros por página:',
                                itemsPerPageOptions: [3,5,10,15,-1]
                                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.id",fn:function({ item }){return [_vm._v("\n                           "+_vm._s(_vm.elementos.map(function(x) {return x.id; }).indexOf(item.id)+1)+"\n                        ")]}},{key:"item.opciones",fn:function({ item }){return [(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.detalles(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\tmdi-dots-horizontal\n\t\t\t\t\t\t\t\t\t\t\t\t")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Ver detalles")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v("\n                                    Ningún dato que mostrar :(\n                                ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1),_vm._v(" "),_c('exportar',{ref:"refExportar"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }