import { render, staticRenderFns } from "./ram.vue?vue&type=template&id=a03d8e9e"
import script from "./ram.vue?vue&type=script&lang=js"
export * from "./ram.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports