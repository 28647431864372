class Torre {
    constructor() {
        this.nombre = "";
        this.encargado = "";
        this.Telefono = "";
        this.celular = "";
        this.direccion = "";
        this.longitud = "";
        this.latitud = "";
        this.detalles = "";
        this.zona_id = null;
    }
}

export default Torre