<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool">
              <v-card-title primary-title>
                <strong>Detalles Zona</strong>
              </v-card-title>
              <v-tabs v-model="tab" color="red" grow show-arrows>
                <v-tab>
                  <v-icon left>mdi-map-marker</v-icon>
                  Datos
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-account-multiple</v-icon>
                  Clientes</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-volume-low</v-icon>
                  antenas</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-cash-multiple</v-icon>
                  Pagos</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-speedometer</v-icon>
                  Servicios</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-radio-tower</v-icon>
                  Torres</v-tab
                >
              </v-tabs>
              <v-card-text>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <ubicacion></ubicacion>
                  </v-tab-item>
                  <v-tab-item>
                    <clientes></clientes>
                  </v-tab-item>
                  <v-tab-item>
                    <antenas></antenas>
                  </v-tab-item>
                  <v-tab-item>
                    <pagos></pagos>
                  </v-tab-item>
                  <v-tab-item>
                    <servicios></servicios>
                  </v-tab-item>
                  <v-tab-item>
                    <torres></torres>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import ubicacion from "@/js/components/red/zonas/detalles/ubicacionZona.vue";
import clientes from "@/js/components/red/zonas/detalles/clientesZona.vue";
import antenas from "@/js/components/red/zonas/detalles/antenasZona.vue";
import pagos from "@/js/components/red/zonas/detalles/pagosZona.vue";
import torres from "@/js/components/red/zonas/detalles/torresZona.vue";
import servicios from "@/js/components/red/zonas/detalles/serviciosZona.vue";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    ubicacion,
    clientes,
    antenas,
    pagos,
    servicios,
    torres,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_ZONA", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_ZONA");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {},
};
</script>