<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-card>
          <v-card-title>
            Tabla laravel
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              clearable
              @keyup.enter="getDatos"
              append-icon="mdi-magnify"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :search="search"
            :headers="headers"
            :items="elementos"
            :options.sync="options"
            :server-items-length="totalElementos"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-format-horizontal-align-left',
              lastIcon: 'mdi-format-horizontal-align-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por página:',
              itemsPerPageOptions: [15, 50, 100, 500, -1],
            }"
          >
            <template v-slot:no-results>
              <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
              >
            </template>
            <template v-slot:item.id="{ item }">
              {{
                elementos
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(item.id) + 1
              }}
            </template>
            <template v-slot:item.opciones="{ item }">
              <v-tooltip v-if="item.eliminado == false" top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editar(item)" icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip v-if="item.eliminado == false" top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="eliminar(item)" icon v-on="on">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Desactivar</span>
              </v-tooltip>
              <v-tooltip v-if="item.eliminado == false" top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="detalles(item.id)" icon v-on="on">
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Ver detalles</span>
              </v-tooltip>
              <v-tooltip v-if="item.eliminado" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dense
                    @click="restaurar(item)"
                    :color="item.eliminado == true ? 'red' : 'green'"
                    v-on="on"
                    fab
                    x-small
                    dark
                  >
                    <v-icon>mdi-backup-restore</v-icon>
                  </v-btn>
                </template>
                <span>Restaurar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-alert
                :value="true"
                color="orange accent-3"
                icon="mdi-alert-circle"
              >
                <v-row align="center">
                  <v-col> Ningún dato que mostrar :( </v-col>
                  <v-col class="shrink">
                    <v-btn @click="getDatos()"
                      ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </base-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "nombre", value: "nombre", sortable: false },
        { text: "email", value: "email" },
        { text: "celular", value: "celular", sortable: false },
        { text: "rol", value: "role", sortable: false },
        { text: "opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    //options
    /*
        page: 2
        itemsPerPage: 10
        sortBy: Array(0)
        sortDesc: Array(0)
        groupBy: Array(0)
        groupDesc: Array(0)
        mustSort: false
        multiSort: false


        //request

        "search" => null
        "sort" => "id|desc"
        "page" => "1"
        "per_page" => "10"
        */
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `tabla-ejemplo/datos`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.data;
          this.totalElementos = result.data.meta.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
  },
};
</script>