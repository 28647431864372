<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-3">
              <v-card-title class="display-1">
                <strong>Pagos</strong>
                <v-spacer></v-spacer>
                <v-flex class="d-flex justify-center" xs12 md6>
                  <v-radio-group dense v-model="estadoPago" row>
                    <v-radio
                      dense
                      color="orange"
                      label="Pendiente"
                      value="2"
                    ></v-radio>
                    <v-radio
                      dense
                      color="green"
                      label="Liquidado"
                      value="1"
                    ></v-radio>

                    <v-radio
                      dense
                      color="purple"
                      label="Promesa"
                      value="3"
                    ></v-radio>
                    <v-radio
                      dense
                      color="blue"
                      label="Todos"
                      value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-card-title>
              <v-card v-if="estadisticas" class="cool pa-1 ma-2" outlined>
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 md3>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Liquidado</v-list-item-title>
                          <v-list-item-subtitle
                            >Cantidad:
                            {{
                              estadisticas.liquidados_cantidad
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action class="green--text">
                          {{ dinero(estadisticas.liquidados_monto) }}
                        </v-list-item-action>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Pendientes</v-list-item-title>
                          <v-list-item-subtitle
                            >Cantidad:
                            {{
                              estadisticas.pendiente_cantidad
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action class="orange--text">
                          {{ dinero(estadisticas.pendiente_monto) }}
                        </v-list-item-action>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Promesa</v-list-item-title>
                          <v-list-item-subtitle
                            >Cantidad:
                            {{
                              estadisticas.promesa_cantidad
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action class="indigo--text">
                          {{ dinero(estadisticas.promesa_monto) }}
                        </v-list-item-action>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Retrasados</v-list-item-title>
                          <v-list-item-subtitle
                            >Cantidad:
                            {{
                              estadisticas.retrasados_cantidad
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action class="red--text">
                          {{ dinero(estadisticas.retrasados_monto) }}
                        </v-list-item-action>
                      </v-list-item>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="9">
                    <v-btn
                      @click="nuevo()"
                      class="rounded-pill ma-1"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Nuevo
                    </v-btn>
                    <v-btn
                      @click="registrarPago()"
                      class="rounded-pill ma-1"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-cash</v-icon>
                      Registrar
                    </v-btn>
                    <v-btn
                      @click="generarFacturas()"
                      class="rounded-pill ma-1"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-tag-plus</v-icon>
                      Generar
                    </v-btn>
                    <v-btn
                      @click="exportar()"
                      class="rounded-pill ma-1"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-file-export</v-icon>
                      Exportar
                    </v-btn>
                    <v-menu offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          color="purple"
                          class="white--text rounded-pill ma-1"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ nombreZona }}
                          <v-icon rigth>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-responsive
                          id="scroll-target"
                          class="overflow-y-auto"
                          :max-height="450"
                        >
                          <v-list-item
                            v-for="z in zonas"
                            :key="z.id"
                            link
                            @click="filtrarZona(z)"
                          >
                            <v-list-item-title>{{
                              z.nombre
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-responsive>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      solo
                      class="rounded-pill"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [10, 500, 1000, 5000, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.cliente="{ item }">
                  <v-chip
                    v-if="item.clienteEliminado"
                    class="mb-1"
                    outlined
                    dark
                    small
                    color="black"
                  >
                    <v-icon left>mdi-emoticon-dead</v-icon>
                    {{ item.nombreCliente }}</v-chip
                  >
                  <v-chip
                    class="mb-1"
                    outlined
                    v-else
                    dark
                    small
                    color="cyan"
                    @click="abrirCliente(item.cliente_id)"
                    ><v-icon left>mdi-account</v-icon>
                    {{ item.nombreCliente }}</v-chip
                  >

                  <v-chip
                    v-if="item.status_pago_id == 1 && item.cajero"
                    outlined
                    dark
                    small
                    color="orange"
                    @click="abrirCajero(item.persona_id)"
                    ><v-icon left>mdi-worker</v-icon> {{ item.cajero }}</v-chip
                  >
                </template>

                <template v-slot:item.descuento="{ item }">
                  <p class="success--text ma-1">
                    <v-icon small color="success"
                      >mdi-arrow-up-bold-circle-outline</v-icon
                    >${{ item.extra }}
                  </p>
                  <p class="red--text ma-1">
                    <v-icon small color="red"
                      >mdi-arrow-down-bold-circle-outline</v-icon
                    >${{ item.descuento }}
                  </p>
                </template>
                <template v-slot:item.correspondencia="{ item }">
                  {{ item.mes.mes }}<br />{{ item.anio }}
                </template>
                <template v-slot:item.detalles="{ item }">
                  <div v-if="item.status_pago_id == 1">
                    <div>
                      {{ "Pago: " + item.tipo.tipo }}
                    </div>
                    {{ item.fecha + " - " + item.hora }}
                  </div>
                  <p v-if="item.detalles">
                    {{ item.detalles }}
                  </p>
                </template>
                <template v-slot:item.estado="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        :color="calcularColor(item.status_pago_id)"
                        dark
                        @click="detalles(item)"
                      >
                        <v-icon left v-if="item.status_pago_id == 1"
                          >mdi-cash</v-icon
                        >
                        <v-icon left v-if="item.status_pago_id == 2"
                          >mdi-timer</v-icon
                        >
                        <v-icon left v-if="item.status_pago_id == 3"
                          >mdi-timer-off</v-icon
                        >
                        ${{ item.monto }}
                      </v-chip>
                    </template>
                    <span>{{ item.estado.status }} </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.retraso="{ item }">
                  <strong v-if="item.retraso == 0" class="green--text title">{{
                    item.retraso
                  }}</strong>
                  <strong v-else class="red--text">{{ item.retraso }}</strong>
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="detalles(item)" icon v-on="on">
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalles</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.status_pago_id == 1" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="ticketCalor(item.id)" icon v-on="on">
                        <v-icon small> mdi-printer </v-icon>
                      </v-btn>
                    </template>
                    <span>Imprimir ticket de calor</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="editar(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="eliminar(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>

                  <v-tooltip v-if="item.eliminado" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        dense
                        small
                        @click="restaurar(item)"
                        :color="item.eliminado == true ? 'red' : 'green'"
                        v-on="on"
                        fab
                        x-small
                        dark
                      >
                        <v-icon>mdi-backup-restore</v-icon>
                      </v-btn>
                    </template>
                    <span>Restaurar</span>
                  </v-tooltip>
                </template>

                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row>
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <formPago ref="refPago"></formPago>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <formEditarPago ref="refEditarPago"></formEditarPago>
        <exportar ref="refExportar"></exportar>
      </div>
    </base-component>
  </div>
</template>

<script>
import formPago from "@/js/components/pagos/todos/nuevoPago.vue";
import formEditarPago from "@/js/components/pagos/todos/editarPago.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import jsPDF from "jspdf";
import Comun from "@/js/Common.js";
import LocalStorage from "@/js/LocalStorage.js";

import "jspdf-autotable";

export default {
  components: {
    formPago,
    formEditarPago,
    exportar,
  },
  data() {
    return {
      estadoPago: "2",
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      hoy: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "Folio", align: "start", value: "id" },
        { text: "Monto y estado", value: "estado", sortable: false },
        { text: "Extra y descuento", value: "descuento", sortable: false },
        { text: "Mes y año", value: "correspondencia", sortable: false },
        { text: "Servicio", value: "servicio.nombre", sortable: false },
        { text: "Dias de Retraso", value: "retraso", sortable: false },
        { text: "Cliente/Cajero", value: "cliente", sortable: false },
        { text: "Detalles", value: "detalles", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialog: false,
      PagoActualizar: {},
      resolve: null,
      reject: null,
      zonas: [],
      zona: 0,
      nombreZona: "Zona",
      estadisticas: null,
    };
  },
  mounted() {
    this.getZonas();
  },
  watch: {
    options: {
      zona(val) {},
      handler() {
        this.getDatos();
        this.getZonas();
      },
      deep: true,
    },
    estadoPago(val) {
      this.getDatos();
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getZonas() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `zonas/empresa/todas`,
          data: {},
        })
        .then((result) => {
          this.zonas = result.data.zonas;

          this.zonas.push({ id: 0, nombre: "Todas" });
        });
    },
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
            zona: parseInt(this.zona),
          },
        })
        .then((result) => {
          this.loading = false;
          if ([200, 201].includes(result.status)) {
            this.elementos = result.data.pagos.data;
            this.totalElementos = result.data.pagos.total;
            this.estadisticas = result.data.estadisticas;
          }
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `pago/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          }
        });
    },
    nuevo() {
      this.$refs.refPago.mostrar().then((resultado) => {
        if (resultado) {
          this.totalElementos = this.elementos.unshift(resultado);
        }
      });
    },
    editar(elemento) {
      this.PagoActualizar = elemento;
      const pago = Object.assign({}, elemento);
      this.$refs.refEditarPago.esperarDatos(pago).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.PagoActualizar),
            1,
            confirm
          );
        }
      });
    },
    restaurar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de restaurar este elemento?", {
          color: "orange",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnUpdate";
            let endpoint = `pago/restaurar/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                item.eliminado = false;
              });
          }
        });
    },
    generarFacturas() {
      this.$refs.confirmar
        .open(
          "Confirmación",
          "Generar las facturas del mes actual de todos los clientes?",
          { color: "orange darken-2" }
        )
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnGet";
            let endpoint = "generar/facturas";
            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: {},
              })
              .then((result) => {
                this.getDatos();
              });
          } else {
          }
        });
    },
    calcularColor(id) {
      if (id == 1) {
        return "green";
      }
      if (id == 2) {
        return "deep-orange";
      }
      if (id == 3) {
        return "purple";
      }
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("pagos");
    },
    detalles(item) {
      document.location.href = "detalles/pago/" + item.id;
    },
    filtrarZona(zona) {
      this.nombreZona = zona.nombre;
      this.zona = zona.id;
      this.getDatos();
    },
    dinero(monto) {
      return Comun.formatMoney(monto);
    },

    abrirCliente(id) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);

      document.location.href = "detalles/cliente/" + id;
    },
    abrirCajero(id) {
      document.location.href = "/vista/persona/detalles/" + id;
    },
    registrarPago() {
      document.location.href = "/registrar/pago/vista";
    },
    ticketCalor(id) {
      let url = "ticket/calor/pago/" + id;
      window.open(url, "_blank");
    },
  },
};
</script>
