<template>
  <div class="mt-4">
    <actualizarStatus ref="status"></actualizarStatus>
  </div>
</template>

<script>
import actualizarStatus from "@/js/components/clientes/listar_clientes/actualizarEstadoCliente.vue";

export default {
  components: {
    actualizarStatus,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    cliente() {
      return this.$store.getters.getClienteEncontrado;
    },
  },
  methods: {
    editarStatus() {
      const cliente = Object.assign({}, this.cliente);
      this.$refs.status.cargarDatos(cliente).then((confirm) => {
        if (confirm) {
          this.$store.dispatch("asigaClienteEncontrado", confirm);
        }
      });
    },
  },
};
</script>
