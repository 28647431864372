<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-card class="cool-xs pa-4" outlined color="teal accent-1">
          <v-card-title primary-title> Memoria</v-card-title>
          <div v-if="showChart">
            <apexchart
              type="area"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";

export default {
  components: {
    apexchart,
  },
  data() {
    return {
      showChart: false,
      series: [
        {
          name: "RAM",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#1DE9B6"],
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "stepline",
        },
        fill: {
          opacity: 0.8,
          type: "pattern",
          pattern: {
            style: ["verticalLines", "horizontalLines"],
            width: 5,
            height: 6,
          },
        },
        title: {
          text: "RAM",
          align: "left",
        },
        grid: {
          row: {
            colors: ["transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  },
  mounted() {},
  computed: {},
  methods: {
    graficar(datos) {
      let rams = [];
      for (let index = 0; index < datos.length; index++) {
        let dato = datos[index];

        rams.push((dato.ram_libre / (1024 * 1024)).toFixed(1));

        var date = new Date(dato.created_at);
        var fecha = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        ).toISOString();

        this.chartOptions.xaxis.categories.push(fecha);
      }

      this.series = [
        {
          name: "Ram libre (Mb.)",
          data: rams,
        },
      ];
      this.showChart = true;
    },
  },
};
</script>