<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-row v-if="showChart">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md12>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-card class="cool pa-2 ma-2">
                      <div id="chart-spark1">
                        <apexchart
                          type="area"
                          height="100"
                          :options="chartOptions"
                          :series="series"
                        ></apexchart>
                      </div>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card class="cool pa-2 ma-2">
                      <div id="chart-spark2">
                        <apexchart
                          type="area"
                          height="100"
                          :options="chartOptionsSpark2"
                          :series="seriesSpark2"
                        ></apexchart>
                      </div>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card class="cool pa-2 ma-2">
                      <div id="chart-spark3">
                        <apexchart
                          type="area"
                          height="100"
                          :options="chartOptionsSpark3"
                          :series="seriesSpark3"
                        ></apexchart>
                      </div>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <graficaServiciosPago></graficaServiciosPago>
              </v-flex>
              <v-flex xs12 md6>
                <graficaMetodoPago></graficaMetodoPago>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md12>
                <graficaCajerosPagos></graficaCajerosPagos>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <graficaPagosZonas></graficaPagosZonas>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <graficaPagosAnio></graficaPagosAnio>
              </v-flex>
            </v-layout>
          </v-container>
        </v-row>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import apexchart from "vue-apexcharts";
import graficaPagosZonas from "@/js/components/pagos/estadisticas/PagosPorZona.vue";
import graficaServiciosPago from "@/js/components/pagos/estadisticas/graficaServiciosPago.vue";
import graficaMetodoPago from "@/js/components/pagos/estadisticas/graficaMetodoPago.vue";
import graficaPagosAnio from "@/js/components/pagos/estadisticas/graficaPagosAnio.vue";
import graficaCajerosPagos from "@/js/components/pagos/estadisticas/graficaCajerosPagos.vue";
export default {
  components: {
    apexchart,
    graficaPagosZonas,
    graficaServiciosPago,
    graficaMetodoPago,
    graficaPagosAnio,
    graficaCajerosPagos,
  },
  data() {
    return {
      showChart: false,
      series: [
        {
          name: "Liquidado",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 0.3,
        },
        yaxis: {
          min: 0,
        },
        colors: ["#82ed8a"],
        title: {
          text: "$0.00",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Liquidados de este mes",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },

      seriesSpark2: [
        {
          name: "Pendientes",
          data: [],
        },
      ],
      chartOptionsSpark2: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 0.3,
        },
        yaxis: {
          min: 0,
        },
        colors: ["#f2ac3a"],
        title: {
          text: "$0,00",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Pendientes de este mes",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },

      seriesSpark3: [
        {
          name: "Promesa",
          data: [],
        },
      ],
      chartOptionsSpark3: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 0.3,
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
        },
        colors: ["#ca3af2"],
        title: {
          text: "$0.00",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Promesas de este mes",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/estadisticas/datos`,
          data: {},
        })
        .then((result) => {
          this.promesas(result.data.promesas);
          this.liquidados(result.data.liquidados);
          this.pendientes(result.data.pendientes);
          this.showChart = true;
        });
    },
    liquidados(datos) {
      let montos = [];
      let nombres = [];
      let total = parseFloat(0);
      for (let index = 0; index < datos.length; index++) {
        total += parseFloat(datos[index].monto);
        montos.push(datos[index].monto);
        nombres.push(datos[index].cliente.FullName);
      }
      this.series = [
        {
          name: "Liquidado",
          data: montos,
        },
      ];
      this.chartOptions.title.text = Common.formatMoney(total, 2);
      this.chartOptions.xaxis.categories = nombres;
    },

    pendientes(datos) {
      let montos = [];
      let nombres = [];
      let total = parseFloat(0);
      for (let index = 0; index < datos.length; index++) {
        montos.push(datos[index].monto);
        nombres.push(datos[index].cliente.FullName);
        total += parseFloat(datos[index].monto);
      }
      this.seriesSpark2 = [
        {
          name: "Pendiente",
          data: montos,
        },
      ];
      this.chartOptionsSpark2.title.text = Common.formatMoney(total, 2);
      this.chartOptionsSpark2.xaxis.categories = nombres;
    },
    promesas(datos) {
      let montos = [];
      let nombres = [];

      let total = parseFloat(0);
      for (let index = 0; index < datos.length; index++) {
        nombres.push(datos[index].cliente.FullName);
        montos.push(datos[index].monto);
        total += parseFloat(datos[index].monto);
      }
      this.seriesSpark3 = [
        {
          name: "Promesa",
          data: montos,
        },
      ];
      this.chartOptionsSpark3.title.text = Common.formatMoney(total, 2);
      this.chartOptionsSpark3.xaxis.categories = nombres;
    },
  },
};
</script>
