<template>
  <div>
    <v-dialog v-model="dialog" max-width="700" persistent>
      <v-card class="cool pa-2">
        <v-card-title class="display-1">
          <strong>Nuevo</strong>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation dense>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Nombre"
                    v-model="antena.nombre"
                    :rules="generalRegla"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    label="IP"
                    v-model="antena.ip"
                    :rules="ipPrivadaRegla"
                    prepend-icon="mdi-ip"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md4>
                  <v-select
                    v-model="antena.tipo_antena_id"
                    :items="productos"
                    label="Tipo"
                    item-value="id"
                    :rules="generalRegla"
                    item-text="producto"
                    no-data-text="No hay elementos registrados"
                    prepend-icon="mdi-router-wireless"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar color="indigo">
                        <img v-if="item.imagen" :src="item.imagen" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.producto
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Marca: {{ item.marca }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          >Cantidad en stock:
                          {{ item.cantidad }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item @click="nuevoProducto()">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Agregar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>

                <v-flex xs12 md4>
                  <v-select
                    v-model="antena.zona_id"
                    :items="zonas"
                    item-text="nombre"
                    item-value="id"
                    label="Torre"
                    prepend-icon="mdi-city"
                    :rules="generalRegla"
                    no-data-text="No hay elementos registrados"
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item @click="nuevaZona()">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Agregar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Usuario"
                    v-model="antena.usuario"
                    prepend-icon="mdi-account-circle"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Contraseña"
                    v-model="antena.contrasena"
                    prepend-icon="mdi-textbox-password"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-select
                    v-model="antena.mikrotik_id"
                    :items="routers"
                    label="Mikrotik"
                    item-value="id"
                    :rules="generalRegla"
                    item-text="nombre"
                    no-data-text="No hay elementos registrados"
                    prepend-icon="mdi-server-minus"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="indigo"
                        class="headline font-weight-light white--text"
                      >
                        {{ item.nombre.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Ip: {{ item.ip }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-radio-group v-model="modo" inline>
                    <v-radio label="Punto de acceso" value="1"></v-radio>
                    <v-radio label="Estación" value="2"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex v-if="modo == 2" xs12 md6>
                  <v-select
                    v-model="antena.ap_id"
                    :items="puntos_de_acceso"
                    label="Se conecta a la antena"
                    item-value="id"
                    :rules="generalRegla"
                    item-text="ip"
                    no-data-text="No hay elementos registrados"
                    prepend-icon="mdi-server-minus"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="indigo"
                        class="headline font-weight-light white--text"
                      >
                        {{ item.producto.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.producto
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          ><strong>Ip: </strong>
                          {{ item.ip }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          ><strong>Zona: </strong>
                          {{ item.zona }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelar()">Cancelar</v-btn>
          <v-btn color="primary" @click="nuevo()" class="rounded-pill pa-3">
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <nuevaZona ref="nuevaZona"></nuevaZona>
    <formProducto ref="refProducto"></formProducto>
  </div>
</template>
<script>
import formProducto from "@/js/components/almacen/productos/nuevoProducto.vue";
import nuevaZona from "@/js/components/red/zonas/nuevaZona.vue";
import Antena from "@/js/components/clientes/Antena.js";

export default {
  components: {
    formProducto,
    nuevaZona,
  },
  data() {
    return {
      antena: new Antena(),
      valid: true,
      dialog: false,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      ipPrivadaRegla: [
        (v) =>
          /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            v
          ) || "Ip no valida. Ejemplo: 192.168.1.10",
      ],
      resolve: null,
      reject: null,
      productos: [],
      zonas: [],
      routers: [],
      puntos_de_acceso: [],
      modo: 1,
    };
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    mostrar() {
      this.getDatos();
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/antena",
          data: {},
        })
        .then((result) => {
          this.productos = result.data.antenas;
          this.zonas = result.data.zonas;
          this.routers = result.data.routers_admin;
          this.puntos_de_acceso = result.data.puntos_de_acceso;
        });
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
    nuevo() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "antena";

        this.antena.administracion = 3;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.antena,
          })
          .then((result) => {
            var antena = result.data.antena;
            this.dialog = false;
            this.limpiar();
            this.resolve(antena);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },

    nuevaZona() {
      this.$refs.nuevaZona.mostrar().then((resultado) => {
        if (resultado) {
          this.zonas.push(resultado);
          this.antena.zona_id = resultado.id;
        }
      });
    },
    nuevoProducto() {
      this.$refs.refProducto.cargarDatos().then((confirm) => {
        if (confirm) {
          this.productos.push(confirm);
          this.antena.tipo_antena_id = confirm.id;
        }
      });
    },
  },
};
</script>