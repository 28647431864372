<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="headline">
                <strong>Cuentas Bancarias</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      color="red"
                      class="rounded-pill"
                      dark
                      @click="dialogCuenta = true"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                  </v-col>

                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="rounded-pill"
                      solo
                      v-model="search"
                      clearable
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-data-table
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [3, 5, 10, 15, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editar(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="eliminar(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Desactivar</span>
                  </v-tooltip>

                  <v-tooltip v-if="item.eliminado" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        dense
                        @click="restaurar(item)"
                        :color="item.eliminado == true ? 'red' : 'green'"
                        v-on="on"
                        fab
                        x-small
                        dark
                      >
                        <v-icon>mdi-backup-restore</v-icon>
                      </v-btn>
                    </template>
                    <span>Restaurar</span>
                  </v-tooltip>
                </template>

                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <formEditarCuenta ref="refEditarCuenta"></formEditarCuenta>
        <v-dialog v-model="dialogCuenta" max-width="400" persistent>
          <v-card>
            <v-container grid-list-md>
              <v-card-title class="display-1" primary-title
                ><strong>Nuevo</strong></v-card-title
              >
              <v-card-text>
                <formCuenta ref="refCuenta"></formCuenta>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogCuenta = false">Cancelar</v-btn>
                <v-btn class="rounded-pill" color="primary" @click="nuevo()">
                  Registrar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>

<script>
import formCuenta from "@/js/components/configuracion/cuentas/nuevaCuenta.vue";
import formEditarCuenta from "@/js/components/configuracion/cuentas/editarCuenta.vue";

export default {
  components: {
    formCuenta,
    formEditarCuenta,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Banco", value: "banco", sortable: true },
        { text: "Numero de cuenta", value: "numero_cuenta", sortable: false },
        { text: "Tranferencia", value: "transferencia", sortable: false },
        { text: "Titular", value: "titular", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialogCuenta: false,
      CuentaActualizar: {},
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `cuenta_empresas/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.cuentas.data;
          this.totalElementos = result.data.cuentas.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `cuenta_empresa/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      let respuestaDialog = this.$refs.refCuenta.nuevo();

      if (respuestaDialog == null) {
        return;
      }
      let dispatch = "UsersModule/OnSave";
      let endpoint = "cuenta_empresa";
      let event = "insert";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: respuestaDialog,
        })
        .then((result) => {
          this.elementos.push(result.data.cuenta);
          this.dialogCuenta = false;
          this.totalElementos = this.elementos.length;
          this.$refs.refCuenta.limpiar();
        });
    },
    editar(elemento) {
      this.CuentaActualizar = elemento;
      const cuenta = Object.assign({}, elemento);
      this.$refs.refEditarCuenta.esperarDatos(cuenta).then((confirm) => {
        if (confirm) {
          this.actualizar(confirm);
        }
      });
    },
    actualizar(respuesta) {
      let dispatch = "UsersModule/OnUpdate";
      let endpoint = `cuenta_empresa/` + respuesta.id;

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: respuesta,
        })
        .then((result) => {
          this.elementos.splice(
            this.elementos.indexOf(this.CuentaActualizar),
            1,
            result.data.cuenta
          );
        });
    },
    restaurar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de restaurar este elemento?", {
          color: "orange",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnUpdate";
            let endpoint = `cuenta/restaurar/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                item.eliminado = false;
              });
          }
        });
    },
  },
};
</script>
