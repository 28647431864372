<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-card class="ma-0 cool pa-2" v-if="producto">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-card outlined class="cool">
                  <v-img
                    v-if="producto.imagen"
                    :src="producto.imagen"
                    max-height="150"
                  >
                    <v-chip dark class="ma-4">{{
                      producto.categoria.categoria
                    }}</v-chip>
                  </v-img>
                  <v-img v-else src="/img/noimagen.jpg" max-height="150">
                    <v-chip dark class="ma-4">{{
                      producto.categoria.categoria
                    }}</v-chip>
                  </v-img>
                  <v-card-title primary-title>
                    {{ producto.producto }}
                  </v-card-title>
                  <v-card-subtitle>
                    {{ producto.marca }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-archive</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                producto.cantidad
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Cantidad actual</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="grey">mdi-cash-minus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="red--text"
                                >${{
                                  producto.precio_compra
                                }}</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Precio de compra</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-alert-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                producto.stock_minimo
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Stock minimo</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="grey">mdi-ticket</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                producto.codigo
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Codigo</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="grey">mdi-cash-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="green--text"
                                >${{ producto.precio_venta }}</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Precio de venta</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="grey">mdi-tray-full</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                producto.stock_maximo
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Stock maximo</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12">
                        <v-card flat class="cool pa-2">
                          <v-divider></v-divider>
                          <v-card-title primary-title> Detalles </v-card-title>
                          <v-card-text>
                            <p>
                              {{ producto.detalles }}
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="cool pa-2" outlined>
                  <v-card-title primary-title>
                    {{ producto.proveedor.nombre }}
                  </v-card-title>
                  <v-card-subtitle> Proveedor </v-card-subtitle>
                  <v-card-text>
                    <v-list two-line>
                      <v-list-item
                        @click="
                          abrirEnMaps(
                            producto.proveedor.latitud,
                            producto.proveedor.longitud
                          )
                        "
                      >
                        <v-list-item-icon>
                          <v-icon color="blue">mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ producto.proveedor.direccion }},{{
                              producto.proveedor.colonia
                            }},{{ producto.proveedor.municipio
                            }}{{ producto.proveedor.codigo_postal }},{{
                              producto.proveedor.estado
                            }}</v-list-item-title
                          >
                          <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="grey">mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            producto.proveedor.telefono
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-cellphone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            producto.proveedor.celular
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Celular</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            producto.proveedor.correo
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Correo</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card outlined class="cool pa-2">
                  <v-card-title primary-title> Antena clientes </v-card-title>
                  <v-card-subtitle>
                    Clientes que usan este producto como su antena de internet.
                  </v-card-subtitle>
                  <v-card-subtitle>
                    <v-text-field
                      v-model="search_antenas"
                      append-icon="mdi-magnify"
                      label="Buscar cliente"
                      single-line
                      hide-details
                      solo
                      rounded
                    ></v-text-field>
                  </v-card-subtitle>
                  <v-card-text>
                    <v-data-table
                      :headers="headers_antenas"
                      :items="antenas"
                      :search="search_antenas"
                      @click:row="detalles"
                      class="row-pointer"
                    >
                      <template
                        v-slot:[`item.cliente.status_cliente_id`]="{ item }"
                      >
                        <v-chip
                          :color="getColor(item.cliente.status_cliente_id)[0]"
                          small
                          dark
                          >{{
                            getColor(item.cliente.status_cliente_id)[1]
                          }}</v-chip
                        >
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card outlined class="cool pa-2">
                  <v-card-title primary-title> Router clientes </v-card-title>
                  <v-card-subtitle>
                    Clientes que usan este producto como router wifi.
                  </v-card-subtitle>
                  <v-card-subtitle>
                    <v-text-field
                      v-model="search_routers"
                      append-icon="mdi-magnify"
                      label="Buscar cliente"
                      single-line
                      hide-details
                      solo
                      rounded
                    ></v-text-field>
                  </v-card-subtitle>
                  <v-card-text>
                    <v-data-table
                      :headers="headers_routers"
                      :items="routers"
                      :search="search_routers"
                      @click:row="detalles"
                      class="row-pointer"
                    >
                      <template
                        v-slot:[`item.cliente.status_cliente_id`]="{ item }"
                      >
                        <v-chip
                          :color="getColor(item.cliente.status_cliente_id)[0]"
                          small
                          dark
                          >{{
                            getColor(item.cliente.status_cliente_id)[1]
                          }}</v-chip
                        >
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  components: {},
  data() {
    return {
      search_antenas: "",
      headers_antenas: [
        { text: "Nombre", value: "cliente.FullName" },
        { text: "Status", value: "cliente.status_cliente_id" },
      ],
      search_routers: "",
      headers_routers: [
        { text: "Nombre", value: "cliente.FullName" },
        { text: "Status", value: "cliente.status_cliente_id" },
      ],
      mostrarMapa: true,
      producto: null,
      antenas: [],
      routers: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  watch: {},
  methods: {
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "producto/" + id,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.producto = result.data.producto;

            this.antenas = result.data.antenas;

            this.routers = result.data.routers;
          }
        });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad, 2);
    },
    detalles(item) {
      document.location.href = "detalles/cliente/" + item.cliente.id;
    },
    getColor(status) {
      switch (status) {
        case 1:
          return ["orange", "Instalación en proceso"];
        case 2:
          return ["green", "Activo"];
        case 3:
          return ["red", "Suspendido"];
        case 4:
          return ["indigo", "pendiente"];
        case 5:
          return ["grey", "Baja temporal"];
        default:
          break;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>