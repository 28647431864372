<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-card class="cool pa-2">
          <v-card-title primary-title> Tickets </v-card-title>

          <v-card-subtitle>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-btn
                  dense
                  @click="abrirChat()"
                  class="rounded-pill pa-3 mr-2"
                  color="red"
                  dark
                >
                  <v-icon left>mdi-ticket-account</v-icon>
                  Chatear con soporte tecnico
                </v-btn>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="search_tickets"
                  clearable
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  solo
                  rounded
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-subtitle>

          <v-card-text>
            <v-data-table
              :headers="headers_tickets"
              :items="tickets"
              :search="search_tickets"
            >
              <template v-slot:[`item.chat`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="abrirChat()" icon v-on="on">
                      <v-icon small>mdi-chat</v-icon>
                    </v-btn>
                  </template>
                  <span>Abrir Chat</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.estado_reporte_id`]="{ item }">
                <v-chip
                  :color="asignarColor(item.estado_reporte_id).color"
                  small
                  dark
                  >{{ asignarColor(item.estado_reporte_id).texto }}</v-chip
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <chat ref="refChat"></chat>
      </div>
    </base-component>
  </div>
</template>
  
  <script>
import chat from "@/js/components/reportes/chat.vue";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    chat,
  },
  data() {
    return {
      //tabla chats
      search_tickets: "",
      headers_tickets: [
        { text: "Abrir Chat", value: "chat" },
        { text: "Asunto", value: "asunto" },
        { text: "Fecha", value: "fecha" },
        { text: "Hora", value: "hora" },
        { text: "Personal", value: "personal.FullName" },
        { text: "Estatus", value: "estado_reporte_id" },
      ],
      estadoReportes: "1",
      tickets: [],
      ServicioActualizar: null,
      estados: [],
      dialogEstados: false,
      validEstados: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estado_id: "1",
    };
  },
  mounted() {
    this.getReportes();
  },
  watch: {
    estadoReportes(val) {
      this.getReportes();
    },
  },
  computed: {},
  methods: {
    getReportes() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "reportes/cliente/todos",
          data: {
            filtro: this.estadoReportes,
          },
        })
        .then((result) => {
          this.tickets = result.data.reportes;
          this.id = result.data.cliente_id;
        });
    },

    abrirChat() {
      this.$refs.refChat
        .esperarDatos("Personal", this.id, true)
        .then((confirm) => {});
    },

    asignarColor(id) {
      if (id == 1) {
        return { color: "red", texto: "Pendiente" };
      }
      if (id == 2) {
        return { color: "yellow", texto: "En proceso" };
      }
      if (id == 3) {
        return { color: "green", texto: "Solucionado" };
      }
      return { color: "black", texto: "error" };
    },
  },
};
</script>

  
  