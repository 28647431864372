<template>
  <v-card class="cool pa-3 ma-4">
    <div v-if="mostrar">
      <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    <v-progress-circular
      v-else
      :size="70"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
  </v-card>
</template>
<script>
import Common from "@/js/Common";
import apexchart from "vue-apexcharts";

export default {
  components: {
    apexchart,
  },
  data() {
    return {
      mostrar: false,
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },

          zoom: {
            enabled: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Ingresos y egresos totales",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
          title: {
            text: "Mes",
          },
        },
        yaxis: {
          title: {
            text: "$ Cantidad",
          },
          labels: {
            formatter: function (y) {
              return Common.formatMoney(y);
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Common.formatMoney(val);
            },
          },
        },
      },
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.getDatos(arreUrl.pop());
  },
  computed: {},
  methods: {
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `negocio/balance/meses/` + id,
          data: {},
        })
        .then((result) => {
          this.series = [
            {
              name: "Ingresos",
              data: result.data.ingresos,
            },
            {
              name: "Egresos",
              data: result.data.egresos,
            },
          ];

          this.mostrar = true;
        });
    },
  },
};
</script>