<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1">
                <strong>Mis pagos</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="6">
                    <v-text-field
                      solo
                      class="rounded-pill"
                      v-model="search"
                      clearable
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [3, 5, 10, 15, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.monto="{ item }">
                  ${{ item.egreso.monto }}
                </template>
                <template v-slot:item.socio="{ item }">
                  {{
                    item.persona.nombre +
                    " " +
                    item.persona.apellido_paterno +
                    " " +
                    item.persona.apellido_materno
                  }}
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verArchivo(item)" icon v-on="on">
                        <v-icon small> mdi-file </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver comprobante</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <exportar ref="refExportar"></exportar>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
export default {
  components: {
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      dialogNuevo: false,
      options: {},
      id: null,
      headers: [
        { text: "#", align: "start", value: "id", sortable: true },
        { text: "Monto", value: "monto", sortable: true },
        { text: "Persona", value: "socio", sortable: false },
        { text: "Tipo", value: "tipo.tipo", sortable: false },
        { text: "Fecha", value: "egreso.fecha", sortable: false },
        { text: "Hora", value: "egreso.hora", sortable: false },
        { text: "Observaciones", value: "observaciones", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {},
  computed: {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  methods: {
    getDatos(search) {
      this.loading = true;

      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `instalador/egreso/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.egresos.data;
          this.totalElementos = result.data.egresos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    verArchivo(item) {
      if (item.egreso.archivo != null) {
        window.open(item.egreso.archivo, "_blank");
      } else {
        this.$store.dispatch("notificaciones/add", {
          tipo: "warning",
          mensaje: "Sin archivo  :/",
        });
      }
    },
    exportar() {
      if (this.elementos.length > 0) {
        this.$refs.refExportar.cargarDatos(
          "egresos-empleado/" + this.elementos[0].persona_id
        );
      }
    },
  },
};
</script>