<template>
  <div>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex v-for="servicio in servicios" :key="servicio.id" xs12 md4>
          <v-card class="cool ma-1 pa-2">
            <v-card-title
              >{{ servicio.nombre }}
              <v-spacer></v-spacer>
              <v-chip dark color="blue">{{ servicio.tipo.tipo }}</v-chip>
            </v-card-title>

            <v-card-text>
              <v-row align="center" class="mx-0">
                <v-rating
                  :value="servicio.popularidad"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="22"
                ></v-rating>

                <div class="grey--text ms-4">
                  {{ servicio.clientesCantidad }} clientes
                </div>
                <v-spacer></v-spacer>
                <v-chip outlined color="purple"
                  >Zonas: {{ servicio.zonasCantidad }}
                </v-chip>
              </v-row>

              <div class="my-4 text-subtitle-1">$ {{ servicio.costo }}</div>
              <div>{{ servicio.detalles }}</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Velocidad</v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-speedometer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_limit_bajada
                          }}
                          M</v-list-item-title
                        >
                        <v-list-item-subtitle>Bajada</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="12" md="6">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-speedometer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{
                            servicio.burst_limit_subida
                          }}
                          M</v-list-item-title
                        >
                        <v-list-item-subtitle>Subida</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="detalles(servicio)" icon v-on="on">
                    <v-icon small>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>Detalles</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editarServicio(servicio)" icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar Servicio</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <editarServicio ref="refEditarServicio"></editarServicio>
  </div>
</template>
<script>
import Common from "@/js/Common";
import editarServicio from "@/js/components/servicios/dialogEditarServicioComponent.vue";

export default {
  components: {
    editarServicio,
  },
  data() {
    return {
      zona_id: null,
      servicios: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.zona_id = arreUrl.pop();
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `servicios/zona/` + this.zona_id,
        })
        .then((result) => {
          this.servicios = result.data.servicios;
        });
    },
    editarServicio(elemento) {
      this.ServicioActualizar = elemento;
      let servicio = Object.assign({}, elemento);
      this.$refs.refEditarServicio.esperarDatos(servicio).then((confirm) => {
        if (confirm) {
          this.servicios.splice(
            this.servicios.indexOf(this.ServicioActualizar),
            1,
            confirm
          );
        }
      });
    },
    detalles(item) {
      window.location.href = "vista/detalles/servicio/" + item.id;
    },
  },
};
</script>