<template>
  <div>
    <v-row v-if="cliente">
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title primary-title>
            <strong
              >{{ cliente.nombre }} {{ cliente.apellido_paterno }}
              {{ cliente.apellido_materno }}</strong
            >
            <v-spacer></v-spacer>
            <v-icon @click="editar()">mdi-pencil</v-icon>
          </v-card-title>
          <v-card-subtitle class="subtitle-1">
            <v-chip dark color="orange" outlined>{{
              cliente.tipo.tipo
            }}</v-chip>
            <v-chip
              @click="editarStatus()"
              v-if="cliente.status_cliente_id == 1"
              class="ma-2"
              color="orange"
              outlined
            >
              Instalacion en proceso
              <v-icon right>mdi-worker</v-icon>
            </v-chip>
            <v-chip
              @click="editarStatus()"
              v-if="cliente.status_cliente_id == 2"
              class="ma-2"
              color="green"
              outlined
            >
              Activo
              <v-icon right>mdi-wifi</v-icon>
            </v-chip>
            <v-chip
              @click="editarStatus()"
              v-if="cliente.status_cliente_id == 3"
              class="ma-2"
              color="red"
              outlined
            >
              Suspendido
              <v-icon right>mdi-timer</v-icon>
            </v-chip>
            <v-chip
              @click="editarStatus()"
              v-if="cliente.status_cliente_id == 4"
              class="ma-2"
              color="indigo"
              outlined
            >
              Pendiente
              <v-icon right>mdi-wifi-off</v-icon>
            </v-chip>
            <v-chip
              @click="editarStatus()"
              v-if="cliente.status_cliente_id == 5"
              class="ma-2"
              color="grey"
              outlined
            >
              Baja temporal
              <v-icon right>mdi-account-off</v-icon>
            </v-chip>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-map-marker </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ cliente.direccion }}, {{ cliente.colonia }},
                    {{ cliente.municipio }} {{ cliente.codigo_postal }},
                    {{ cliente.estado }}</v-list-item-title
                  >
                  <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="cliente.telefono">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-phone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ cliente.telefono }}</v-list-item-title>
                  <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="cliente.celular">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-cellphone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ cliente.celular }}</v-list-item-title>
                  <v-list-item-subtitle>Celular</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="cliente.correo">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-email </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ cliente.correo }}</v-list-item-title>
                  <v-list-item-subtitle>Correo</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-calendar-star </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Dia de pago</v-list-item-title>
                  <v-list-item-subtitle>{{
                    cliente.dia_pago
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-calendar-import </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Dia de facturacion</v-list-item-title>
                  <v-list-item-subtitle>{{
                    cliente.dia_factura
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-calendar-heart </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Dias de tolerancia</v-list-item-title>
                  <v-list-item-subtitle>{{
                    cliente.dias_gracia
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-calendar-remove </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Dia de suspención</v-list-item-title>
                  <v-list-item-subtitle>{{
                    cliente.dia_pago + cliente.dias_gracia
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list-item v-if="cliente.razon_social">
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-clipboard-account </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Razon social</v-list-item-title>
                <v-list-item-subtitle>{{
                  cliente.razon_social
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="cliente.tipo_identificacion">
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-briefcase-account </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tipo de identificación</v-list-item-title>
                <v-list-item-subtitle>{{
                  cliente.tipo_identificacion
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="cliente.RFC_RUC_NIT">
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-file-account </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>RFC-RUC-NIT</v-list-item-title>
                <v-list-item-subtitle>{{
                  cliente.RFC_RUC_NIT
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="cliente.retenciones">
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-folder-account </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Retenciones</v-list-item-title>
                <v-list-item-subtitle>{{
                  cliente.retenciones
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
        <v-card class="cool pa-2">
          <v-card-title primary-title>
            Imagenes de la instalación
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-file-input
                  label="Seleccionar fotos"
                  accept="image/*"
                  outlined
                  dense
                  class="mr-2"
                  chips
                  multiple
                  v-model="imagenes"
                ></v-file-input>
                <v-btn
                  color="secondary"
                  class="white--text"
                  fab
                  small
                  @click="subirImagenes()"
                >
                  <v-icon dark> mdi-cloud-upload </v-icon>
                </v-btn>
              </v-layout>
            </v-container>
            <v-carousel
              v-show="cliente.imagenes.length > 0"
              v-model="model_carrusel"
            >
              <v-carousel-item
                v-for="(item, i) in cliente.imagenes"
                :key="i"
                :src="item.imagen"
                cover
              >
                <v-row>
                  <v-spacer></v-spacer>
                  <div class="mr-5 mt-2 mx-4">
                    <v-btn @click="eliminarImagen()" color="error" fab dark>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" flat>
        <v-row>
          <v-col cols="12">
            <mapa v-if="mostrarMapa" ref="mapa"></mapa>
          </v-col>
          <v-col v-if="cliente.contrato" cols="12" md="12">
            <v-card class="cool">
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon class="red lighten-1" dark>
                          mdi-file-pdf-box
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          >Contrato del cliente</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <v-btn
                            outlined
                            @click="verContrato()"
                            color="primary"
                            class="rounded-pill"
                            prepend-icon="mdi-cloud-upload"
                          >
                            <v-icon left dark> mdi-eye </v-icon>
                            ver contrato</v-btn
                          >
                          <v-btn
                            color="secondary"
                            outlined
                            class="rounded-pill"
                            prepend-icon="mdi-cloud-upload"
                            @click="cliente.contrato = null"
                          >
                            <v-icon left dark> mdi-cloud-upload </v-icon>
                            Volver a subir</v-btn
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-else cols="12" md="12">
            <v-card class="cool">
              <v-card-title primary-title> Subir contrato </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-file-input
                      label="Seleccionar archivo"
                      accept="application/pdf"
                      outlined
                      dense
                      class="mr-2"
                      v-model="contrato"
                    ></v-file-input>
                    <v-btn
                      color="red"
                      class="white--text"
                      fab
                      small
                      @click="subirContrato()"
                    >
                      <v-icon dark> mdi-cloud-upload </v-icon>
                    </v-btn>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card class="cool">
              <v-card-text>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon class="green lighten-1" dark>
                      mdi-account-cash
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Costo instalación</v-list-item-title>

                    <v-list-item-subtitle>{{
                      formatoDinero(cliente.costo_instalacion)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="cool">
              <v-card-text>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon class="indigo lighten-1" dark>
                      mdi-calendar-check
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Dia de instalación</v-list-item-title>

                    <v-list-item-subtitle>{{
                      cliente.fecha_instalacion + " " + cliente.hora_instalacion
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="cool">
              <v-card-text>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon class="blue lighten-1" dark>
                      mdi-calendar-edit
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Registro</v-list-item-title>

                    <v-list-item-subtitle>{{
                      cliente.created_at
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" v-show="cliente.detalles">
            <v-card class="cool">
              <v-card-text>
                <v-card-title primary-title> Detalles </v-card-title>
                <v-card-text>
                  {{ cliente.detalles }}
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="cool">
              <v-card-title primary-title>
                <v-list-item class="grow">
                  <v-list-item-avatar color="grey darken-3">
                    <img
                      v-if="cliente.instalador.imagen"
                      :src="cliente.instalador.imagen"
                    />
                    <img v-else src="/img/user_default.png" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ cliente.instalador.nombre }}
                      {{ cliente.instalador.apellido_paterno }}
                      {{
                        cliente.instalador.apellido_materno
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>Instalador</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-title>

              <v-card flat>
                <v-card-text>
                  {{ cliente.instalador.direccion }},
                  {{ cliente.instalador.colonia }},
                  {{ cliente.instalador.municipio }}
                  {{ cliente.instalador.codigo_postal }},
                  {{ cliente.instalador.estado }}
                </v-card-text>
              </v-card>
              <v-list two-line>
                <v-list-item v-show="cliente.instalador.telefono">
                  <v-list-item-icon>
                    <v-icon color="teal"> mdi-phone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      cliente.instalador.telefono
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="teal"> mdi-cellphone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      cliente.instalador.celular
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Celular</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-show="cliente.instalador.correo">
                  <v-list-item-icon>
                    <v-icon color="teal"> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      cliente.instalador.correo
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Correo</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <strong v-if="cliente.ayudantes.length > 0" class="ma-2"
                >Ayudantes del instalador</strong
              >
              <v-list
                v-for="ayudante in cliente.ayudantes"
                :key="ayudante.id"
                two-line
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="teal"> mdi-account </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      ayudante.FullName
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Empleado</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="cool">
              <v-card-title primary-title>
                <v-list-item class="grow">
                  <v-list-item-avatar color="grey darken-3">
                    <img
                      v-if="cliente.recepcionista.imagen"
                      :src="cliente.recepcionista.imagen"
                    />
                    <img v-else src="/img/user_default.png" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ cliente.recepcionista.nombre }}
                      {{ cliente.recepcionista.apellido_paterno }}
                      {{
                        cliente.recepcionista.apellido_materno
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>Registrador</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-title>
              <v-card-text>
                <v-card flat>
                  <v-card-text>
                    {{ cliente.instalador.direccion }},
                    {{ cliente.instalador.colonia }},
                    {{ cliente.instalador.municipio }}
                    {{ cliente.instalador.codigo_postal }},
                    {{ cliente.instalador.estado }},
                    {{ cliente.recepcionista.codigo_postal }},
                    {{ cliente.recepcionista.estado }}
                  </v-card-text>
                </v-card>
                <v-list two-line>
                  <v-list-item v-show="cliente.recepcionista.telefono">
                    <v-list-item-icon>
                      <v-icon color="teal"> mdi-phone </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        cliente.recepcionista.telefono
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="teal"> mdi-cellphone </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        cliente.recepcionista.celular
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Celular</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-show="cliente.recepcionista.correo">
                    <v-list-item-icon>
                      <v-icon color="teal"> mdi-email </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        cliente.recepcionista.correo
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Correo</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <editarCliente ref="refEditarCliente"></editarCliente>
    <actualizarStatus ref="status"></actualizarStatus>

    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
  </div>
</template>
<script>
import mapa from "@/js/components/clientes/detalles/cliente/mapa.vue";
import editarCliente from "@/js/components/clientes/listar_clientes/editarCliente.vue";
import actualizarStatus from "@/js/components/clientes/listar_clientes/actualizarEstadoCliente.vue";

import Common from "@/js/Common";
export default {
  components: {
    mapa,
    editarCliente,
    actualizarStatus,
  },
  data() {
    return {
      mostrarMapa: true,
      cliente: null,
      contrato: null,
      imagenes: [],
      //carrusel
      model: 0,
      model_carrusel: 0,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  watch: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.cliente.latitud,
        this.cliente.longitud,
        this.cliente.FullName
      );
    },
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `cliente/` + id,
          data: {},
        })
        .then((result) => {
          this.cliente = result.data.cliente;
          this.$store.dispatch(
            "showCliente",
            Object.assign({}, result.data.cliente)
          );
          this.esperarComponente();
        });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad, 2);
    },
    editar() {
      this.mostrarMapa = false;
      const cliente = Object.assign({}, this.cliente);
      this.$refs.refEditarCliente.cargarDatos(cliente).then((confirm) => {
        if (confirm) {
          this.cliente = confirm;
        }
        this.mostrarMapa = true;
        this.esperarComponente();
      });
    },
    editarStatus() {
      this.mostrarMapa = false;
      const cliente = Object.assign({}, this.cliente);
      this.$refs.status.cargarDatos(cliente).then((confirm) => {
        if (confirm) {
          this.cliente.status_cliente_id = confirm.status_cliente_id;
        }
        this.mostrarMapa = true;
        this.esperarComponente();
      });
    },
    subirContrato() {
      if (this.contrato != null) {
        let formData = new FormData();
        formData.append("contrato", this.contrato);

        let dispatch = "UsersModule/OnSave";
        let endpoint =
          "subir/contrato/cliente/" + window.location.href.split("/").pop();

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: formData,
          })
          .then((result) => {
            this.cliente.contrato = result.data.contrato;
          });
      }
    },
    subirImagenes() {
      if (this.imagenes.length > 0) {
        let formData = new FormData();

        for (let i = 0; i < this.imagenes.length; i++) {
          let imagen = this.imagenes[i];
          formData.append("imagenes[]", imagen); // Usamos 'imagenes[]' para indicar que es un array
        }

        let dispatch = "UsersModule/OnSave";
        let endpoint =
          "subir/imagenes/instalacion/cliente/" +
          window.location.href.split("/").pop();

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: formData,
          })
          .then((result) => {
            this.cliente.imagenes = result.data.imagenes;
          });
      }
    },
    verContrato() {
      window.open(
        window.location.href.split("/")[0] + this.cliente.contrato,
        "_blank"
      );
    },
    eliminarImagen() {
      this.$refs.confirmar
        .open("Confirmación", "¿Esta seguro de eliminar esta imagen?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            const indexImagen = this.model_carrusel;
            let dispatch = "UsersModule/OnDelete";
            let endpoint =
              `imagenes-cliente/` + this.cliente.imagenes[indexImagen].id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
              })
              .then((result) => {
                this.cliente.imagenes = result.data.imagenes;
              });
          }
        });
    },
  },
};
</script>