<template>
  <div slot="component">
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title class="display-1" primary-title>
            <strong>Editar</strong>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation dense>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Nombre o alias"
                    v-model="router.nombre"
                    :rules="generalRegla"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-autocomplete
                    v-model="router.producto_id"
                    :items="productos"
                    label="Tipo de router"
                    item-value="id"
                    :rules="generalRegla"
                    item-text="producto"
                    no-data-text="No hay elementos registrados"
                    prepend-icon="mdi-router-wireless"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar color="indigo">
                        <img v-if="item.imagen" :src="item.imagen" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.producto
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Marca: {{ item.marca }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          >Cantidad en stock:
                          {{ item.cantidad }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item @click="nuevoProducto()">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-access-point-network</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Agregar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 md4>
                  <v-autocomplete
                    v-model="router.torre_id"
                    :items="torres"
                    item-text="nombre"
                    item-value="id"
                    label="Torre"
                    prepend-icon="mdi-city"
                    :rules="generalRegla"
                    no-data-text="No hay elementos registrados"
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item @click="nuevaTorre()">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Agregar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-radio-group row horizontal v-model="router.tipo_conexion">
                  <template v-slot:label>
                    <div><strong>Tipo de conexión.</strong></div>
                  </template>
                  <v-radio color="primary" value="1">
                    <template v-slot:label>
                      <div>
                        <strong class="primary--text">IP publica o DDNS</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio color="success" value="2">
                    <template v-slot:label>
                      <div>
                        <strong class="success--text">Script </strong>(Tunel
                        VPN)
                      </div>
                    </template>
                  </v-radio>
                  <v-radio color="secondary" value="3">
                    <template v-slot:label>
                      <div>
                        <strong class="secondary--text">Ninguno </strong>(Solo
                        registro)
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-layout>
              <v-layout v-if="router.tipo_conexion == 1" row wrap>
                <v-flex xs12 md3>
                  <v-text-field
                    label="IP Publica"
                    v-model="router.ip"
                    :rules="ipRegla"
                    prepend-icon="mdi-ip"
                    hint="Puede ser una url de algun servicio DDNS como no-ip, ip claud, Duckdns, etc."
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md3>
                  <v-text-field
                    label="Usuario"
                    v-model="router.usuario"
                    prepend-icon="mdi-account-circle"
                    :rules="generalRegla"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="establecer nueva contraseña"
                    v-model="contrasena"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    prepend-icon="mdi-textbox-password"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md2>
                  <v-text-field
                    label="Puerto de la api"
                    v-model="router.api"
                    :rules="apiRegla"
                    type="number"
                    prepend-icon="mdi-serial-port"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="router.tipo_conexion == 3" row wrap>
                <v-flex xs12 md6>
                  <v-text-field
                    label="IP privada"
                    v-model="router.ip"
                    :rules="ipPrivadaRegla"
                    prepend-icon="mdi-ip"
                    hint="Ip privada del rango 192.168.x.x o 172.16.x.x o 10.x.x.x"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="router.tipo_conexion == 2" row wrap>
                <v-btn small @click="generarScript()" color="secondary"
                  >Generar script de conexión</v-btn
                >
              </v-layout>
              <v-divider></v-divider>
              <div v-if="router.tipo_conexion == 2">
                <v-layout row wrap>
                  <strong
                    >Scrip de conexión (copie y pegue en una terminal del
                    RB)</strong
                  >
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-responsive
                      id="scroll-target"
                      class="overflow-y-auto"
                      :max-height="150"
                    >
                      <v-card-text>
                        <div class="row">
                          <div class="col-sm-12">
                            <span class="code text-red">{{ script }}</span>
                            <input
                              type="hidden"
                              id="testing-code"
                              :value="script"
                            />
                          </div>
                        </div>
                      </v-card-text>
                    </v-responsive>
                  </v-flex>
                  <v-flex xs12>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <span
                        class="btn btn-success text-white copy-btn ml-auto rounded-pill"
                        @click.stop.prevent="copyCode"
                      >
                        Copiar
                      </span>
                    </v-card-actions>
                  </v-flex>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <strong
                        >Confirma la conexión del Mikrotik con el código que
                        devuelve la ejecución del script.</strong
                      >
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-text-field
                        label="Código de registro"
                        v-model="codigo_registro"
                        outlined
                        :rules="reglasCodigo"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </div>
              <v-layout v-if="router.tipo_conexion != 3" row wrap>
                <v-flex xs12 md6>
                  <v-layout row wrap>
                    <v-flex xs10>
                      <strong> Reglas de suspención </strong>
                      <br />
                      Escribir distintas reglas en el mikrotik para que este
                      pueda suspender el servicio de internet al cliente y
                      redireccionar a una pagina de suspención.
                    </v-flex>
                    <v-flex xs2>
                      <v-switch
                        v-model="router.reglas"
                        hide-details
                        inset
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="router.reglas" row wrap>
                    <v-flex xs10>
                      <strong> Cancelar todos los clientes </strong>
                      <br />
                      Este Mikrotik puede cancelar el servicio de internet de
                      todos clientes anque no esten administrados por este
                    </v-flex>
                    <v-flex xs2>
                      <v-switch
                        v-model="router.cancelar_todos_clientes"
                        hide-details
                        inset
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 md6>
                  <v-layout row wrap>
                    <v-flex xs10>
                      <strong> Monitoreo de estado </strong>
                      <br />
                      Cada cierto tiempo el sistema consultara el estado de su
                      Mikrotik y recolectara datos como temperatura, energia
                      electria, memoria, etc.
                    </v-flex>
                    <v-flex xs2>
                      <v-switch
                        v-model="router.monitoreo"
                        hide-details
                        inset
                      ></v-switch>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs10>
                      <strong> Historial de clientes </strong>
                      <br />
                      Activar larecolección de trafico para poder visualizar el
                      historial de los clientes.
                    </v-flex>
                    <v-flex xs2>
                      <v-switch
                        v-model="router.historial"
                        hide-details
                        inset
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              class="rounded-pill pa-3"
              color="primary"
              @click="actualizar()"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <formProducto ref="refProducto"></formProducto>

    <nuevaTorre ref="nuevaTorre"></nuevaTorre>
  </div>
</template>
<script>
import formProducto from "@/js/components/almacen/productos/nuevoProducto.vue";
import nuevaTorre from "@/js/components/red/torres/nuevaTorre.vue";

export default {
  components: {
    formProducto,
    nuevaTorre,
  },
  data() {
    return {
      router: {
        ip: "",
        contrasena: "",
        usuario: "",
        contrasena_snmp: "",
        tiempo_escaneo: "",
        comunidad_snmp_id: "",
        api: "",
        torre_id: "",
        version_snmp_id: "",
        producto_id: "",
        tipo_conexion: "",
        historial: false,
        monitoreo: false,
        reglas: false,
      },
      show: false,
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      ipRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => this.quitaPuntos(v) || "Sin el puerto",
      ],
      apiRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => parseInt(v) > 1023 || "Debe ser mayor a 1204",
        (v) => parseInt(v) < 49151 || "Debe ser menor a 49151",
        (v) =>
          parseInt(v) != 10022 || "cambia el puerto a uno diferente de 10022",
        (v) =>
          parseInt(v) != 10080 || "cambia el puerto a uno diferente de 10080",
        (v) =>
          parseInt(v) != 18291 || "cambia el puerto a uno diferente de 180291",
      ],
      ipPrivadaRegla: [
        (v) =>
          /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            v
          ) || "Ip no valida. Ejemplo: 192.168.1.10",
      ],
      torres: [],
      productos: [],
      dialogProducto: false,
      dialog: false,
      resolve: null,
      reject: null,
      contrasena: "",
      script: "",
      reglasCodigo: [
        (v) => !!v || "Se necesita el código de confirmacion",
        (v) => this.validarCodigo(v) || "Código incorrecto",
      ],
      codigo_registro: 0,
    };
  },
  mounted() {},
  watch: {
    contrasena(val) {
      this.router.contrasena = val;
    },
  },
  computed: {},
  methods: {
    validarCodigo(codigo) {
      return this.router && codigo == this.router.id;
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/router",
          data: {},
        })
        .then((result) => {
          this.productos = result.data.productos;
          this.versiones = result.data.versiones;
          this.torres = result.data.torres;
          this.comunidades = result.data.comunidades;
        });
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `router/` + this.router.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.router,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.router);
          });
      }
    },
    esperarDatos(elemento) {
      this.getDatos();
      this.router = elemento;

      this.router.contrasena = "";
      this.router.tipo_conexion = elemento.tipo_conexion + "";
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
      this.script = "";
    },
    limpiar() {
      this.$refs.form.reset();
    },
    generarScript() {
      if (this.router.tipo_conexion == 2) {
        this.$store
          .dispatch("UsersModule/OnGet", {
            url: "crear/conexion/script/" + this.router.id,
          })
          .then((result) => {
            this.script = result.data.script;
          });
      }
    },
    nuevaTorre() {
      this.$refs.nuevaTorre.mostrar().then((resultado) => {
        if (resultado) {
          this.torres.push(resultado);
          this.router.torre_id = resultado.id;
        }
      });
    },
    copyCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$store.dispatch("notificaciones/add", {
            tipo: "success",
            mensaje: "Copiado en el portapapeles",
          });
        } else {
          this.$store.dispatch("notificaciones/add", {
            tipo: "warrning",
            mensaje: "No se pudo copiar",
          });
        }
      } catch (err) {
        this.$store.dispatch("notificaciones/add", {
          tipo: "warrning",
          mensaje: "No se pudo copiar: Detalles: " + err.message,
        });
      }
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      this.yaSeCopio = false;
    },
    nuevoProducto() {
      this.$refs.refProducto.cargarDatos().then((confirm) => {
        if (confirm) {
          this.productos.push(confirm);
          this.router.producto_id = confirm.id;
        }
      });
    },
    quitaPuntos(ip) {
      if (ip == null) {
        return true;
      }
      for (let index = 0; index < ip.length; index++) {
        if (ip[index] == ":") {
          return false;
        }
      }
      return true;
    },
  },
};
</script>