<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-container grid-list-md>
              <v-card class="cool pa-2" v-if="cliente">
                <v-layout row wrap>
                  <v-flex xs12 md8>
                    <v-card-title primary-title>
                      <strong>{{ cliente.FullName }}</strong>
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-subtitle class="subtitle-1">
                      <v-chip dark color="orange" outlined>{{
                        cliente.tipo.tipo
                      }}</v-chip>
                      <v-chip
                        v-if="cliente.status_cliente_id == 1"
                        @click="cambiarStatus()"
                        class="ma-2"
                        color="orange"
                        outlined
                      >
                        Instalacion en proceso
                        <v-icon right>mdi-worker</v-icon>
                      </v-chip>
                      <v-chip
                        v-if="cliente.status_cliente_id == 2"
                        class="ma-2"
                        color="green"
                        outlined
                        @click="cambiarStatus()"
                      >
                        Activo
                        <v-icon right>mdi-wifi</v-icon>
                      </v-chip>
                      <v-chip
                        v-if="cliente.status_cliente_id == 3"
                        class="ma-2"
                        color="red"
                        outlined
                        @click="cambiarStatus()"
                      >
                        Suspendido
                        <v-icon right>mdi-wifi-off</v-icon>
                      </v-chip>
                      <v-chip
                        v-if="cliente.status_cliente_id == 4"
                        class="ma-2"
                        color="indigo"
                        outlined
                        @click="cambiarStatus()"
                      >
                        Pendiente
                        <v-icon right>mdi-wifi-off</v-icon>
                      </v-chip>
                      <v-chip
                        v-if="cliente.status_cliente_id == 5"
                        class="ma-2"
                        color="grey"
                        outlined
                        @click="cambiarStatus()"
                      >
                        Baja temporal
                        <v-icon right>mdi-account-off</v-icon>
                      </v-chip>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo"> mdi-map-marker </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >{{ cliente.direccion }}, {{ cliente.colonia }},
                              {{ cliente.municipio }}
                              {{ cliente.codigo_postal }},
                            </v-list-item-title>
                            <v-list-item-subtitle
                              >Dirección</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo">
                                  mdi-access-point-network
                                </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>{{
                                  cliente.zona.nombre
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Zona</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-show="cliente.celular">
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-cellphone </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>{{
                                  cliente.celular
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Celular</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-ip </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>{{
                                  cliente.ip
                                }}</v-list-item-title>
                                <v-list-item-subtitle>IP</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-show="cliente.correo">
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-email </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>{{
                                  cliente.correo
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Correo</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-list-item v-if="cliente.telefono">
                              <v-list-item-icon>
                                <v-icon color="indigo"> mdi-phone </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>{{
                                  cliente.telefono
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Telefono</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-flex>
                        </v-layout>

                        <v-divider></v-divider>
                        <v-layout row wrap>
                          <strong>Dias del mes</strong>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo">
                                  mdi-calendar-import
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Facturación</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  cliente.dia_factura
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo">
                                  mdi-calendar-star
                                </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>Pago</v-list-item-title>
                                <v-list-item-subtitle>{{
                                  cliente.dia_pago
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo">
                                  mdi-calendar-heart
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Tolerancia</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  cliente.dias_gracia
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="indigo">
                                  mdi-calendar-remove
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Suspención</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  cliente.dia_pago + cliente.dias_gracia
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-flex>
                        </v-layout>
                      </v-list>
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-container grid-list-md>
                      <v-btn
                        color="red"
                        @click="buscar()"
                        class="rounded-pill pa-3 mb-6"
                        dark
                        ><v-icon>mdi-plus</v-icon> Buscar cliente</v-btn
                      >
                      <v-card class="cool" elevation4 dark>
                        <v-sheet
                          :color="total > 0 ? 'red' : 'green'"
                          class="cool"
                        >
                          <v-container grid-list-md>
                            <v-flex
                              class="d-flex justify-center align-center mb-2"
                              xs12
                            >
                              <h3 class="display-1">
                                <strong>Adeudo Total:</strong>
                              </h3>
                            </v-flex>
                            <v-flex
                              class="d-flex align-center justify-center mb-2"
                              xs12
                            >
                              <h1 class="Heading 1">$ {{ total }}</h1>
                            </v-flex>
                            <v-flex
                              v-if="total > 0"
                              class="d-flex align-center justify-center mb-2"
                              xs12
                            >
                              <v-btn
                                @click="liquidarTodo()"
                                x-large
                                dark
                                class="rounded-pill pa-3"
                                color="success"
                              >
                                <v-icon>mdi-lock</v-icon> Liquidar Todo</v-btn
                              >
                            </v-flex>
                            <v-flex
                              v-else
                              xs12
                              class="d-flex align-center justify-center mb-2"
                            >
                              <v-btn
                                @click="getPagoNUevo()"
                                x-large
                                class="rounded-pill pa-3"
                                color="light-blue accent-3"
                              >
                                <v-icon>mdi-lock</v-icon> Adelantar pago
                              </v-btn>
                            </v-flex>
                          </v-container>
                        </v-sheet>
                      </v-card>
                      <v-card class="cool pa-2 mt-2">
                        <v-card-text>
                          <v-flex
                            xs12
                            class="d-flex align-center justify-center"
                          >
                            <p>
                              <strong
                                >Enviar aviso de estado de pagos por
                                Whatsapp</strong
                              >
                            </p>
                          </v-flex>
                          <v-flex
                            xs12
                            class="d-flex align-center justify-center mb-2"
                          >
                            <v-btn
                              @click="enviarWhats()"
                              color="green"
                              class="rounded-pill pa-3"
                              dark
                              ><v-icon>mdi-whatsapp</v-icon> Enviar
                            </v-btn>
                          </v-flex>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-card class="cool" outlined>
                      <v-card-title> Historial de pagos </v-card-title>
                      <v-data-table
                        dense
                        :items-per-page="5"
                        :headers="headers"
                        :items="pagos"
                        :footer-props="{
                          showFirstLastPage: true,
                          firstIcon: 'mdi-format-horizontal-align-left',
                          lastIcon: 'mdi-format-horizontal-align-right',
                          prevIcon: 'mdi-chevron-left',
                          nextIcon: 'mdi-chevron-right',
                          itemsPerPageAllText: 'Todos',
                          itemsPerPageText: 'Registros por página:',
                          itemsPerPageOptions: [5, 12, -1],
                        }"
                      >
                        <template v-slot:item.correspondencia="{ item }">
                          {{ item.mes.mes }}<br />{{ item.anio }}
                        </template>
                        <template v-slot:item.descuentoYextra="{ item }">
                          <p class="success--text ma-1">
                            <v-icon small color="success"
                              >mdi-arrow-up-bold-circle-outline</v-icon
                            >${{ item.extra }}
                          </p>
                          <p class="red--text ma-1">
                            <v-icon small color="red"
                              >mdi-arrow-down-bold-circle-outline</v-icon
                            >${{ item.descuento }}
                          </p>
                        </template>

                        <template v-slot:item.estado="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                class="elevation-0"
                                v-bind="attrs"
                                v-on="on"
                                :color="calcularColor(item.status_pago_id)"
                                dark
                              >
                                <v-icon left v-if="item.status_pago_id == 1"
                                  >mdi-cash</v-icon
                                >
                                <v-icon left v-if="item.status_pago_id == 2"
                                  >mdi-timer</v-icon
                                >
                                <v-icon left v-if="item.status_pago_id == 3"
                                  >mdi-timer-off</v-icon
                                >
                                ${{ item.monto }}
                              </v-chip>
                            </template>
                            <span>{{ item.estado.status }} </span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.retraso="{ item }">
                          <v-chip
                            small
                            dark
                            :color="
                              parseInt(item.retraso) == 0 ? 'green' : 'red'
                            "
                            >{{ item.retraso }}</v-chip
                          >
                        </template>
                        <template v-slot:item.detalles="{ item }">
                          <div v-if="item.status_pago_id == 1">
                            <div>
                              {{ "Pago: " + item.tipo.tipo }}
                            </div>
                            {{ item.fecha + " - " + item.hora }}
                          </div>
                          <p v-if="item.detalles">
                            {{ item.detalles }}
                          </p>
                        </template>
                        <template v-slot:item.opciones="{ item }">
                          <v-tooltip v-if="item.eliminado == false" top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                @click="detalles(item)"
                                icon
                                v-on="on"
                              >
                                <v-icon small> mdi-dots-horizontal </v-icon>
                              </v-btn>
                            </template>
                            <span>Ver detalles</span>
                          </v-tooltip>
                          <v-tooltip v-if="item.status_pago_id == 1" top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                @click="ticketCalor(item.id)"
                                icon
                                v-on="on"
                              >
                                <v-icon small> mdi-printer </v-icon>
                              </v-btn>
                            </template>
                            <span>Imprimir ticket de calor</span>
                          </v-tooltip>
                          <v-tooltip v-if="item.eliminado == false" top>
                            <template v-slot:activator="{ on }">
                              <v-btn small @click="editar(item)" icon v-on="on">
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                          <v-tooltip v-if="item.eliminado == false" top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                @click="eliminarPago(item)"
                                icon
                                v-on="on"
                              >
                                <v-icon small> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:no-data>
                          <v-alert
                            :value="true"
                            color="green"
                            dark
                            icon="mdi-alert-circle"
                          >
                            <v-row align="center">
                              <v-col> Ningún dato que mostrar :( </v-col>
                            </v-row>
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-container>
          </v-flex>
        </v-layout>
        <buscadorCliente ref="refBuscador"></buscadorCliente>
        <dialogLiquidar ref="refDialogLiquidar"></dialogLiquidar>
        <dialogLiquidado ref="refDialogLiquidado"></dialogLiquidado>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <formEditarPago ref="refEditarPago"></formEditarPago>
        <aviso_whats ref="refwhats"></aviso_whats>
        <actualizarStatus ref="status"></actualizarStatus>
      </div>
    </base-component>
  </div>
</template>

<script>
import Common from "../../../Common";
import actualizarStatus from "@/js/components/clientes/listar_clientes/actualizarEstadoCliente.vue";
import buscadorCliente from "@/js/components/pagos/registrar_pago/buscadorCliente.vue";
import dialogLiquidar from "@/js/components/pagos/registrar_pago/dialogLiquidar.vue";
import dialogLiquidado from "@/js/components/pagos/registrar_pago/dialogLiquidado.vue";
import formEditarPago from "@/js/components/pagos/todos/editarPago.vue";
import aviso_whats from "@/js/components/pagos/registrar_pago/aviso_whatsapp.vue";

export default {
  components: {
    buscadorCliente,
    dialogLiquidar,
    dialogLiquidado,
    formEditarPago,
    aviso_whats,
    actualizarStatus,
  },
  data() {
    return {
      cliente: null,
      pagos: [],
      tipos: [],
      headers: [
        { text: "Estado", value: "estado", sortable: false },

        { text: "Mes y año", value: "correspondencia", sortable: false },
        { text: "Servicio", value: "servicio.nombre", sortable: false },
        {
          text: "Descuento/Extra",
          value: "descuentoYextra",
          sortable: false,
        },
        { text: "Dias de retraso", value: "retraso" },
        { text: "Detalles", value: "detalles", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    this.buscar();
  },
  watch: {},
  computed: {
    total() {
      return this.pagos
        .filter((pago) => pago.status_pago_id !== 1)
        .reduce((total, pago) => total + parseFloat(pago.monto), 0)
        .toFixed(2);
    },
  },
  methods: {
    buscar() {
      this.$refs.refBuscador.mostrar().then((confirm) => {
        if (confirm) {
          this.$store
            .dispatch("UsersModule/OnGet", {
              url: "cliente/detalles/pagos/" + confirm,
              data: {},
            })
            .then((result) => {
              console.log("result clientes pagos historial: ", result);
              this.cliente = result.data.cliente;
              this.pagos = result.data.pagos;
            });
        }
      });
    },
    liquidarTodo() {
      var pagosPendientes = this.pagos.filter(
        (pago) => pago.status_pago_id !== 1
      );
      this.$refs.refDialogLiquidar.mostrar(pagosPendientes).then((confirm) => {
        if (confirm) {
          console.log("confirm liquidados: ", confirm);
          let updatedPagos = [...this.pagos];

          confirm.pagosLiquidados.forEach((pagoLiquidado) => {
            const index = updatedPagos.findIndex(
              (pago) => pago.id === pagoLiquidado.id
            );

            if (index !== -1) {
              // Si el pago ya existe en el arreglo temporal, lo actualizamos
              updatedPagos[index] = {
                ...updatedPagos[index],
                ...pagoLiquidado,
              };
            } else {
              // Si no existe, lo agregamos al arreglo temporal
              updatedPagos.push(pagoLiquidado);
            }
          });

          // Una vez actualizado el arreglo temporal, lo asignamos a this.pagos
          this.pagos = updatedPagos;

          this.cliente = confirm.cliente;

          this.$refs.refDialogLiquidado.mostrar(
            this.cliente,
            confirm.pagosLiquidados
          );
        }
      });
    },

    getPagoNUevo() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "adelantar/pago/datos/" + this.cliente.id,
          data: {},
        })
        .then((result) => {
          this.pagos.unshift(result.data.pagos);
        });
    },

    eliminarPago(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `pago/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.pagos.splice(this.pagos.indexOf(elemento), 1);
              });
          }
        });
    },
    enviarWhats() {
      this.$refs.refwhats.mostrar(this.cliente, this.pagos, false);
    },
    editar(elemento) {
      this.PagoActualizar = elemento;
      const pago = Object.assign({}, elemento);
      this.$refs.refEditarPago.esperarDatos(pago).then((confirm) => {
        if (confirm) {
          this.pagos.splice(
            this.pagos.indexOf(this.PagoActualizar),
            1,
            confirm
          );
        }
      });
    },
    cambiarStatus() {
      const cliente = Object.assign({}, this.cliente);
      this.$refs.status.cargarDatos(cliente).then((confirm) => {
        if (confirm) {
          this.cliente.status_cliente_id = confirm.status_cliente_id;
        }
      });
    },

    calcularColor(id) {
      if (id == 1) {
        return "green";
      }
      if (id == 2) {
        return "orange";
      }
      if (id == 3) {
        return "purple";
      }
    },
    detalles(item) {
      document.location.href = "detalles/pago/" + item.id;
    },
    ticketCalor(id) {
      let url = "ticket/calor/pago/" + id;
      window.open(url, "_blank");
    },
  },
};
</script>